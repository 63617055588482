import axios from 'axios';
import {useContext, useMemo} from 'react';
import {AuthContext} from '../../components/AuthContext';
import {useNavigate} from 'react-router-dom';

// todo - i dont satisfy with this solution. Working only with when user is logged in and  access token really expired.

const DEVELOPMENT_WITH_PROD_DB = true;

const API_URL = process.env.NODE_ENV === 'development' && DEVELOPMENT_WITH_PROD_DB
    ? 'https://django-api.efunctional.com/api/v0'
    : process.env.NODE_ENV === 'development'
        ? 'http://0.0.0.0:8080/api/v0'
        : 'https://django-api.efunctional.com/api/v0';


export const useAuthAPI = () => {
    const {accessToken, refreshToken, refresh, authConfig} = useContext(AuthContext);
    const navigate = useNavigate();

    const authAPI = useMemo(() => {

        const instance = axios.create({baseURL: API_URL,});

        const refreshTokenAndRetryRequest = async (error, originalRequest) => {
            try {
                const token = await refresh();

                if (!token) {
                    throw new Error('Refresh token failed');
                }

                originalRequest.headers.Authorization = `ECL ${token}`;
                return await instance.request(originalRequest);
            } catch (refreshError) {
                navigate('/session-expired');
                return Promise.reject(refreshError);
            }
        };

        instance.interceptors.request.use((config) => {
            const updatedConfig = {
                ...config,
                ...authConfig(),
                headers: {
                    ...config.headers,
                    ...authConfig().headers,
                },
            };
            return updatedConfig;
        });

        instance.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;

                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    return refreshTokenAndRetryRequest(error, originalRequest);
                }
                return Promise.reject(error);
            }
        );

        return instance;
    }, [accessToken, refreshToken, navigate, refresh, authConfig]); // Add accessToken to the dependency array

    return authAPI;
};