import React, {useState, useEffect} from 'react';
import {Button, Form, Input, InputNumber, message, Modal} from 'antd';
import {EditOutlined} from "@ant-design/icons";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";

const SalesPersonEditModal = (props) => {
    const api = useAuthAPI();
    const {
        id, first_name, last_name, email, phone, commission, about, setSalesList, salesList
    } = props;

    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setConfirmLoading(false);
            triggerSubmitHandler();
        }, 1000);
    };

    const submitForm = async (formData) => {
        formData.active = true;

        await api.put(`/sales-persons/${id}/`, formData)
            .then(response => {
                setSalesList(prevState =>
                    prevState.map(oneGroup => oneGroup.id === id ? response.data : oneGroup));

            })
            .catch(err => console.log(err));
    };

    const onFinish = async (values) => {
        /* Submit valid form */

        await submitForm(values)
            .then(() => {
                setOpen(false);
                form.resetFields();
                message.success({
                    content: `${last_name} ${first_name} updated!`,
                    duration: 2,
                });
            })
            .catch(() => {
                setOpen(true);
                message.error({
                    content: 'We have an errors!',
                    duration: 2,
                });
            });
    }

    const triggerSubmitHandler = () => form.submit();

    useEffect(() => {
        form.setFieldsValue({
            last_name,
            first_name,
            email,
            phone,
            about,
            commission,
        });
        // eslint-disable-next-line
    }, [salesList]);

    return <>
        <Button size={'small'} onClick={showModal} disabled={true}>
            <EditOutlined/>&nbsp;Edit
        </Button>

        <Modal
            title={`Edit Sale person : ${last_name} ${first_name}`}
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
        >
            <Form onFinish={onFinish} form={form} autoComplete="off">

                <Form.Item
                    label={'Last name'}
                    name="last_name"
                    rules={[{
                        required: true,
                        message: 'Please input last name!',
                    }]}
                >
                    <Input placeholder="Last name"/>
                </Form.Item>

                <Form.Item
                    label={'First name'}
                    name="first_name"
                    rules={[{
                        required: true,
                        message: 'Please input first name!',
                    }]}
                >
                    <Input placeholder="First name"/>
                </Form.Item>

                <Form.Item
                    label={'Email'}
                    name="email"
                    rules={[{required: true, message: 'Please input email!'}]}
                >
                    <Input placeholder="Email"/>
                </Form.Item>

                <Form.Item
                    label={'Phone'}
                    name="phone"
                    rules={[{required: true, message: 'Please input phone number!'}]}
                >
                    <Input placeholder="Please input phone number!"/>
                </Form.Item>

                <Form.Item
                    label='Commission, %'
                    name="commission"
                    rules={[{
                        type: 'number',
                        required: true,
                        message: 'Please specify commission percent',
                        min: 1,
                        max: 100,
                    }]}
                >
                    <InputNumber style={{width: "100%"}} placeholder="Please specify percent"/>
                </Form.Item>

                <Form.Item label={'About'} name="about">
                    <Input.TextArea placeholder="Input description"/>
                </Form.Item>

            </Form>

        </Modal>
    </>;
}

export default SalesPersonEditModal;