import React, {useState} from 'react';
import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {Button, message, Modal} from 'antd';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";

const SalesPersonDeleteConfirmModal = ({id, last_name, first_name, setSalesList, userTokens}) => {
    const api = useAuthAPI();
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };
    const hideModal = () => {
        setOpen(false);
    };

    const deleteClientHandler = async () => {
        await api.delete(`/sales-persons/${id}/`)
            .then(response => {
                if (response.status === 204) hideModal();
                setSalesList(prevState =>
                    prevState.filter(salePerson => salePerson.id !== id));
                message.success({
                    content: `${last_name} ${first_name} was deleted!`,
                    duration: 2,
                });
            })
            .catch(err => console.log(err))
    };

    return <>
        <Modal
            closable={false}
            open={open}
            onOk={deleteClientHandler}
            onCancel={hideModal}
            okText={"Delete"}
            okButtonProps={{danger: true, type: 'primary', size: 'small'}}
            cancelButtonProps={{type: 'primary', size: 'small'}}
            cancelText="Cancel"
            // TODO add text warning about deleting everything connect with this element.
        >
            <ExclamationCircleOutlined/>&nbsp;
            Delete sale person: {last_name} {first_name}?
        </Modal>

        <Button
            disabled={true}
            size={'small'}
            type="primary"
            onClick={showModal}>
            <DeleteOutlined/>&nbsp;Delete
        </Button>
    </>;
}

export default SalesPersonDeleteConfirmModal;