import {Menu} from "antd";
import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {superusersPortalPages} from "../../Root/routerConfig";


const MainMenu = () => {
    // TODO - with new structure you have a possibility to use this component for all users with props
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [currentLink, setCurrentLink] = useState([])

    useEffect(() => {
        setCurrentLink(pathname);
    }, [pathname]);

    const itemsForLeftMenu = superusersPortalPages
        .slice(0, 10)  // only for superusers menu needs to be limited
        .map((page) => {
            const adjustedPath = page.path.replace('/*', ''); // Adjust the path, removing the wildcard
            return {
                key: adjustedPath,
                label: page.title,
                onClick: ({key}) => {
                    setCurrentLink(key);
                    navigate(adjustedPath);
                },
            };
        });

    return <Menu
        style={{userSelect: 'none'}}
        theme="dark"
        mode="horizontal"
        items={itemsForLeftMenu}
        selectedKeys={currentLink}
    />
}

export default MainMenu;