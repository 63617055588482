import {Menu} from "antd";
import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {salesPortalPages} from "../../Root/routerConfig";


const MainMenu = () => {

    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [currentLink, setCurrentLink] = useState([])

    useEffect(() => {
        setCurrentLink(pathname);
    }, [pathname]);

    const itemsForLeftMenu = salesPortalPages.map((page) => {
        const adjustedPath = page.path.replace('/*', ''); // Adjust the path, removing the wildcard
        return {
            key: adjustedPath,
            label: page.title,
            onClick: ({key}) => {
                setCurrentLink(key);
                navigate(adjustedPath);
            },
        };
    });

    return <Menu
        style={{userSelect: 'none'}}
        theme="dark"
        mode="horizontal"
        items={itemsForLeftMenu}
        selectedKeys={currentLink}
    />
}

export default MainMenu;