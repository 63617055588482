import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, {useEffect, useState} from 'react';
import {Button, Col, List, Row, Space, Spin, Tag, Progress, Typography, Divider, DatePicker, Input, Select} from 'antd';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import {NavLink} from "react-router-dom";
import {EyeOutlined} from "@ant-design/icons";


// Use the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const KitsList = ({patientsList, setPatientsList}) => {
    const api = useAuthAPI();
    const {Search} = Input;

    const [searchSTR, setSearchSTR] = useState(false);
    const [loading, setLoading] = useState(false);

    // filter by date
    const [selectedDateFilter, setSelectedDateFilter] = useState('dateActivated');

    const onSearch = async value => {
        /* Update state when user change searchSTR after clicking on Search btn*/
        if (searchSTR !== value) {
            setSearchSTR(value);

            setLoadMoreObject({
                ...loadMoreObject, clickCounter: 0, nextURL: null,
            });
        }
    };

    const onSelectedDateFilterChange = async value => {
        setSelectedDateFilter(value);

        setLoadMoreObject({
            ...loadMoreObject, clickCounter: 0, nextURL: null,
        });
    }

    const loadMoreData = async () => {
        setLoading(true);

        try {
            // (1) Stop if loading
            if (loading) return;

            let requestURL = `/kits/all/?ordering=-${selectedDateFilter}`; // result API request URL

            // (2) Check a default case when search input doesn't touched and the user clicked the load more btn
            if (loadMoreObject.nextURL) {
                requestURL = loadMoreObject.nextURL;
            }

            // (3) Check only search input and update all the client list with searchSTR
            if (searchSTR && searchSTR.length !== 0) {
                requestURL = `/kits/all/?search=${searchSTR}`;
            }

            // if search used, and we have a new clicks from user to load more btn
            if (searchSTR && searchSTR.length !== 0 && loadMoreObject.nextURL) {
                requestURL = loadMoreObject.nextURL;
            }

            // const response = await api.get(`${requestURL}`);
            const response = await api.get(requestURL)

            // Update state with new data
            if (loadMoreObject.clickCounter === 0) {
                setPatientsList(response?.data?.results);
            } else {
                setPatientsList(prevState => [...prevState, ...response?.data?.results]);
            }

            setLoadMoreObject({
                ...loadMoreObject, nextURL: response?.data?.next, totalCount: response?.data?.count,
            });

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    const [loadMoreObject, setLoadMoreObject] = useState({
        nextURL: null, totalCount: null, clickCounter: 0, nextSearchURL: null,
    });


    // Listen for Load More button click
    useEffect(() => {
        if (patientsList?.length <= loadMoreObject.totalCount) {
            (async () => {
                try {
                    await loadMoreData();
                } catch (err) {
                    console.error(err);
                }
            })();
        }
        // eslint-disable-next-line
    }, [loadMoreObject.clickCounter, searchSTR, selectedDateFilter]);


    const buttonClickHandler = () => {
        setLoadMoreObject({...loadMoreObject, clickCounter: loadMoreObject.clickCounter + 1});
    }


    function renderTagColor(kit) {

        const month = ["MN", "AF", "AM"].includes(kit?.test) ? 2 : 6;

        if (kit?.status !== 'Pending') return '#dedcdc';

        if (kit?.assembled_and_packed_timestamp || kit?.created) {
            if (dayjs().diff(dayjs(kit?.assembled_and_packed_timestamp || kit?.created), 'month') >= month) {
                return 'error';
            } else {
                return '#01a9ac';
            }
        } else {
            return 'error';
        }
    }

    function renderTagContent(kit) {

        const month = ["MN", "AF", "AM"].includes(kit?.test) ? 2 : 6;

        if (kit?.status !== 'Pending') return 'Final';

        if (kit?.assembled_and_packed_timestamp || kit?.created) {
            if (dayjs().diff(dayjs(kit?.assembled_and_packed_timestamp || kit?.created), 'month') >= month) {
                return 'Expired';
            } else {
                return dayjs(kit?.assembled_and_packed_timestamp || kit?.created).add(month, 'month').format('MM-DD-YYYY');
            }
        } else {
            return 'No';
        }
    }

    const loadMore = !loading ? (

        <Row style={{alignSelf: "center", justifyContent: "center", marginTop: 20}}>

            <Col xs={5}>
                <p style={{textAlign: "center"}}>
                    {patientsList?.length === 0
                        ? <>Nothing to show</>
                        : <>
                            {patientsList?.length} kits on page from {loadMoreObject.totalCount}

                            <Progress
                                strokeColor={'#01a9ac'}
                                percent={Math.round((patientsList?.length / loadMoreObject.totalCount) * 100)}
                                status="active"
                            />
                        </>
                    }
                </p>
            </Col>

            <Col xs={24}>
                <div style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                    display: (loadMoreObject.clickCounter === 0 && !loadMoreObject.nextURL) || (patientsList?.length === loadMoreObject.totalCount)
                        ? 'none'
                        : 'block',
                }}>
                    <Button onClick={buttonClickHandler} loading={loading} disabled={loading}>
                        loading more
                    </Button>
                </div>
            </Col>
        </Row>
    ) : null;


    return <Spin spinning={loading}>

        <Row gutter={10}>
            <Col xs={24} lg={24} xl={24}>
                <Space style={{margin: '0 0 20px 0'}}>

                    <Search
                        enterButton
                        allowClear
                        size={'small'}
                        placeholder="Search kit by barcode"
                        onSearch={onSearch}
                        style={{width: "400px",}}
                    />


                    <span>Order by date:</span>

                    <Select
                        // style={{width: 200}}
                        size={'small'}
                        onChange={onSelectedDateFilterChange}
                        defaultValue={selectedDateFilter}
                        options={[
                            {
                                value: 'dateActivated',
                                label: 'Activated date (z-a)',
                            },
                            {
                                value: 'finalDate',
                                label: 'Finalized date (z-a)',
                            },
                            {
                                value: 'created',
                                label: 'Created date (z-a)',
                            },
                        ]}
                    />


                    {
                        searchSTR
                            ? (<Tag>We find <strong>{loadMoreObject.totalCount}</strong> kits in Database</Tag>)
                            : (loadMoreObject.totalCount === null || loadMoreObject.totalCount === 0)
                                ? <>Nothing to show</>
                                : (<Tag>We have <strong>{loadMoreObject.totalCount}</strong> kits in Database</Tag>)
                    }
                </Space>

                {/*<hr/>*/}
                {/*<h1>DEBUG</h1>*/}
                {/*<p>*/}
                {/*    <strong>Total count:</strong> {loadMoreObject.totalCount}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    <strong>Next URL:</strong> {loadMoreObject.nextURL}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    <strong>Next searchURL:</strong> {loadMoreObject.nextSearchURL}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    <strong>Click counter:</strong> {loadMoreObject.clickCounter}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    Clients on page: {patientsList.length}*/}
                {/*</p>*/}
                {/*<hr/>*/}

            </Col>
        </Row>

        <List
            loadMore={loadMore}
            dataSource={patientsList}
            renderItem={kit =>
                <List.Item key={kit.id} extra={
                    <Space>
                        {/*<>*/}
                        {/*    <strong>Last login date:</strong>*/}
                        {/*    {dayjs(kit?.user?.last_login).isValid()*/}
                        {/*        ? <Tag>*/}
                        {/*            {dayjs(kit?.user?.last_login).tz('America/New_York').format('MM-DD-YYYY HH:mm:ss')}*/}
                        {/*        </Tag>*/}
                        {/*        : <Tag>Never</Tag>*/}
                        {/*    }*/}
                        {/*</>*/}
                    </Space>
                }>
                    <List.Item.Meta
                        description={
                            <Space>
                                <strong>Activated date:</strong>
                                {dayjs(kit?.dateActivated).isValid() ?
                                    <Tag>{dayjs(kit?.dateActivated).format('MM-DD-YYYY')}</Tag> : <Tag>Never</Tag>}

                                <Divider type="vertical"/>

                                <strong>Final date:</strong>
                                {dayjs(kit?.dateActivated).isValid() ?
                                    <Tag>{dayjs(kit?.finalDate).format('MM-DD-YYYY')}</Tag> : <Tag>Never</Tag>}

                                <Divider type="vertical"/>

                                <strong>Is Paid:</strong>
                                {kit?.isPaid
                                    ? <Tag color={'#01a9ac'}>Yes</Tag>
                                    : <Tag color={'error'}>No</Tag>
                                }

                                {/*<Divider type="vertical"/>*/}

                                {/*<strong>Sold price:</strong>*/}
                                {/*{kit?.assembled_and_packed_timestamp*/}
                                {/*    ? <Tag color={'#01a9ac'}>${kit.sold_price}</Tag>*/}
                                {/*    : <Tag color={'error'}>${kit.sold_price}</Tag> // if zero in kit.sold_price*/}
                                {/*}*/}

                                <Divider type="vertical"/>

                                <strong>Use by:</strong>
                                {<Tag color={renderTagColor(kit)}>
                                    {renderTagContent(kit)}
                                </Tag>}

                            </Space>
                        }
                        title={
                            <Typography.Title level={5}>
                                {kit?.barcode}
                                <Divider type="vertical"/>
                                Kit status:&nbsp;&nbsp;
                                <Tag color={'#01a9ac'}>{kit?.status}</Tag>

                                <Divider type="vertical"/>

                                {
                                    kit?.patient_details
                                        ? <NavLink to={`/patients/${kit?.patient_details?.id}`}>
                                            <Typography.Text level={5}>
                                                {kit?.patient_details?.first_name}&nbsp;{kit?.patient_details?.middle_name || ""}
                                                &nbsp;{kit?.patient_details?.last_name}&nbsp;
                                                <EyeOutlined/>
                                            </Typography.Text>
                                        </NavLink>
                                        : <Tag color={'grey'}>No patient assigned</Tag>
                                }
                            </Typography.Title>
                        }
                    />
                </List.Item>}
        />
    </Spin>;
};
export default KitsList;