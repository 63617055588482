// import {Provider} from 'react-redux';
// import {store} from './app/store';

import React from 'react';
import ReactDOM from 'react-dom/client';


/*-----------------------------------------*/
import 'antd/dist/reset.css';
import {ConfigProvider} from 'antd';
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import 'antd/dist/reset.css';
/*-----------------------------------------*/

import {AuthProvider} from "./components/AuthProvider";
import App from './components/Efunctional/App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <ConfigProvider theme={{token: {colorPrimary: '#01a9ac'}}}>
                <App/>
            </ConfigProvider>
        </AuthProvider>
    </React.StrictMode>
);