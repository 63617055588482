import React, {useState, useEffect} from "react";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import {UserSwitchOutlined, TeamOutlined} from "@ant-design/icons";
import {Typography, Spin, Row, Col, List, Button, Input, Divider, Alert} from "antd";

const SalesManager = ({manager, onPercentageChange, onDelete}) =>
    <List.Item
        extra={
            <Row gutter={16}>
                <Col md={12}>
                    <Typography.Text>
                        {manager?.first_name + " " + manager?.last_name}
                    </Typography.Text>
                </Col>

                <Col md={6}>
                    <Input
                        width={'100%'}
                        size={'small'}
                        type="number"
                        value={manager?.split_percent}
                        onChange={e => onPercentageChange(manager.id, e.target.value)}
                        suffix="%"
                    />
                </Col>

                <Col md={6}>
                    <Button size={'small'} onClick={() => onDelete(manager.id)}>Delete</Button>
                </Col>
            </Row>
        }
    />

const SalesGroupMembershipsManagement = ({
                                             saleGroupID,
                                             selectedSalesPersons,
                                             setSelectedSalesPersons,
                                             componentEditMode,
                                             setComponentEditMode
                                         }) => {
    const api = useAuthAPI();

    // const [componentEditMode, setComponentEditMode] = useState(false);
    const [saleGroupData, setSaleGroupData] = useState(null);

    // const [selectedSalesPersons, setSelectedSalesPersons] = useState([]);
    const [availableSalesPersons, setAvailableSalesPersons] = useState([]);

    const [loading, setLoading] = useState(true);

    const fetchDetailsForSalesGroup = async () => {
        /* Get all data for current Sales Group */
        try {
            const response = await api.get(`/sales-groups/${saleGroupID}/`);
            setSaleGroupData(response.data);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const fetchAllSalesPersons = async () => {
        /* Get all Sales Persons Data */
        try {
            const response = await api.get(`/sales-persons/`);
            return response.data.results;
        } catch (error) {
            console.error(error);
        }
    }

    const handleSplitPercentageChange = (id, value) => {
        // Similar as before: adjust percentages here to ensure the sum of all is 100%
        const updatedManagers = selectedSalesPersons.map(m =>
            m.id === id ? {...m, split_percent: value} : m
        );
        setSelectedSalesPersons(updatedManagers);
        console.log(updatedManagers)
    };

    const addNewSalesPersonToSelectedList = id => {
        const newSalesPerson = availableSalesPersons.find(salesPerson => salesPerson.id === id);
        setSelectedSalesPersons([...selectedSalesPersons, {...newSalesPerson, split_percent: 0}]);
        setAvailableSalesPersons(availableSalesPersons.filter(salesPerson => salesPerson.id !== id));
    }

    const deleteSalesPersonFromSelectedList = id => {
        const salesPersonToDelete = selectedSalesPersons.find(salesPerson => salesPerson.id === id);
        setAvailableSalesPersons([...availableSalesPersons, salesPersonToDelete]);
        setSelectedSalesPersons(selectedSalesPersons.filter(salesPerson => salesPerson.id !== id));
    }

    // TODO - Update memberships and after `save` button was clicked

    useEffect(() => {
        (async () => {
            try {
                // 0. Load the data
                const salesGroupData = await fetchDetailsForSalesGroup();
                const {memberships} = salesGroupData;
                const salesPersonsData = await fetchAllSalesPersons();

                // 1. Filter the data
                const salesPersonsIdsInMemberships = memberships.map(membership => membership.salesperson);
                const filteredSalesPersons = salesPersonsData.filter(salesPerson => !salesPersonsIdsInMemberships.includes(salesPerson.id));
                // const existingSalesPersons = salesPersonsData.filter(salesPerson => salesPersonsIdsInMemberships.includes(salesPerson.id));

                const existingSalesPersons = salesPersonsData.filter(salesPerson => {
                    const membership = memberships.find(membership => membership.salesperson === salesPerson.id);
                    if (membership) {
                        salesPerson.split_percent = membership.split_percent; // add split_percent to the salesPerson object
                        return true;
                    }
                    return false;
                });

                // 2. Set the data
                setAvailableSalesPersons(filteredSalesPersons)
                setSelectedSalesPersons(existingSalesPersons)
                console.log(existingSalesPersons)
                console.log(memberships)

            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })()
    }, [saleGroupID, componentEditMode]);


    return componentEditMode
        ? <Spin spinning={loading}>
            <Typography.Title level={4}>Assign group managers - {saleGroupData?.shortname}</Typography.Title>

            <Button
                type={'primary'}
                icon={<TeamOutlined/>}
                size={'small'}
                onClick={() => setComponentEditMode(!componentEditMode)}
            >
                Return to Read mode
            </Button>

            <Divider/>

            <Row gutter={16}>

                <Col span={12}>
                    <h4>Available Sales Persons</h4>
                    <List
                        style={{height: 300, overflow: 'auto'}}
                        bordered
                        dataSource={availableSalesPersons}
                        renderItem={salesPerson => (
                            <List.Item
                                extra={
                                    <Button onClick={() => addNewSalesPersonToSelectedList(salesPerson?.id)}
                                            size={'small'}>
                                        Add to group
                                    </Button>
                                }
                            >
                                {salesPerson.first_name + " " + salesPerson.last_name}
                            </List.Item>
                        )}
                    />
                </Col>

                <Col span={12}>
                    <h4>Selected Sales Persons</h4>
                    <List
                        style={{height: 300, overflow: 'auto'}}
                        bordered
                        dataSource={selectedSalesPersons}
                        renderItem={salesPerson =>
                            <SalesManager
                                manager={salesPerson}
                                onPercentageChange={handleSplitPercentageChange}
                                onDelete={deleteSalesPersonFromSelectedList}
                            />
                        }
                    />
                </Col>

                <Col lg={24}>
                    <br/>
                    <Alert type={'info'} message={'Your changes will take effect after a successful save.'}/>
                </Col>
            </Row>
        </Spin>
        : <Spin spinning={loading}>
            <Row gutter={16}>
                <Col span={24}>
                    <Typography.Title level={4}>Sales persons for - {saleGroupData?.shortname}</Typography.Title>
                    <Button
                        type={'primary'}
                        icon={<UserSwitchOutlined/>}
                        size={'small'}
                        onClick={() => setComponentEditMode(!componentEditMode)}
                    >
                        Manage sales persons
                    </Button>
                    <Divider/>

                    <List
                        bordered
                        dataSource={selectedSalesPersons}
                        renderItem={salesPerson => (
                            <List.Item>
                                {salesPerson.email} - {salesPerson.split_percent}%
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Spin>
}


export default SalesGroupMembershipsManagement;