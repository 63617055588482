import React, {useState, useEffect} from "react";
import styled, {keyframes} from "styled-components";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import {
    Row,
    Col,
    Divider,
    Space,
    List,
    Typography,
    Button,
    Tag,
    Switch,
    message,
    Form,
    Input,
    Result,
    Progress, Spin, Select
} from "antd";
import {CheckOutlined, CloseOutlined, ThunderboltOutlined} from "@ant-design/icons";


// -----  STYLES  ----- //
const blink = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const BlinkingTag = styled(Tag)`
    animation: ${blink} 1s linear infinite;
`;
// -----  STYLES  ----- //


const SingleKitItem = (
    {kit, isAssembled, setLocalAssembledKitsCount, setKitsForOrder, setSelectedOrder, printLabel}
) => {

    const api = useAuthAPI();

    const [isAssembledKit, setIsAssembledKit] = useState(isAssembled);


    const updateKitData = async kitId => {
        try {
            const request = await api.post(`/kits/all/${kitId}/toggle-assembled-and-packed/`)
            return await request;
        } catch (error) {
            console.error(error);
        }
    }


    /* ----- MARK AS COMPLETE ----- */
    const updateKitDataHandler = async kitObject => {
        try {
            const responseFromServer = await updateKitData(kitObject?.id);
            // setIsAssembled(true); // State for the Switch component in the SingleKit component
            setIsAssembledKit(true);


            // Update the Kit in an existed list
            setKitsForOrder(prevState => {
                return prevState.map(kitItem => {
                    if (kitItem.id === kitObject?.id) {
                        return {
                            ...kitItem,
                            assembled_and_packed: true
                        }
                    }
                    return kitItem;
                })
            });

            // Update the selected order
            setSelectedOrder(prevState => {
                return {
                    ...prevState,
                    assembled_kit_count: parseInt(prevState.assembled_kit_count) + 1,
                }
            })

            // Disable a counter for the Assembled Kits if this kit already assembled
            if (!kit?.assembled_and_packed) {
                setLocalAssembledKitsCount(prevState => parseInt(prevState) + 1);
            }

            message.success(`${responseFromServer.data.status}`);
        } catch (error) {
            console.error(error);
        }
    }

    /* ----- MARK AS COMPLETE ----- */

    const getKitLabel = kit => {
        // Extracting values to variables for readability and efficiency
        const productName = kit?.orderItem?.product?.name;
        const testType = kit?.test;

        let label = null;

        if (productName) {
            // If product name is available, use it
            label = productName;
        } else {
            // Handling the test type logic
            if (testType === "AM") {
                label = "DOD MALE PANEL";
            } else if (testType === "AF") {
                label = "DOD FEMALE PANEL";
            }
        }

        return (
            <Typography.Text>
                <Tag>{label}</Tag>
            </Typography.Text>
        );
    }


    return <List.Item
        key={kit.id}
        extra={
            <Space>
                <Button disabled={!!kit?.assembled_and_packed} size={'small'}
                        onClick={async () => await updateKitDataHandler(kit)}>
                    Mark as assembled
                </Button>

                <Divider type={'vertical'}/>
                <Button size={'small'} onClick={() => printLabel(kit.barcode)}>print the label</Button>
                <Divider type={'vertical'}/>
            </Space>
        }
    >
        <List.Item.Meta
            title={
                <Space>
                    <Switch
                        size={'small'}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        checked={isAssembledKit}
                    />

                    <Typography.Text>
                        {kit.barcode} &nbsp;&nbsp;
                    </Typography.Text>

                    <Divider type={'vertical'}/>

                    {getKitLabel(kit)}

                    {kit?.web_provider === 1166 && <BlinkingTag color={'#01a9ac'}>AGE REJUVENATION</BlinkingTag>}
                    {kit?.web_provider === 1177 && <BlinkingTag color={'#01a9ac'}>AGE REJUVENATION</BlinkingTag>}

                </Space>
            }
            description={<Space></Space>}
        />
    </List.Item>
}

const OrderDetailsWithAllKits = ({
                                     selectedOrder,
                                     printerDevice,
                                     setPrinterDevice,
                                     setIsModalVisible,
                                     setOrdersList,
                                     setSelectedOrder,
                                     printLabel,
                                     loading
                                 }) => {
    /* Displaying all Kits List for current Order ID and show actions buttons. */

    const api = useAuthAPI();

    /* ------------- List with all component modes -------------  */
    const modes = {
        KITS_MANAGEMENT: 'KITS_MANAGEMENT',
        ALL_KITS_ASSEMBLED: 'ALL_KITS_ASSEMBLED',
        ORDER_SUCCESSFULLY_SHIPPED: 'ORDER_SUCCESSFULLY_SHIPPED',
    }

    const [componentMode, setComponentMode] = useState(modes.KITS_MANAGEMENT);

    const {
        id: orderId,
        status: orderStatus,
        assembled_kit_count: assembledKitsCount,
    } = selectedOrder;

    // console.log(selectedOrder);

    // Tracking numbers for current order. Default values are null. Waiting for all kits was assembled and packed.
    const [trackingNumber, setTrackingNumber] = useState(null);
    const [trackingReturn, setTrackingReturn] = useState(null);
    const [carrierId, setCarrierId] = useState(1);

    // Initial state for assembled kits. Needed for the Switch component and Complete the Supply Order button.
    const [localAssembledKitsCount, setLocalAssembledKitsCount] = useState(assembledKitsCount);

    // All kits for current order
    const [kitsForOrder, setKitsForOrder] = useState([]);

    // Promo materials order data
    const [dataForPromoOrder, setDataForPromoOrder] = useState([]);

    // Server response with all data for current order
    const [orderData, setOrderData] = useState(null);

    // Server response with all data for current order
    const [productData, setProductData] = useState([]);

    const assembledPercentage = orderData?.count ? (assembledKitsCount / orderData?.count) * 100 : 0;

    const markOrderAsShipped = async () => {

        const requestData = {
            trackingNumber,
            trackingReturn,
            carrierId
        };

        try {
            const response = await api.post(`/orders/supplies-orders-all/${orderId}/mark-as-shipped/`, requestData);
            console.log(response.data);
            message.success('Order marked as shipped successfully!');
            setComponentMode(modes.ORDER_SUCCESSFULLY_SHIPPED);

            setOrdersList(prevState => prevState.filter(order => order.id !== orderId))
        } catch (error) {
            console.error('Error marking order as shipped:', error);

            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Server response:', error.response.data);
                message.error('Failed to mark order as shipped. ' + error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received:', error.request);
                message.error('Failed to mark order as shipped. No response from server.');
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up request:', error.message);
                message.error('Failed to mark order as shipped. Error: ' + error.message);
            }
        }
    }

    const loadKitsForOrder = async () => {
        try {
            const response = await api.get(`/kits/all/?order=${orderId}`);
            console.log(response.data); // TODO - remove this console.log
            setKitsForOrder(response.data.results);
            setOrderData(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const loadDataForPromoOrder = async () => {
        try {
            const response = await api.get(`/orders/promo-materials-order/${orderId}/`);
            console.log(response.data); // TODO - remove this console.log
            setDataForPromoOrder(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const loadDataForPromoKitsOrder = async () => {
        try {
            const response = await api.get(`/orders/promo-kits-order/${orderId}/`);
            console.log(response.data); // TODO - remove this console.log
            setDataForPromoOrder(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const loadProductData = async () => {
        try {
            const response = await api.get(`/products/`);
            setProductData(response.data.results);
        } catch (error) {
            console.error('Error marking order as shipped:', error);

            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Server response:', error.response.data);
                message.error('Failed to mark order as shipped. ' + error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received:', error.request);
                message.error('Failed to mark order as shipped. No response from server.');
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up request:', error.message);
                message.error('Failed to mark order as shipped. Error: ' + error.message);
            }
        }
    }

    useEffect(() => {
        (async () => {

            if (selectedOrder?.orderType === "PromoMaterialsOrder") {
                await loadDataForPromoOrder();
            }

            if (selectedOrder?.orderType === "PromoKitsOrder") {
                await Promise.all([
                    loadProductData(),
                    loadDataForPromoKitsOrder(),
                ]);
            }

            if (selectedOrder?.orderType === null) {
                await loadKitsForOrder();
            }


        })();
    }, [selectedOrder]);

    // TODO - here in request output data we have a paginated data. Need to implement pagination here.

    const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);


    const KITS_MANAGEMENT = <Spin spinning={loading}>
            <Row>
                <Col lg={24}>
                    <Space>

                        <Tag color="#01a9ac">Order ID: {orderId}</Tag>
                        {selectedOrder?.isExpeditedShipping && <Tag icon={<ThunderboltOutlined/>} color={'gold'}>
                            Expedited shipping
                        </Tag>}

                        <Tag>Status: {orderStatus}</Tag>

                        {selectedOrder?.orderType === null &&
                            <Tag>Total kits in order: {orderData?.count}</Tag>
                        }

                        {selectedOrder && selectedOrder?.orderType === null &&
                            <Button
                                onClick={() => setComponentMode(modes.ALL_KITS_ASSEMBLED)}
                                disabled={parseInt(localAssembledKitsCount) !== parseInt(orderData?.count)}
                                type={'primary'}
                                size={'small'}
                            >
                                ({localAssembledKitsCount} of {orderData?.count})&nbsp;
                                Finish the order and mark like&nbsp;
                                <strong>Shipped</strong></Button>
                        }

                        {selectedOrder && selectedOrder?.orderType !== null &&
                            <Button
                                onClick={() => setComponentMode(modes.ALL_KITS_ASSEMBLED)}
                                type={'primary'}
                                size={'small'}
                            >
                                Finish the order and mark as&nbsp;
                                <strong>Shipped</strong></Button>
                        }


                        {/*{selectedOrder && selectedOrder?.orderType == "PromoKitsOrder" &&*/}
                        {/*    <Button*/}
                        {/*        onClick={() => setComponentMode(modes.ALL_KITS_ASSEMBLED)}*/}
                        {/*        type={'primary'}*/}
                        {/*        size={'small'}*/}
                        {/*    >*/}
                        {/*        Finish the order and mark as&nbsp;*/}
                        {/*        <strong>Shipped</strong></Button>*/}
                        {/*}*/}


                    </Space>

                    <Row style={{marginTop: '1rem'}}>
                        <Col lg={17}>
                            <Typography.Text>
                                <strong>Shipping Address:</strong>&nbsp;
                                {selectedOrder?.address_1}&nbsp;{selectedOrder?.address_2},&nbsp;
                                {selectedOrder?.city}, {selectedOrder?.state}, {selectedOrder?.postcode}
                            </Typography.Text>
                        </Col>

                        <Col lg={7}>
                            <Typography.Text>
                                <strong>Customer name:</strong>&nbsp;
                                {capitalizeFirstLetter(selectedOrder?.firstName)}&nbsp;
                                {capitalizeFirstLetter(selectedOrder?.lastName)}
                            </Typography.Text>
                        </Col>
                    </Row>


                    {selectedOrder?.orderType === null && <Progress
                        style={{marginTop: '1rem'}}
                        steps={55}
                        percent={Math.round(assembledPercentage)}
                        strokeColor={'#01a9ac'}
                    />}


                    {/*{selectedOrder?.isExpeditedShipping &&*/}
                    {/*    <>&nbsp;&nbsp;*/}
                    {/*        <Tag icon={<ThunderboltOutlined/>}*/}
                    {/*             color={'gold'}>Expedited*/}
                    {/*            shipping</Tag></>*/}
                    {/*}*/}

                    {/*{selectedOrder?.isExpeditedShipping &&*/}
                    {/*    <>&nbsp;&nbsp;*/}
                    {/*        <Tag icon={<ThunderboltOutlined/>}*/}
                    {/*             color={'gold'}>Expedited*/}
                    {/*            shipping</Tag></>*/}
                    {/*}*/}

                    {/*{selectedOrder?.isExpeditedShipping &&*/}
                    {/*    <>&nbsp;&nbsp;*/}
                    {/*        <Tag icon={<ThunderboltOutlined/>}*/}
                    {/*             color={'gold'}>Expedited*/}
                    {/*            shipping</Tag></>*/}
                    {/*}*/}

                    {/*{selectedOrder?.isExpeditedShipping &&*/}
                    {/*    <>&nbsp;&nbsp;*/}
                    {/*        <Tag icon={<ThunderboltOutlined/>}*/}
                    {/*             color={'gold'}>Expedited*/}
                    {/*            shipping</Tag></>*/}
                    {/*}*/}

                    {/*{selectedOrder?.isExpeditedShipping &&*/}
                    {/*    <>&nbsp;&nbsp;*/}
                    {/*        <Tag icon={<ThunderboltOutlined/>}*/}
                    {/*             color={'gold'}>Expedited*/}
                    {/*            shipping</Tag></>*/}
                    {/*}*/}

                    <Divider/>

                </Col>


                {selectedOrder?.orderType === "PromoMaterialsOrder" &&
                    <Col lg={24} style={{height: '50vh', overflow: "auto"}}>
                        <List
                            style={{overflow: "auto", maxHeight: "49vh"}}
                            dataSource={dataForPromoOrder?.cart}
                            renderItem={item =>
                                <Row>
                                    <Col lg={24}>
                                        <Typography.Paragraph>
                                            <Tag bordered={false}>Material name:</Tag> {item?.name}
                                        </Typography.Paragraph>

                                        <Typography.Paragraph>
                                            <Tag bordered={false}>Quantity:</Tag> {item?.quantity}
                                        </Typography.Paragraph>
                                        <Divider/>
                                    </Col>
                                </Row>
                            }
                        />
                    </Col>
                }


                {selectedOrder?.orderType === "PromoKitsOrder" &&
                    <Col lg={24} style={{height: '50vh', overflow: "auto"}}>
                        <List
                            style={{overflow: "auto", maxHeight: "49vh"}}
                            dataSource={dataForPromoOrder?.cart}
                            renderItem={item =>
                                <Row>
                                    <Col lg={24}>
                                        {productData.filter(product => product.id === item.product).map(filteredProduct => (
                                            <Typography.Paragraph key={filteredProduct.id}>
                                                <Tag bordered={false}>Product Name:</Tag>
                                                <Tag color={'rgb(1, 169, 172)'}>
                                                    {filteredProduct.name}
                                                </Tag>
                                            </Typography.Paragraph>
                                        ))}

                                        <Typography.Paragraph>
                                            <Tag bordered={false}>Quantity:</Tag> {item?.quantity}
                                        </Typography.Paragraph>
                                        <Divider/>

                                        {/*{JSON.stringify(item, null, 2)}*/}
                                        {/*{JSON.stringify(productData, null, 2)}*/}


                                    </Col>
                                </Row>
                            }
                        />
                    </Col>
                }


                {selectedOrder?.orderType === null &&
                    <Col lg={24} style={{height: '50vh', overflow: "auto"}}>
                        <List
                            style={{overflow: "auto", maxHeight: "49vh"}}
                            // loadMore={loadMore}
                            dataSource={kitsForOrder}
                            renderItem={kit =>
                                <SingleKitItem
                                    printLabel={printLabel}
                                    setSelectedOrder={setSelectedOrder}
                                    setKitsForOrder={setKitsForOrder}
                                    setLocalAssembledKitsCount={setLocalAssembledKitsCount}
                                    printerDevice={printerDevice}
                                    setPrinterDevice={setPrinterDevice}
                                    kit={kit}
                                    isAssembled={kit?.assembled_and_packed}
                                />
                            }
                        />
                    </Col>}


                {/*<Col lg={24}>*/}
                {/*    {JSON.stringify(selectedOrder, null, 2)}*/}
                {/*</Col>*/}


                {/*<Col lg={24}>*/}
                {/*    {JSON.stringify(dataForPromoOrder?.cart, null, 2)}*/}
                {/*</Col>*/}

            </Row>
        </Spin>
    ;


    const ALL_KITS_ASSEMBLED =
        <Row>

            <Col lg={24}>
                <Divider/>
                <Typography.Title level={3}>
                    <strong>Complete the order and mark as <span
                        style={{textTransform: 'uppercase'}}>Shipped</span></strong>
                </Typography.Title>
                <Divider/>
            </Col>

            <Col lg={24} style={{height: '50vh', overflow: "auto"}}>

                <Row>

                    <Col lg={24}>
                        <Typography.Text>
                            <strong>ORDER ID:</strong>&nbsp;
                            {orderId}
                        </Typography.Text>
                    </Col>

                    <Col lg={24}>
                        <Typography.Text>
                            <strong>Customer name:</strong>&nbsp;
                            {capitalizeFirstLetter(selectedOrder?.firstName)}&nbsp;
                            {capitalizeFirstLetter(selectedOrder?.lastName)}
                        </Typography.Text>
                    </Col>

                    <Col lg={24}>
                        <Typography.Text>
                            <strong>Customer phone:</strong>&nbsp;
                            {selectedOrder?.phone}
                        </Typography.Text>
                    </Col>

                    <Col lg={24}>
                        <Typography.Text>
                            <strong>Customer email:</strong>&nbsp;
                            {selectedOrder?.email}
                        </Typography.Text>
                    </Col>


                    <Col lg={24}>
                        <Typography.Text>
                            <strong>Shipping Address:</strong>&nbsp;
                            {selectedOrder?.address_1} {selectedOrder?.address_2}
                            {selectedOrder?.city}, {selectedOrder?.state}, {selectedOrder?.postcode}
                        </Typography.Text>
                    </Col>

                    <Divider/>

                    <Form>
                        <Form.Item label={'Shipping carrier:'} name={'shippingCarrier'}>
                            <Select
                                defaultValue={carrierId}
                                onChange={value => setCarrierId(value)}
                                options={[
                                    {
                                        label: "USPS",
                                        value: 1
                                    },
                                    {
                                        label: "FedEx",
                                        value: 2
                                    }
                                ]}
                                size={'small'}
                                placeholder={"shipping carrier"}
                                name={'carrierId'}
                            />
                        </Form.Item>

                        <Form.Item label={'Tracking number:'} name={'trackingNumber'}>
                            <Input
                                value={trackingNumber}
                                onChange={e => setTrackingNumber(e.target.value)}
                                size={'small'}
                                placeholder={"tracking number"}
                                name={'trackingNumber'}
                            />
                        </Form.Item>

                        <Form.Item label={'Tracking return:'} name={'trackingReturn'}>
                            <Input
                                value={trackingReturn}
                                onChange={e => setTrackingReturn(e.target.value)}
                                size={'small'}
                                placeholder={"tracking number"}
                                name={'trackingReturn'}
                            />
                        </Form.Item>


                        <Button
                            onClick={markOrderAsShipped}
                            type={'primary'} disabled={!trackingNumber ||
                            trackingNumber.length === 0}>
                            Save tracking numbers and mark order as shipped
                        </Button>
                    </Form>

                </Row>
            </Col>


            <Col lg={24}>
                {JSON.stringify(selectedOrder?.cart, null, 2)}
            </Col>

        </Row>


    /* ----------------- Render a component by mode context ----------------*/
    if (componentMode === modes.KITS_MANAGEMENT) {
        return KITS_MANAGEMENT;
    }

    if (componentMode === modes.ALL_KITS_ASSEMBLED) {
        return ALL_KITS_ASSEMBLED;
    }

    if (componentMode === modes.ORDER_SUCCESSFULLY_SHIPPED) {
        return <Result
            status="success"
            title="Order info successfully updated!"
            subTitle="Awesome!"
            extra={[
                <Button type="primary" key="console" onClick={() => setIsModalVisible(false)}>
                    Close the window
                </Button>
            ]}
        />
    }
}

export default OrderDetailsWithAllKits;