import React, {useState, useEffect} from "react";
import fetchOrderData from "./services/fetchOrderData";
import {Spin, Collapse, Tag, Descriptions, Button, Divider} from "antd";
import dayjs from "dayjs";

const OrderDetails = ({orderId}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // if (isOpen) {

        (async () => {
            try {
                setIsLoading(true);
                const response = await fetchOrderData(orderId);
                setOrderData(response);

            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        })();
        // }

    }, [orderId]);

    if (!orderId) return null;


    return <Spin spinning={isLoading}>
        <Descriptions size={'small'}>
            <Descriptions.Item label="Order ID">{orderData?.orderid}</Descriptions.Item>
            <Descriptions.Item label="First name">{orderData?.firstname}</Descriptions.Item>
            <Descriptions.Item label="Last name">{orderData?.lastname}</Descriptions.Item>
            <Descriptions.Item label="Status">{orderData?.status}</Descriptions.Item>
            <Descriptions.Item label="Address 1">{orderData?.address1}</Descriptions.Item>
            <Descriptions.Item label="Address 2">{orderData?.address2}</Descriptions.Item>
            <Descriptions.Item label="City">{orderData?.city}</Descriptions.Item>
            <Descriptions.Item label="State">{orderData?.state}</Descriptions.Item>
            <Descriptions.Item label="Postcode">{orderData?.postcode}</Descriptions.Item>
            <Descriptions.Item label="Phone">{orderData?.phone}</Descriptions.Item>
            <Descriptions.Item label="Email">{orderData?.email}</Descriptions.Item>
            <Descriptions.Item label="Created">{dayjs(orderData?.created).format('MM/DD/YYYY')}</Descriptions.Item>
            <Descriptions.Item label="Order Type">{orderData?.ordertype}</Descriptions.Item>
            <Descriptions.Item label="Tracking Number">{orderData?.trackingnumber}</Descriptions.Item>
            <Descriptions.Item label="Tracking Return">{orderData?.trackingreturn}</Descriptions.Item>
            <Descriptions.Item label="Product ID">{orderData?.productid}</Descriptions.Item>
            <Descriptions.Item label="Product Name">{orderData?.productname}</Descriptions.Item>
            <Descriptions.Item label="Quantity">{orderData?.quantity}</Descriptions.Item>
        </Descriptions>
        <Divider dashed={true}/>
    </Spin>
}

export default OrderDetails;


// orderid
// firstname
// lastname
// status
// address1
// address2
// city
// state
// postcode
// phone
// email
// created
// updated
// ordertype
// trackingnumber
// trackingreturn
// productid
// productname
// quantity