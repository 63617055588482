import React, {useState} from 'react';
import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {Button, message, Modal, Input, Tag} from 'antd';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";

const SalesGroupDeleteConfirmModal = ({id, shortname, setSalesGroupsList}) => {
    const api = useAuthAPI();
    const [open, setOpen] = useState(false);
    const [confirmInput, setConfirmInput] = useState('');

    const showModal = () => {
        setOpen(true);
    };
    const hideModal = () => {
        setOpen(false);
    };

    const deleteClientHandler = async () => {
        await api.delete(`/sales-groups/${id}/`)
            .then(response => {
                if (response.status === 204) hideModal();
                setSalesGroupsList(prevState =>
                    prevState.filter(saleGroup => saleGroup.id !== id));
                message.success({
                    content: `${shortname} was deleted!`,
                    duration: 2,
                });
            })
            .catch(err => console.log(err))
    };

    return <>
        <Modal
            closable={false}
            open={open}
            onOk={deleteClientHandler}
            onCancel={hideModal}
            okText={"Delete"}
            okButtonProps={{danger: true, type: 'primary', size: 'small', disabled: (confirmInput !== shortname)}}
            cancelButtonProps={{type: 'primary', size: 'small'}}
            cancelText="Cancel"
            // TODO add text warning about deleting everything connect with this element.
        >
            <ExclamationCircleOutlined/>&nbsp;
            Delete sale group: {shortname}?

            <br/><br/>
            Deleting this sale group you make nex actions too:
            <ul>
                <li>you will delete <strong>all client</strong> with them full information's;</li>
                <ul>
                    <li>you will delete <strong>all doctor's</strong> connected with this client;</li>
                    <li>you will delete <strong>all test's</strong> connected with this client;</li>
                </ul>

                <li>
                    you will delete every included in this group sales persons, with full information's about this
                    peoples;
                </li>
            </ul>

            Better solutions it's edit sale group or change sale group in client's / sales persons settings.

            <br/><br/>

            <section>
                For deleting, please input&nbsp;<Tag>{shortname}</Tag> in filed at the bottom <br/><br/>
                <Input size={'small'} placeholder={"Input sale group name"}
                       onChange={({target: {value}}) => setConfirmInput(value)}/>
            </section>
        </Modal>

        <Button
            disabled={true}
            danger
            size={'small'}
            //onClick={showModal} // [INFO] Still works good, but a lot of risks with deleting. Need to test it better.
        >
            <DeleteOutlined/>&nbsp;Delete
        </Button>
    </>;
}

export default SalesGroupDeleteConfirmModal;