import React, {useEffect, useState} from "react";
import pages from "../../../../Root/routerConfig";
import {Breadcrumb, Divider, Button, Col, Row, Statistic, Spin, Typography, Tag, message} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {NavLink} from "react-router-dom";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";


const Index = () => {
    /* Entry point for SuperUsers. The Dashboard page. */
    const api = useAuthAPI();
    const [total, setTotal] = useState({});
    const [loading, setLoading] = useState(true);
    const [stripeReportData, setStripeReportData] = useState(null);

    const getStripeDataForReport = async () => {
        try {
            const response = await api.get('/stripe/bo-main-website-commission/');
            setStripeReportData(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getClients = async () => {
        try {
            const response = await api.get('/all-clients/');

            setTotal(prevState => {
                return {...prevState, clients: response.data.count};
            });
        } catch (error) {
            console.error(error);
        }
    }

    const getSalesPersons = async () => {
        try {
            const response = await api.get('/sales-persons/');

            setTotal(prevState => {
                return {...prevState, salesPersons: response.data.count};
            });

        } catch (error) {
            console.error(error);
        }
    }

    const getSalesGroups = async () => {
        try {
            const response = await api.get('/sales-groups/');

            setTotal(prevState => {
                return {...prevState, salesGroups: response.data.count}
            });

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Set the loading state to true while fetching data
                await Promise.allSettled([getClients(), getSalesPersons(), getSalesGroups(), getStripeDataForReport()]);
                setLoading(false); // Set the loading state to false after fetching data
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };

        (async () => {
            try {
                await fetchData();
            } catch (error) {
                console.error(error);
                message.error(error.message);
            }
        })()
        // eslint-disable-next-line
    }, []);

    return <Spin tip="Loading..." spinning={loading}>
        <Row justify="center">

            <Col xs={24}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to={'/'}>
                            <HomeOutlined/>&nbsp;
                            Dashboard
                        </NavLink>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Typography.Title level={1}>
                    Dashboard
                </Typography.Title>
            </Col>

            <Divider/>

            <Col xs={24} lg={24}>
                <Row justify={'start'} gutter={0}>
                    <Col xs={24} md={24} lg={6}>
                        <Statistic title="Active Sales Groups" value={total.salesGroups}/>
                        <NavLink to={`/sales-groups`}>
                            <Button style={{marginTop: 10}} size={'small'} type="primary">
                                manage sales groups
                            </Button>
                        </NavLink>
                    </Col>

                    <Col xs={24} md={24} lg={6}>
                        <Statistic title="Active Client's" value={total.clients}/>
                        <NavLink to={`/clients`}>
                            <Button style={{marginTop: 10}} size={'small'} type="primary">
                                manage clients
                            </Button>
                        </NavLink>
                    </Col>

                    <Col xs={24} md={24} lg={6}>
                        <Statistic title="Active Sale Persons" value={total.salesPersons}/>
                        <NavLink to={`/sales`}>
                            <Button style={{marginTop: 10}} size={'small'} type="primary">
                                manage sales persons
                            </Button>
                        </NavLink>
                    </Col>

                </Row>
            </Col>

            <Divider/>


            <Col xs={24}>

                <Typography.Title level={5}>
                    Report for all orders from <Tag bordered={false}>https://efunctional.com</Tag>
                </Typography.Title>

                <Typography.Paragraph>
                    Real payments from the Stripe: <strong>${stripeReportData?.total_amount}</strong>
                </Typography.Paragraph>

                <Typography.Paragraph>
                    5% of total amount: <strong>${stripeReportData?.commission}</strong>
                </Typography.Paragraph>

                <Typography.Paragraph level={3}>
                    Total count of paid orders: <strong>{stripeReportData?.pi_items.length}</strong>
                </Typography.Paragraph>
                <Divider dashed/>


                {/*{stripeReportData && stripeReportData?.pi_items.map((item, index) => {*/}
                {/*    return <Row key={index} justify={'start'} gutter={0}>*/}
                {/*        <Col xs={24} md={24} lg={6}>*/}
                {/*            <Statistic title={item.title} value={item.value} precision={2} suffix={"$"}/>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*})}*/}

            </Col>
        </Row>
    </Spin>
}

export default Index;