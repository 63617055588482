import React, {useState} from 'react';
import {NavLink} from "react-router-dom";

import NewClientForm from "./NewClientForm/NewClientForm";
import ClientList from "./ClientList";
import {Col, Divider, Row, Breadcrumb, Typography} from "antd";
import {HomeOutlined} from "@ant-design/icons";


const ClientManagement = () => {
    const [clientsList, setClientsList] = useState([]);

    return <Row justify="center">
        <Col xs={24}>
            <Breadcrumb>

                <Breadcrumb.Item>
                    <NavLink to={'/'}>
                        <HomeOutlined/>&nbsp;
                        Dashboard
                    </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    Clients
                </Breadcrumb.Item>
            </Breadcrumb>

            <Typography.Title level={1}>
                Clients
            </Typography.Title>
        </Col>

        <Col xs={24}>
            <NewClientForm setClientsList={setClientsList}/>
            <Divider/>
            <ClientList clientsList={clientsList} setClientsList={setClientsList}/>
        </Col>
    </Row>;
}

export default ClientManagement;