import lyfeAPI from '../lyfeAPI'

const getAllKits = async (status = null, orderId = null) => {
    try {
        const params = {};
        if (status) params.status = status;
        if (orderId) params.order_id = orderId;

        const kits = await lyfeAPI.get('/kits/', {params});
        // console.log(kits.data);

        return kits.data;
    } catch (err) {
        console.error(err);
    }
}

export default getAllKits;