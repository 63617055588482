import pages from "../../../../Root/routerConfig";
import React, {useState, useEffect} from 'react';
import {useParams} from "react-router";
import {NavLink, useNavigate} from "react-router-dom";
import NewEmployerEmployeeForm from "./NewEmployerEmployeeForm";
import EmployerTestSettingsModal from "./EmployerTestSettingsModal";
import EmployerEmployeesDownloaderModal from "./EmployerEmployeesDownloaderModal";
import EmployerEmployeesList from "./EmployerEmployeesList";
import {Breadcrumb, Col, Divider, Row, Space, Tag, Spin, Typography, Button} from "antd";
import {HomeOutlined} from "@ant-design/icons";

import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";

const ClientManagement = () => {

    const api = useAuthAPI();

    let navigate = useNavigate();
    let {clientID} = useParams();
    const {sessionExpired, dashboard, clients} = pages;
    const [clientInfo, setClientInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [employerTests, setEmployerTests] = useState([]);
    const [employees, setEmployees] = useState([]);


    const getClientDetailsByID = async () =>
        await api.get(`/employer-clients/${clientID}`)
            .then(response => {
                setClientInfo(response.data);
            })
            .catch(err => console.log(err))
            .finally(() => {
                setLoading(false)
            })

    useEffect(() => {
        (async () => {
            try {
                await getClientDetailsByID();
            } catch (err) {
                console.log(err);
            }
        })();
        // eslint-disable-next-line
    }, []);


    const getAllProducts = async () =>
        await api.get(`/employer-tests/?client=${clientID}`)
            .then(response => {
                setEmployerTests(response?.data?.results);
            });

    useEffect(() => {
        (async () => {
            try {
                await getAllProducts();
            } catch (err) {
                if (err?.response?.status === 401) {
                    navigate(sessionExpired.url);
                } else {
                    console.log("getClientsList Error", err);
                }
            }
        })();
        // eslint-disable-next-line
    }, []);


    return <Spin spinning={loading}>
        <Row>
            <Col xs={24}>

                <Row justify={'space-between'}>
                    <Col span={24}>


                        <Typography.Title>
                            <Space>
                                {clientInfo && clientInfo?.name}&nbsp;
                                <Tag color="#b143bf" style={{display: "block"}}>
                                    Employer
                                </Tag>
                            </Space>
                        </Typography.Title>


                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <NavLink to={dashboard.url}><HomeOutlined/></NavLink>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item>
                                <NavLink to={clients.url}>{clients.title}</NavLink>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item>
                                <NavLink to={clients.url}>Employers list</NavLink>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item>{clientInfo && clientInfo?.name}</Breadcrumb.Item>
                        </Breadcrumb>

                    </Col>
                </Row>

                <Divider/>

                <Space>
                    <NewEmployerEmployeeForm
                        clientInfo={clientInfo}
                        setEmployees={setEmployees}
                    />

                    <EmployerTestSettingsModal
                        employerTests={employerTests}
                        clientInfo={clientInfo}
                    />

                    <EmployerEmployeesDownloaderModal
                        employerTests={employerTests}
                        clientInfo={clientInfo}
                        setEmployees={setEmployees}
                    />

                    <Button disabled danger size={'small'}>Delete Employer</Button>
                </Space>

                <Divider/>

                <EmployerEmployeesList
                    clientID={clientID}
                    employees={employees}
                    setEmployees={setEmployees}
                />

            </Col>
        </Row>
    </Spin>;
}


export default ClientManagement;