import React, {useState, useEffect, useRef} from 'react';
import {useReactToPrint} from 'react-to-print';
import {TrophyOutlined, PrinterOutlined} from '@ant-design/icons';
import {Row, Col, Button, Space, Modal, Spin, Typography, Table, Alert, Badge, Tag, Divider} from 'antd';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import styled from 'styled-components';


const PrintingDiv = styled.div`
    @media print {
        & {
            padding: 80px;
        }
    }
`;

const PrintableComponent = React.forwardRef(({printHeaderVisible, columns, data, title}, ref) => {
    return (
        <PrintingDiv ref={ref}>
            {printHeaderVisible && <Typography.Title level={2}>{title}</Typography.Title>}

            <Typography.Paragraph>
                This report shows the total revenue generated by each salesperson for all Efunctional lifetime.
                We not showing the salespersons who have not any sales.
            </Typography.Paragraph>

            <Table
                columns={columns}
                dataSource={data}
                size="small"
                pagination={false}
                sticky={true}
            />
        </PrintingDiv>
    );
});

const RevenueDetails = () => {
    const api = useAuthAPI();
    const [loadData, setLoadData] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [revenueDetails, setRevenueDetails] = useState([]);
    const [error, setError] = useState(null);

    const componentRef = useRef();
    const [printHeaderVisible, setPrintHeaderVisible] = useState(false);

    useEffect(() => {

        if (!loadData) return; // Only fetch data when loadData is true

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await api.get('/sales-persons/revenue-details/');
                setRevenueDetails(response.data.results);
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        }

        (async () => {
            await fetchData();
        })()

    }, [loadData]);

    const columns = [
        {
            title: 'Sales Person',
            dataIndex: 'sales_person_name',
            key: 'sales_person_name',
            width: '20%',
        },
        {
            title: 'Salesperson Commission',
            dataIndex: 'total_commission',
            key: 'total_commission',
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend'],
            width: '15%',
            align: 'center',
            render: text => `$${text}`,
            sorter: (a, b) => a.total_commission - b.total_commission,
        },
        {
            title: 'Details',
            dataIndex: 'groups',
            key: 'groups',
            width: '30%',
            align: 'right',
            render: (groups, obj) => {
                const groupElements = groups.reduce((accumulator, group, index) => {
                    // Summing the total kits sold
                    const totalKitsSold = accumulator.totalKitsSold + group.kits_was_sold;

                    // Building up the JSX elements
                    const elements = [
                        ...accumulator.elements,
                        <Col xs={24} key={index}>
                            <strong>{group.group_name.toUpperCase()}</strong> - Sold {group.kits_was_sold} kits
                        </Col>
                    ];

                    return {totalKitsSold, elements};
                }, {totalKitsSold: 0, elements: []});

                return (
                    <Row>
                        {/*{groupElements.elements}*/}
                        <Col xs={24}>Total Clients' Revenue: ${obj.total_clients_revenue}</Col>
                        <Col xs={24}>Total Kits Sold: {groupElements.totalKitsSold}</Col>
                    </Row>
                );
            },
        },
        // {
        //     title: 'Sales-groups Revenue',
        //     dataIndex: 'total_clients_revenue',
        //     key: 'total_clients_revenue',
        //     width: '15%',
        //     align: 'right',
        //     render: text => `$${text}`,
        // },
    ];

    // Prepare the data for the table
    const data = revenueDetails
        .filter(item => item.total_commission !== 0)
        .map((item, index) => {
            return {
                key: index,
                sales_person_name: item.sales_person_full_name.toUpperCase(),
                total_commission: item.total_commission,
                total_clients_revenue: item.total_clients_revenue,
                groups: item.groups.map(group => ({
                    client_count: group.client_count,
                    group_name: group.group_name,
                    salesgroup_revenue: group.salesgroup_revenue,
                    salesperson_commission: group.salesperson_commission,
                    kits_was_sold: group.kits_was_sold
                })),
            };
        });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                setPrintHeaderVisible(true);
                setTimeout(resolve, 0);
            });
        },
        onAfterPrint: () => setPrintHeaderVisible(false),
    });

    return <>
        <Space>
            <Button size={'small'} type="dashed" icon={<TrophyOutlined/>} onClick={() => {
                setIsModalVisible(1);
                setLoadData(true); // Set loadData to true when button is clicked
            }}>
                Revenue Details
            </Button>
        </Space>

        <Modal
            open={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
        >
            <Spin spinning={isLoading}>
                <Row justify="center">

                    {error && <Col xs={24}><Alert message={error} type="error" showIcon/></Col>}

                    <Col xs={24}>
                        <Typography.Title level={4}>
                            <Space>
                                Sale persons revenue Details
                                <Button
                                    icon={<PrinterOutlined/>}
                                    onClick={handlePrint}
                                    size={'small'}>
                                    Print Report
                                </Button>
                            </Space>
                        </Typography.Title>

                        <PrintableComponent
                            title={'Sale persons revenue Details'}
                            ref={componentRef}
                            printHeaderVisible={printHeaderVisible}
                            columns={columns}
                            data={data}
                        />
                    </Col>
                </Row>
            </Spin>

        </Modal>
    </>;
};

export default RevenueDetails;