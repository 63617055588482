import axios from 'axios';


const restAPI = axios.create({
    // baseURL: 'http://0.0.0.0:8080/api/v0',
    baseURL: 'https://django-api.efunctional.com/api/v0',
    headers: {
        'Content-Type': 'application/json',
    },
});

export default restAPI;

