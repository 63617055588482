import axios from 'axios';


const lyfeAPI = axios.create({
    baseURL: 'https://lyfe.linkingapimid.com/api/v0',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDIzNDI0MDMsImV4cCI6MjMzMzA2MjQwM30.oe39AxE65GgbOS2mXNx99rMTshWo7itzZyOdbfJqj7Q'
    },
});

export default lyfeAPI;