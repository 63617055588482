import React, {useState, useEffect} from "react";
import fetchPatientData from "./services/fetchPatientData";
import {Spin, Collapse, Tag, Descriptions, Button, Divider} from "antd";

const PatientDetails = ({patientId}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [patientData, setPatientData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // if (isOpen) {

        (async () => {
            try {
                setIsLoading(true);
                const response = await fetchPatientData(patientId);
                setPatientData(response);

            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        })();
        // }

    }, [patientId]);


    const items = [
        {
            key: '2',
            label: 'Patient Details',
            children: '',
        },
    ];

    return <Spin spinning={isLoading}>
        <Descriptions size={'small'}>
            <Descriptions.Item label="First name">{patientData?.first_name}</Descriptions.Item>
            <Descriptions.Item label="Last name">{patientData?.last_name}</Descriptions.Item>
            <Descriptions.Item label="Email">{patientData?.email}</Descriptions.Item>
            <Descriptions.Item label="Phone">{patientData?.phone}</Descriptions.Item>
            <Descriptions.Item label="Sex">{patientData?.gender}</Descriptions.Item>
            <Descriptions.Item label="DOB">{patientData?.date_of_birth}</Descriptions.Item>
            <Descriptions.Item label="Address 1">{patientData?.address_1}</Descriptions.Item>
            <Descriptions.Item label="Address 2">{patientData?.address_2}</Descriptions.Item>
            <Descriptions.Item label="City">{patientData?.city}</Descriptions.Item>
            <Descriptions.Item label="State">{patientData?.state}</Descriptions.Item>
            <Descriptions.Item label="Postcode">{patientData?.postcode}</Descriptions.Item>
        </Descriptions>
        <Divider dashed={true}/>
    </Spin>
}

export default PatientDetails;