import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import pages from "../../../../Root/routerConfig";

import {Col, Divider, Row, Breadcrumb} from "antd";
import {HomeOutlined} from "@ant-design/icons";

import OrdersList from "./OrdersList";
// import PrinterPaired from "./PrinterPaired";

const OrdersManagement = () => {
    // const dispatch = useAppDispatch();
    const {printer} = pages;
    const [ordersList, setOrdersList] = useState([]);
    const [printerDevice, setPrinterDevice] = useState(null);


    return <Row justify="center">

        <Col xs={24}>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to={'/'}>
                        <HomeOutlined/>
                    </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    {printer.title}
                </Breadcrumb.Item>
            </Breadcrumb>

            <h1>{printer.title}</h1>
        </Col>

        <Col xs={24}>
            {/*<NewClientForm setOrdersList={setOrdersList}/>*/}
            {/*<PrinterPaired printerDevice={printerDevice} setPrinterDevice={setPrinterDevice}/>*/}

            <Divider/>
            <OrdersList
                ordersList={ordersList}
                setOrdersList={setOrdersList}
                printerDevice={printerDevice}
                setPrinterDevice={setPrinterDevice}
            />
        </Col>
    </Row>;
}

export default OrdersManagement;