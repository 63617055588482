import React, {useEffect, useContext, useState} from 'react'
import {useNavigate} from "react-router-dom";

import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Row, Col, Button, Form, Input, Alert, Typography} from 'antd';

import {AuthContext} from "../../AuthContext";
import restAPI from "../../../features/restAPI";
import jwt_decode from "jwt-decode";
import {useAuthAPI} from "../../../features/hooks/useAuthAPI";

const LoginForm = () => {


    const navigate = useNavigate();

    const {authContext, login, authConfig} = useContext(AuthContext);

    const [authError, setAuthError] = useState(null);  // state for login error
    const [isLogin, setIsLogin] = useState(authContext);  // local component state for auth

    const api = useAuthAPI();


    // First call to get tokens. When error - we show a server message.
    const submitLoginFormHandler = async formValues => {

        try {

            // clear errors if we have any
            setAuthError(null);

            const response = await restAPI.post(`/token/`, {...formValues});


            // Get user info
            const userID = jwt_decode(response.data.access)['user_id'];

            const userResponse = await restAPI.get(`/users/${userID}/`, {
                headers: {
                    Authorization: `ECL ${response.data.access}`,
                },
            });

            // Save to AuthProvider
            await login({access: response.data.access, refresh: response.data.refresh, user: userResponse.data});
            setIsLogin(true);  // update Auth state in the component, for redirect

        } catch (error) {

            console.log(error);

            // These errors we can get from API
            if (error?.response?.data.hasOwnProperty('error')) {
                setAuthError(error?.response?.data?.error);
                return;
            }

            // Check where we can manage this error
            if (error?.response?.data.hasOwnProperty('detail')) {
                setAuthError(error.response?.data?.detail);
            }
        }
    };


    // Login redirect controller
    useEffect(() => {
        // navigate('/');  // These need it for control all-other links, when we are not AUTH yet

        if (isLogin) {
            document.title = 'Login to back-office';
            navigate('/');  // move to the main page after successful auth
        }

        // eslint-disable-next-line
    }, [navigate, isLogin]);


    return <Row justify={'center'}>

        <Col sm={24} xs={24} md={12} lg={4}>

            <Typography.Title level={2} underline={true} style={{textAlign: "center", margin: "150px 0 40px 0"}}>
                Login to back-office
            </Typography.Title>

            <Form

                autoComplete={'true'}
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={submitLoginFormHandler}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Username"/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                    ]}
                >
                    <Input
                        name="password"
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                <Form.Item>
                    <Row justify={'center'}>
                        <Button type="primary" htmlType="submit" className="login-form-button" style={{marginTop: 20}}>
                            Log-in to system
                        </Button>
                    </Row>
                </Form.Item>
            </Form>

            {/*todo - show errors here*/}
            {authError && <Alert message={authError} type="error" showIcon/>}
        </Col>
    </Row>
}

export default LoginForm;