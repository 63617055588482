import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import BreadcrumbsList from "./BreadcrumbsList";
import SaleGroupClientsList from "./SaleGroupClientsList";
import SaleGroupSalesList from "./SaleGroupSalesList";
import {Col, Divider, Row, Card, Tag, Spin} from "antd";

import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";


const SaleGroupDetails = () => {
    const api = useAuthAPI();
    let navigate = useNavigate();
    const {saleGroupID} = useParams();
    const [loading, setLoading] = useState(true);
    const [clientsList, setClientsList] = useState([]);
    const [salesList, setSalesList] = useState(null);
    const [saleGroupObject, setSaleGroupObject] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                await api.get(`/sales-groups/${saleGroupID}/`)
                    .then(response => {
                        console.log(response?.data);
                        setSaleGroupObject(response?.data);
                    })
            } catch (err) {
                if (err.response.status === 401) {
                    navigate('/session-expired');
                } else {
                    console.log("SaleGroupDetails Error", err);
                }
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, []);

    return <Spin spinning={loading}>
        <Row justify="start" gutter={24}>

            <Col xs={24}>
                <BreadcrumbsList saleGroupObject={saleGroupObject}/>

                <h1>{saleGroupObject?.shortname}</h1>
            </Col>

            <Divider/>

            <Col xs={24} md={24} lg={6}>
                <Card title={"Sales Persons"}>

                    <SaleGroupSalesList
                        saleGroupObject={saleGroupObject}
                        salesList={salesList}
                        setSalesList={setSalesList}
                        saleGroupID={saleGroupID}
                    />

                    <Card.Meta description={
                        <Tag>Sales person count: {saleGroupObject?.salesPersonsDetails.length}</Tag>
                    }

                    />
                </Card>
            </Col>

            <Col xs={24} md={24} lg={18}>
                <Card title={"Clients"}>
                    <SaleGroupClientsList
                        clientsList={clientsList}
                        setClientsList={setClientsList}
                        saleGroupID={saleGroupID}
                    />
                    <Card.Meta description={<Tag>Client count: {clientsList?.length}</Tag>}/>
                </Card>
            </Col>
        </Row>
    </Spin>;
}

export default SaleGroupDetails;