import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import {Col, Divider, Row, Breadcrumb, Typography} from "antd";
import {HomeOutlined} from "@ant-design/icons";

import OrdersList from "./OrdersList";

const OrdersManagement = () => {
    const [ordersList, setOrdersList] = useState([]);


    return <Row justify="center">

        <Col xs={24}>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to={'/'}>
                        <HomeOutlined/>&nbsp;
                        Dashboard
                    </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    Supply Orders
                </Breadcrumb.Item>
            </Breadcrumb>

            <Typography.Title level={1}>Supply Orders</Typography.Title>
        </Col>

        <Col xs={24}>
            <Divider/>
            <OrdersList ordersList={ordersList} setOrdersList={setOrdersList}/>
        </Col>
    </Row>;
}

export default OrdersManagement;