// import axios from "axios";
import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

import {PlusOutlined} from '@ant-design/icons';
import {Button, Col, Drawer, Form, Input, InputNumber, message, Row, Space} from 'antd';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";


const initialState = {
    shortname: null,
    split_percent: null,
    description: null,
}


const NewSalesGroupForm = ({userTokens, setSalesGroupsList}) => {
    const navigate = useNavigate();
    const {TextArea} = Input;
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(initialState);

    const api = useAuthAPI();

    const submitForm = async () => {
        const {shortname, split_percent, description} = formData;

        await api.post(`/sales-groups/`, {
            "shortname": shortname,
            "split_percent": split_percent,
            "active": true,
            "description": description,
        })
            .then((response) => {
                setSalesGroupsList(prevState => [response.data, ...prevState].map(c => c));
            });
    };

    const key = 'newSalesGroup';

    const triggerSubmitHandler = () => form.submit();

    const sendFormWithMessage = () => {
        setOpen(false);
        message.loading({
            content: 'Loading...',
            key,
        });
        setTimeout(() => {
            submitForm()
                .then(() => {
                    setFormData(initialState);
                    form.resetFields();
                    message.success({
                        content: 'Sales group created!',
                        key,
                        duration: 2,
                    });
                })
                .catch((err) => {
                    console.log(err);

                    if (err.response.status === 401) {
                        navigate('/session-expired');
                    } else {
                        console.log("getClientsList Error", err);
                    }

                    message.error({
                        content: 'We have an errors!',
                        key,
                        duration: 2,
                    });

                    setOpen(true);
                })
        }, 1000);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Space>
                <Button size={'small'} type="primary" onClick={showDrawer} icon={<PlusOutlined/>}>
                    New sales group
                </Button>
            </Space>

            <Drawer
                title="Create a new sales group"
                width={window.innerWidth > 1200 ? 780 : '100%'}
                onClose={onClose}
                open={open}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={triggerSubmitHandler} type="primary">
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={sendFormWithMessage}
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="shortname"
                                label="Shortname"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter shortname',
                                    },
                                ]}
                            >
                                <Input
                                    onChange={({target: {value}}) => setFormData({...formData, shortname: value})}
                                    placeholder="Please enter shortname"
                                />
                            </Form.Item>
                        </Col>


                        <Col span={12}>
                            <Form.Item
                                name="split_percent"
                                label="Split percent, %"
                                rules={[
                                    {
                                        type: 'number',
                                        required: true,
                                        message: 'Please specify split percent',
                                        min: 1,
                                        max: 100,
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{width: '100%',}}
                                    min={1} max={100}
                                    onChange={value => setFormData({
                                        ...formData,
                                        split_percent: value
                                    })}
                                    placeholder="Please specify split percent"
                                />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="notes" label="Description for this sales group">
                                <TextArea
                                    value={formData.notes}
                                    onChange={({target: {value}}) => setFormData({...formData, description: value})}
                                    placeholder="Please add notes"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </>
    );
};

export default NewSalesGroupForm;