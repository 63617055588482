import dayjs from "dayjs";
import pages from "./routerConfig";
import React, {useState, useEffect, useRef, useLayoutEffect, useContext} from "react";
import {NavLink, Outlet, Route, Routes, useLocation, useNavigate, Navigate} from "react-router-dom";
import {AuthContext} from "../../AuthContext";
import {LogoutOutlined} from '@ant-design/icons';
import {Col, Layout, Menu, Row, Typography} from 'antd';
import SuperUserMenu from '../AppUsers/SuperUser/Menu'
import SalesPersonUserMenu from '../AppUsers/Sales/Menu'

import {AuthRequired, RoleBasedAuth} from "../../AuthProvider";


export default function Root() {
    const location = useLocation();

    // const {
    //     dashboard,
    //     login,
    //     logout,
    //     register,
    //     forgotPassword,
    //     sessionExpired,
    //     notFoundScreen,
    //     sales,
    //     saleDetails,
    //     contracts,
    //     clients,
    //     employerDetail,
    //     providerDetail,
    //     salesGroups,
    //     saleGroupDetails,
    //     ordersManagement,
    //     patientsManagement,
    //     patientDetails,
    //     printer,
    //     allBilledKitsManagement,
    //     kitsManagement,
    //
    //     // sales persons
    //     salesPersonClientsPage,
    //
    //     // lyfe supplies
    //     lyfeSupplies,
    //     efSupplies
    // } = pages;

    useEffect(() => {
        // Find the current page from the route configuration
        const currentPage = pages.find(route => route.path === location.pathname);

        // If the current page is found and has a title, set the document title
        if (currentPage && currentPage.title) {
            document.title = `${currentPage.title} | Efunctional`;
        }
    }, [location.pathname]);

    // return <Routes>
    //     <Route element={<LayoutRouter/>}>
    //
    //         {/* Enabled when AUTH only. */}
    //         {/* [INFO] This page decided what User type dashboard to render. Please CHECK IT! */}
    //         <Route path={dashboard.url} element={dashboard.jsx}/>
    //         {/* Enabled when AUTH only */}
    //
    //         {/* Enabled when NOT AUTH */}
    //         <Route path={login.url} element={login.jsx}/>
    //         <Route path={register.url} element={register.jsx}/>
    //         <Route path={forgotPassword.url} element={forgotPassword.jsx}/>
    //         <Route path={sessionExpired.url} element={sessionExpired.jsx}/>
    //         {/* Enabled when NOT AUTH */}
    //
    //         {/* SuperUser PATH's START (* config Menu in component dir: Menu.js)*/}
    //         <Route path={printer.url} element={printer.jsx}/>
    //         <Route path={contracts.url} element={contracts.jsx}/>
    //         <Route path={clients.url} element={clients.jsx}/>
    //         <Route path={employerDetail.url} element={employerDetail.jsx}/>
    //         <Route path={providerDetail.url} element={providerDetail.jsx}/>
    //         <Route path={ordersManagement.url} element={ordersManagement.jsx}/>
    //         <Route path={allBilledKitsManagement.url} element={allBilledKitsManagement.jsx}/>
    //         <Route path={kitsManagement.url} element={kitsManagement.jsx}/>
    //         <Route path={patientsManagement.url} element={patientsManagement.jsx}/>
    //         <Route path={patientDetails.url} element={patientDetails.jsx}/>
    //         <Route path={sales.url} element={sales.jsx}/>
    //         <Route path={saleDetails.url} element={saleDetails.jsx}/>
    //         <Route path={salesGroups.url} element={salesGroups.jsx}/>
    //         <Route path={saleGroupDetails.url} element={saleGroupDetails.jsx}/>
    //         {/* SuperUser PATH's END */}
    //
    //         {/* SalesPersons PATH's END */}
    //         <Route
    //             path={salesPersonClientsPage.url}
    //             element={salesPersonClientsPage.jsx}
    //         />
    //
    //         {/*<Route*/}
    //         {/*    path={salesPersonClientsPage.url}*/}
    //         {/*    element={salesPersonClientsPage.jsx}*/}
    //         {/*/>*/}
    //         {/* SalesPersons PATH's END */}
    //
    //         {/* Supply app PATH's START */}
    //         <Route path={lyfeSupplies.url} element={lyfeSupplies.jsx}/>
    //         <Route path={efSupplies.url} element={efSupplies.jsx}/>
    //         {/* Supply app PATH's END */}
    //
    //         {/* Enabled always */}
    //         <Route path={logout.url} element={logout.jsx}/>
    //         <Route path={notFoundScreen.url} element={notFoundScreen.jsx}/>
    //         {/* Enabled always */}
    //
    //     </Route>
    // </Routes>;

    return <Routes>
        <Route element={<LayoutRouter/>}> {/* Shared layout component */}
            {pages.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={
                        <AuthRequired> {/* Check if user is authenticated */}
                            {route.roles
                                ? <RoleBasedAuth allowedRoles={route.roles}>
                                    {<route.component/>}
                                </RoleBasedAuth>

                                : <route.component/>
                            }
                        </AuthRequired>
                    }
                />
            ))}
        </Route>
    </Routes>;
}

const Layout2 = () => {
    const {authContext} = useContext(AuthContext);
    const {Title} = Typography;
    const {
        Header,
        Content,
        Footer
    } = Layout;
    const navigate = useNavigate();

    /* ========= Default pages controller ========= */
    const itemsForRightMenu = [
        {
            key: '100',
            icon: <LogoutOutlined/>,
            label: 'Logout',
            onClick: () => {
                navigate("/logout")
            },
        },
    ];

    const [isShowFooterToBottom, setIsShowFooterToBottom] = useState(false);
    const contentRef = useRef(null);
    const location = useLocation();

    const handleFooterVisibility = () => {
        if (!contentRef.current) return;

        const contentRect = contentRef.current.getBoundingClientRect();
        const isContentTallerThanViewport = contentRect.height < window.innerHeight;

        setIsShowFooterToBottom(isContentTallerThanViewport);
    };

    useLayoutEffect(() => {
        handleFooterVisibility();
    }, [location]);


    /* Handling before layout rendered */
    // const {pathname} = useLocation();

    /* ==================Important===================
    * This code running before render first content on page and fix render problems.
    * At the bottom we can see all default pages, what works without Auth.
    * */

    // if (authContext === null && pathname === notAuthPages.resetPassword.url) {
    //     return notAuthPages.resetPassword.jsx
    // }

    // console.log(authContext);

    const {pathname} = useLocation();
    // if (authContext === null && pathname === '/') return pages.login.jsx;

    // Redirect to login if not authenticated and trying to access the root
    if (authContext === null && location.pathname === '/') {
        return <Navigate to="/login" replace/>;
    }

    return <Layout style={{background: "transparent", minHeight: '100vh'}}>

        <Header>
            <Row justify="space-between">

                {/*default logo block for all screens*/}
                <Col xs={12} md={12} lg={4} xxl={2} style={{justifySelf: "center", alignSelf: 'center'}}>
                    <NavLink to={'/'}>
                        <Title strong={'true'} style={{color: 'white', fontSize: 24, marginTop: "15px"}}>
                            Efunctional

                            <span style={{display: 'block', fontSize: 9, color: 'gold'}}>
                                {(authContext && authContext?.user?.is_sales) && 'sales portal'}
                            </span>

                            <span style={{display: 'block', fontSize: 9, color: 'gold'}}>
                                {(authContext && authContext?.user?.is_superuser) && 'superuser portal'}
                            </span>
                        </Title>
                    </NavLink>
                </Col>
                {/*default logo block for all screens*/}

                {/*/!* Custom menu START *!/*/}
                <Col xs={10} xxl={authContext ? 20 : 22} lg={authContext ? 18 : 22}>
                    {/*{(authContext && authContext?.user?.is_client) && <ClientMenu/>}*/}
                    {(authContext && authContext?.user?.is_sales) && <SalesPersonUserMenu menuPages={pages}/>}
                    {(authContext && authContext?.user?.is_superuser) && <SuperUserMenu menuPages={pages}/>}

                    {/*{authContext && <SuperUserMenu/>}*/}
                </Col>
                {/* Custom menu END */}

                {/* LogOut button visible only we have AUTH user */}
                {authContext &&
                    <Col xs={0} md={0} lg={2} style={{flex: 0}}>
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            items={itemsForRightMenu}
                        />
                    </Col>
                }
                {/* LogOut button visible only we have AUTH user END */}
            </Row>
        </Header>

        <Content ref={contentRef} style={{flexGrow: 1, padding: '20px 60px'}}>
            <Row>
                <Col span={24}>
                    <Outlet/>
                </Col>
            </Row>
        </Content>

        {isShowFooterToBottom &&
            <Footer
                style={{
                    textAlign: 'center',
                    background: '#001529',
                    color: 'white',
                }}
            >
                Back-office | Efunctional LLC © 2023-{dayjs().format('YYYY')}
            </Footer>
        }
    </Layout>;
};

const LayoutRouter = () => <Layout2/>;
