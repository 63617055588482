import React, {useState, useEffect} from "react";
import {Breadcrumb, Col, Divider, Row, Spin, Typography, Card} from "antd";
import {NavLink} from "react-router-dom";
import {HomeOutlined, MailOutlined, UserOutlined, MobileOutlined} from "@ant-design/icons";
import {useAuthAPI} from "../../../../../../../../features/hooks/useAuthAPI";

import NewProviderLocationForm from "./NewProviderLocationForm";

const Locations = ({clientID, clientInfo}) => {

    const api = useAuthAPI();

    const [locationsList, setLocationsList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getLocations = async () => {
        /* Loading all Provider locations */

        try {
            const response = await api.get(`/provider-locations/?provider_id=${clientID}`);

            setLocationsList([...response.data.results]);
            console.log(response.data.results);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (locationsList === null) {
            (async () => {
                await getLocations();
            })();
        }

    }, [getLocations]);

    return <Spin spinning={isLoading}>

        <Row>
            <Col lg={24}>

                <Typography.Title>
                    {clientInfo?.name} | Locations
                </Typography.Title>

                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to={'/'}>
                            <HomeOutlined/>&nbsp;
                            Dashboard
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/clients`}>
                            Clients
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/clients/providers/${clientID}`}>
                            {clientInfo?.name}
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Locations</Breadcrumb.Item>
                </Breadcrumb>

                <Divider/>

                <NewProviderLocationForm clientID={clientID} setLocationsList={setLocationsList}/>
            </Col>


            <Col span={24} style={{marginTop: 20}}>
                {/*list with locations*/}
                <Row gutter={[24, 24]}>
                    {locationsList !== null && locationsList.length > 0
                        ? locationsList.map((location, index) =>

                            <Col lg={8} key={index}>

                                <Card
                                    width={"100%"}
                                    bordered={true}
                                    hoverable={true}
                                    size="small"
                                    title={
                                        <Typography.Title
                                            level={4}>{location?.city}, {location?.state}, <br/>{location?.postcode}
                                        </Typography.Title>}
                                    // extra={<a href="#">Manage</a>}  todo - create this
                                >
                                    <p>
                                        <strong>Address:</strong>
                                        <Divider type={"vertical"}/>
                                        {location?.address_1}, {location?.address_2} <br/>

                                        <strong>City:</strong>
                                        <Divider type={"vertical"}/>
                                        {location?.city} <br/>

                                        <strong>State:</strong>
                                        <Divider type={"vertical"}/>
                                        {location?.state} <br/>

                                        <strong>Postcode:</strong>
                                        <Divider type={"vertical"}/>
                                        {location?.postcode} <br/>

                                        <strong>Notes:</strong> <br/>
                                        {location?.shipping_notes
                                            ? location?.shipping_notes
                                            : "No notes"}
                                    </p>

                                    <Divider/>

                                    <p>
                                        <strong> Location manager:</strong> <br/>

                                        <UserOutlined/>&nbsp;&nbsp;
                                        {location?.location_manager_last_name && location?.location_manager_first_name
                                            ? `${location?.location_manager_last_name} ${location?.location_manager_first_name}`
                                            : "Not set"}

                                        <br/>
                                        <MailOutlined/>&nbsp;&nbsp;
                                        {location?.location_manager_contact_email
                                            ? location?.location_manager_contact_email
                                            : "Not set"}

                                        <br/>
                                        <MobileOutlined/>&nbsp;&nbsp;
                                        {location?.location_manager_contact_phone
                                            ? location?.location_manager_contact_phone
                                            : "Not set"}
                                    </p>
                                </Card>


                            </Col>
                        )

                        : <Row gutter={[24, 24]}>
                            <Col style={{marginTop: 20}}>
                                Nothing to show
                            </Col>
                        </Row>
                    }
                </Row>
            </Col>


        </Row>
    </Spin>;
}

export default Locations;