import React from "react";
import dayjs from "dayjs";
// import {UserAddOutlined} from "@ant-design/icons";
// import {Button, Space, Typography, Modal, Input} from 'antd';
import {FinalizedOrdersTable} from "./styled-components/FinalizedTable";
import {Button, Tag} from "antd";
import {NavLink} from "react-router-dom";
import {UserOutlined} from "@ant-design/icons";


const TotalKitsTable = ({data}) => {
    const pagination = {position: ["top"],}


    const columns = [

        {
            title: 'Test',
            dataIndex: 'orderItem',
            key: 'orderItem',
            render: (orderItem, currentObject) => {
                if (['AF', 'AM',].includes(orderItem?.product_string)) return <Tag>
                    DOD Kit ({currentObject?.patient_details?.gender})
                </Tag>
                return <Tag>{orderItem?.product?.name}</Tag>
            },
            responsive: ['lg']
        },

        {
            title: 'Barcode',
            dataIndex: 'barcode',
            key: 'id',
            render: (text) => <Tag bordered={false}>{text}</Tag>,
        },


        {
            title: 'Kit status',
            dataIndex: 'status',
            key: 'id',
            render: (kitStatus) => {
                if (kitStatus === 'Final') return <Tag bordered={false} color={'#01a9ac'}>{kitStatus}</Tag>
                if (kitStatus === 'Activated') return <Tag bordered={false} color={'#3db464'}>{kitStatus}</Tag>
                if (kitStatus === 'Pending') return <Tag bordered={false} color={'#ccbc19'}>{kitStatus}</Tag>
                if (kitStatus === 'Replacement') return <Tag bordered={false} color={'#d70d1f'}>{kitStatus}</Tag>
                return <>{kitStatus}</>
            },
            // responsive: ['lg']
        },

        // {kit?.orderItem?.product?.name}

        {
            title: 'Patient',
            dataIndex: 'patient',
            render:
                (text, currentObject) =>
                    <>
                        {currentObject?.patient_details
                            ? <NavLink to={`/patients/${currentObject?.patient_details?.id}`}>
                                <Button type="dashed" size={'small'} icon={<UserOutlined/>}>
                                    {currentObject?.patient_details?.last_name} &nbsp;
                                    {currentObject?.patient_details?.first_name}
                                </Button>
                            </NavLink>

                            : <Tag bordered={false}>TBD</Tag>}
                    </>,
            responsive: ['lg']
        },

        // {
        //     title: 'Activated Date',
        //     dataIndex: 'dateActivated',
        //     key: 'address',
        //     render: (data) => <Tag
        //         bordered={false}>{dayjs(data).isValid() ? dayjs(data).format('YYYY-MM-DD') : "TBD"}</Tag>,
        //     responsive: ['lg',],
        // },

        // {
        //     title: 'Collection Date',
        //     dataIndex: 'collectionDate',
        //     key: 'collectionDate',
        //     render: (data) => <>{dayjs(data).isValid() ? dayjs(data).format('YYYY-MM-DD') : "Not set"}</>,
        //     responsive: ['lg',],
        // },


        {
            title: 'Final Date',
            dataIndex: 'finalDate',
            key: 'collectionDate',
            render: (data) => <Tag
                bordered={false}>{dayjs(data).isValid() ? dayjs(data).format('YYYY-MM-DD') : "TBD"}</Tag>,
            responsive: ['lg',],
        },

        {
            title: 'Is Paid?',
            dataIndex: 'isPaid',
            key: 'isPaid',
            render: isPaid =>
                <>{!!isPaid
                    ? <Tag color={'#01a9ac'}>Yes</Tag>
                    : <Tag color={'error'}>No</Tag>}
                </>,
            responsive: ['lg',],
        },

    ];

    return <FinalizedOrdersTable
        sticky={true}
        size={'small'}
        columns={columns}
        dataSource={data}
        pagination={pagination}
        scroll={false}
    />;

}

export default TotalKitsTable;