import states from './states.json'
import React from 'react';
import {AutoComplete, Form, Select} from 'antd';
import {CheckOutlined} from "@ant-design/icons";

const StatesAutoComplete = ({setFormData}) => {
    const statesArray = JSON.parse(states);
    const options = statesArray.map(state => ({
        label: state.name,
        value: state.name
    }));

    return <Form.Item
        name="state"
        label="State"
        rules={[
            {required: true, message: 'Please choose state'},
        ]}
    >
        <AutoComplete
            inputReadOnly
            aria-autocomplete={'none'}
            allowClear
            style={{width: "100%"}}
            options={options}
            placeholder="Please choose state"
            filterOption={(inputValue, option) =>
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onSelect={(value, {label}) => {
                const selectedState = statesArray.find((state) => state.name === label);

                // console.log(selectedState.postal);

                setFormData((prevState) => ({
                    ...prevState,
                    state: selectedState ? selectedState.postal : '',
                }));
            }}
        />
    </Form.Item>
}


const StatesAutoCompleteUseForm = ({form}) => {
    const statesArray = JSON.parse(states);
    const {Option} = Select;

    return (
        <Form.Item
            name="state"
            label="State"
            rules={[{required: true, message: 'Please choose state'}]}
        >
            <Select
                menuItemSelectedIcon={<CheckOutlined/>}
                showSearch
                placeholder="Please choose state"
                optionFilterProp="children"
                style={{width: "100%"}}
                onChange={(value) => form.setFieldsValue({state: value})}
            >
                {statesArray.map(state => (
                    <Option key={state.postal} value={state.postal}>
                        {state.name}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export {StatesAutoCompleteUseForm};

export default StatesAutoComplete;


// import states from './states.json'
// import statesNyIncluding from './states_ny_included.json'
// import React from 'react';
// import {Form, Select} from 'antd';
//
// const {Option} = Select;
//
// const StatesAutoCompleteUseForm = ({form, isNyStateIncluded = false}) => {
//
//     let statesJSON;
//
//     if (!isNyStateIncluded) {
//         statesJSON = states
//     } else {
//         statesJSON = statesNyIncluding
//     }
//
//     const statesArray = JSON.parse(statesJSON);
//
//
//     return (
//         <Form.Item
//             name="state"
//             label="State"
//             rules={[{required: true, message: 'Please choose state'}]}
//         >
//             <Select
//                 showSearch
//                 placeholder="Please choose state"
//                 optionFilterProp="children"
//                 style={{width: "100%"}}
//                 onChange={(value) => form.setFieldsValue({state: value})}
//             >
//                 {statesArray.map(state => (
//                     <Option key={state.postal} value={state.postal}>
//                         {state.name}
//                     </Option>
//                 ))}
//             </Select>
//         </Form.Item>
//     );
// };
//
//
// export {StatesAutoCompleteUseForm};

