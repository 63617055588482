import lyfeAPI from '../lyfeAPI'

const fetchOrderData = async (orderId) => {
    // todo : need to provide an API endpoint for this to work
    try {
        if (!orderId) throw new Error('Order ID is required');

        const orderData = await lyfeAPI.get(`/orders/${orderId}/`);

        return orderData.data;
    } catch (err) {
        throw new Error('Error fetching order data');
    }
}

export default fetchOrderData;