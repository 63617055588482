import React, {useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Drawer, Form, Input, message, Row, Space, Spin, Typography} from 'antd';
import {useAuthAPI} from "../../../../../../../../features/hooks/useAuthAPI";
import {phoneNumberMask} from "../../../../../../../../features/validation";
import {StatesAutoCompleteUseForm} from "../../../../components/StatesAutoComplete";


// todo - when we create a new order - we don't re-render a new order to Orders List. Fix it and provide solution
const NewProviderLocationForm = ({clientID, setLocationsList}) => {
    const key = 'newProviderLocationForm';

    const api = useAuthAPI();

    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [submitErrors, setSubmitErrors] = useState(null);  todo - show errors

    const triggerSubmitHandler = () => form.submit();


    const sendFormWithMessage = async formValues => {

        const updatedFormValues = {...formValues, provider: clientID};

        try {
            message?.loading({
                content: 'Loading...', key,
            });
            setIsLoading(true);

            const response = await api.post(`/provider-locations/`, updatedFormValues);

            message?.success({
                content: 'Your order was successfully generated.', key, duration: 3,
            });

            setOpen(false);

            form.resetFields();

            setLocationsList(prevState => [response.data, ...prevState,]);

            // console.log(response.data);

        } catch (error) {

            // TODO - SHOW ERRORS HERE!!!
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return <>

        <Row justify={'space-between'}>
            <Col span={20}>
                <Space>
                    <Button size={'small'} type="primary" onClick={showDrawer} icon={<PlusOutlined/>}>
                        Add new location
                    </Button>
                </Space>
            </Col>
        </Row>

        <Drawer
            title={<Typography.Title level={4}>New location</Typography.Title>}
            width={window.innerWidth > 1200 ? '45%' : '100%'}
            onClose={onClose}
            open={open}
            bodyStyle={{paddingBottom: 80}}
            extra={<Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={triggerSubmitHandler} type="primary">
                    Save
                </Button>
            </Space>}
        >
            <Spin spinning={isLoading}>

                <Form
                    layout="vertical"
                    form={form}
                    onFinish={sendFormWithMessage}
                    autoComplete="off"
                >
                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label={'Address 1'}
                                name="address_1"
                                rules={[{required: true, message: 'Please input address 1!',}]}
                            >
                                <Input placeholder="Address"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item label={'Address 2'} name="address_2">
                                <Input placeholder="Address 2"/>
                            </Form.Item>
                        </Col>


                        <Col xs={10} lg={8}>
                            <Form.Item
                                label={'City'}
                                name="city"
                                rules={[{required: true, message: 'Please input city!',}]}
                            >
                                <Input placeholder="City"/>
                            </Form.Item>
                        </Col>

                        <Col xs={5} lg={8}>
                            <StatesAutoCompleteUseForm form={form}/>
                        </Col>

                        <Col xs={9} lg={8}>
                            <Form.Item
                                label={'Postal code'}
                                name="postcode"

                                rules={[
                                    {required: true, message: 'Please input postal code!',},
                                    // eslint-disable-next-line
                                    {pattern: /^\d{5}$/, message: 'Please enter zip code. We expect 5 numbers',}
                                ]}
                            >
                                <Input placeholder="Postal code"/>
                            </Form.Item>
                        </Col>

                        <Col xs={9} lg={24}>
                            <Form.Item
                                label={'Shipping notes'}
                                name="shipping_notes"
                            >
                                <Input.TextArea placeholder="Shipping notes"/>
                            </Form.Item>
                        </Col>

                        <Divider orientation="right" dashed={true}>
                            <Typography.Title level={4}>Location manager info</Typography.Title>
                        </Divider>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={'First name'}
                                name="location_manager_first_name"
                            >
                                <Input placeholder="Manager first name"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={'Last name'}
                                name="location_manager_last_name"
                            >
                                <Input placeholder="Manager last name"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={'Phone'}
                                name="location_manager_contact_phone"
                                rules={[
                                    {
                                        pattern: /\((\d{3})\)\s(\d{3})-(\d{4})/,
                                        message: 'Please enter valid phone number',
                                    }
                                ]}
                            >
                                <Input
                                    onChange={({target: {value}}) => {
                                        const prettyPhoneNumber = phoneNumberMask(value);
                                        form.setFieldValue('location_manager_contact_phone', prettyPhoneNumber);
                                    }}
                                    placeholder="Please input phone"/>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={'Email'}
                                name="location_manager_contact_email"
                                rules={[{
                                    // eslint-disable-next-line
                                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                    message: 'Please enter valid email',
                                }]}
                            >
                                <Input placeholder="Email"/>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Spin>
        </Drawer>
    </>;
};

export default NewProviderLocationForm;