import React, {useContext, useState, useEffect} from 'react'
import {AuthContext} from "../../AuthContext";
import SuperUserDashboard from "../AppUsers/SuperUser/pages/Dashboard";
import SalesDashboard from "../AppUsers/Sales/pages/Dashboard";

const dashboardComponents = {
    'sales': SalesDashboard,
    'superuser': SuperUserDashboard,
    // ...
};

const HomeScreen = () => {
    const {authContext} = useContext(AuthContext);
    const [appUserRole, setAppUserRole] = useState(null);

    const getAppUserRole = () => {

        if (!authContext?.user?.is_superuser && authContext?.user?.is_sales) {
            setAppUserRole('sales');
            return;
        }

        if (authContext?.user?.is_superuser) {
            setAppUserRole('superuser');
            return;
        }

        throw new Error('Unknown user role')
    }

    useEffect(() => {
        getAppUserRole();
    }, [authContext]);

    const DashboardComponent = dashboardComponents[appUserRole && appUserRole];

    return <MetaLayer>
        {DashboardComponent
            ? <DashboardComponent/>
            : 'App loaded with issues'
        }
    </MetaLayer>;
};

const MetaLayer = ({children}) => <>{children}</>;

export default HomeScreen;