import React from "react";
import TotalKitsTable from "./TotalKitsTable";

const InventoryRouterComponent = props => {

    const {currentInventory, totalKits, finalizedKits, activatedKits, pendingKits, authConfig} = props;

    // TODO maybe I need run here async request for getting data and push it to needed component
    switch (currentInventory) {
        case 'Total':
            return <TotalKitsTable data={totalKits} authConfig={authConfig}/>;

        case 'In Stock':
            return <TotalKitsTable data={pendingKits} authConfig={authConfig}/>;

        case 'Activated':
            return <TotalKitsTable data={activatedKits} authConfig={authConfig}/>;

        case 'Finalized':
            return <TotalKitsTable data={finalizedKits} authConfig={authConfig}/>;

        default:
            return <TotalKitsTable data={totalKits} authConfig={authConfig}/>;
    }
}

export default InventoryRouterComponent;