import dayjs from "dayjs";
import React, {useEffect, useState} from 'react';

import {NavLink} from 'react-router-dom';
import {useParams} from "react-router";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import styled from "styled-components";
import {
    HomeOutlined, SyncOutlined, ProfileOutlined, FilePdfOutlined, LinkOutlined, CloseOutlined
} from "@ant-design/icons";
import {
    Breadcrumb, Col, Divider, Row, Space, Spin, Tag, Typography, Tooltip, Button, Modal, Alert, Drawer, Flex
} from "antd";
import ChangeThePassword from "./ChangeThePassword";
import ChangeTheUsername from "./ChangeTheUsername";
import ActivateTheKitForPatient from "./ActivateTheKitForPatient";


const ElaborateReportModal = styled(Modal)`
    .ant-modal-content {
        padding: 0 !important;
        z-index: 999 !important;

        .ant-modal-content::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5;
        }

        .ant-modal-content::-webkit-scrollbar {
            width: 10px;
            background-color: #F5F5F5;
        }

        .ant-modal-content::-webkit-scrollbar-thumb {
            background-color: #000000;
            border: 2px solid #555555;
        }
    }
`


const PatientsDetails = () => {
    /* Provider management component with own routing */

    const {patientID} = useParams();
    const api = useAuthAPI();

    const [isLoading, setIsLoading] = useState(true);
    const [patientInfo, setPatientInfo] = useState(null);
    const [patientKits, setPatientKits] = useState(null);

    // Elaborate ---
    const [selectedKIT, setSelectedKIT] = useState(null);
    const [open, setOpen] = useState(false);  // report modal state
    const [isReportLoading, setIsReportLoading] = useState(false);
    const [iframeLink, setIframeLink] = useState(null);
    const [reportError, setReportError] = useState(null);
    // Elaborate ---

    // VitaminLabs ---
    const [isDrawerOpened, setIsDrawerOpened] = useState(false);
    const [isCreateOrderLoading, setIsCreateOrderLoading] = useState(false);
    const [kind, setKind] = useState(null);  // kind of vitamins
    const [daysSupply, setDaysSupply] = useState(null);  // vitamins days supply
    // VitaminLabs ---

    const getAllKitsForCurrentPatient = async () => {
        /* Get current Provider Client by ID */

        try {
            setIsLoading(true);

            const response = await api.get(`/kits/all/?patient=${patientID}&ordering=-created`);
            setPatientKits(response.data.results);

        } catch (error) {
            // TODO check and display errors
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const getPatientDetailsByID = async () => {
        /* Get current Provider Client by ID */

        try {
            setIsLoading(true);

            const response = await api.get(`/patients/all/?id=${patientID}`);
            setPatientInfo(response.data.results[0]);

        } catch (error) {
            // TODO check and display errors
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    // Load all component data
    useEffect(() => {
        if (patientInfo === null || patientKits === null) {
            (async () => {
                try {
                    await Promise.all([
                        getPatientDetailsByID(), getAllKitsForCurrentPatient()
                    ]);

                } catch (err) {
                    console.log(err); // todo - handle error
                }
            })();
        }
        // eslint-disable-next-line
    }, []);


    // FS and FE test-kits reports
    const openGeneralPdf = () => {
        /* Default PDF-file with explanations for FS and FE test-kits */

        const storageURL = "https://efunctional-static-server.s3.us-east-1.amazonaws.com";
        const fileName = '/static_files/56%20pages%20report.pdf';
        const finalUrl = storageURL + fileName;

        window.open(finalUrl);
    };

    const openPersonalPdf = async (kitID, kitBarcode) => {
        /* Personal PDF-file with explanations for FS and FE test-kits.
           Works with FE, FS, MN test kits.
           Each barcode has its own PDF-file with unique content.
           PDF-url formula: f"{file_name_without_extension}_{kit.id}.pdf" */
        const storageURL = "https://efunctional-static-server.s3.us-east-1.amazonaws.com";
        const fileName = `/mediafiles/patients-pdf-reports/${kitBarcode}_${kitID}.pdf`;
        const finalUrl = storageURL + fileName;

        window.open(finalUrl);
    }
    // FS and FE test-kits reports

    const openECLPdf = async (kitID, kitBarcode) => {
        /* Personal PDF-file with explanations for FS and FE test-kits.
           Works with FE, FS, MN test kits.
           Each barcode has its own PDF-file with unique content.
           PDF-url formula: f"{file_name_without_extension}_{kit.id}.pdf" */
        const prefix = "AR_ECL";
        const storageURL = "https://efunctional-static-server.s3.us-east-1.amazonaws.com";
        const fileName = `/mediafiles/patients-pdf-reports/${prefix}_${kitBarcode}_${kitID}.pdf`;
        const finalUrl = storageURL + fileName;

        window.open(finalUrl);
    }


    // Elaborate ---
    const showModal = (kit) => {
        setSelectedKIT(kit);
        setOpen(true);
    };

    const handleOk = () => {
        handleCancel();
    };

    const handleCancel = () => {
        setOpen(false);
        setIframeLink(null);
        setReportError(null);
    };

    const getReportLink = async () => {
        /* Elaborate Report */
        const {id, patient, to_date, npi} = selectedKIT;

        const data = {
            "kitID": id,
            "patientID": patient,
            "doctorNPI": npi,  // for Patient, we can check only Patient info.
            to_date
        };

        try {
            setIsReportLoading(true);

            const response = await api.post(`/results/get-report-link/`, data)
            setIframeLink(response.data.secureURL);

        } catch (error) {

            if (error.hasOwnProperty('response')) {
                setReportError(error.response.data.error);
            }

            console.log(error);

        } finally {
            setIsReportLoading(false);
        }

    }


    useEffect(() => {
        if (open) {
            (async () => {
                await getReportLink();
            })();
        }
    }, [open])
    // Elaborate ---


    // VitaminLabs ---
    const showDrawerHandler = currentKIT => {
        setSelectedKIT(currentKIT);
        setIsDrawerOpened(true);
    }

    const onDrawerCloseHandler = () => {
        console.log('Clicked cancel button');
        setIsDrawerOpened(false);
        setSelectedKIT(null);
        setKind(null);
        setDaysSupply(null);
    }

    const kindChangeHandler = state => {
        console.log(state);
        setKind(state);
    }

    const daysSupplyChangeHandler = state => {
        console.log(state);
        setDaysSupply(state);
    }

    const makeNewVitaminLabsOrder = async action => {
        /*
        -> make request for creating a new order
        -> after api successfully answer
        -> redirect user to `VitaminLabs` with orderID in params */

        // console.log(action);
        // console.log(selectedKIT);

        const {first_name, last_name, id} = selectedKIT?.patient_details;

        // Prepare data for VitaminLabs
        const data = {
            "partner_uuid": selectedKIT?.id,
            "kind": kind,
            "days_supply": parseInt(daysSupply),
            "provider_id": selectedKIT?.provider,
            "meta": {
                first_name, last_name, "efunctionalID": id,
            }
        }

        try {

            setIsCreateOrderLoading(true);

            // Save order to Efunctional DB
            const response = await api.post('igs-vitamins/make-vitaminlab-order/', data);

            // console.log(response.data.orderID);

            if (action === 'buy') {
                // window.open(`https://wp-staging.getvitaminlab.com/efunctional-portal/?buy_now=true&formula_id=${response.data.orderID}`)
                window.open(`https://getvitaminlab.com/efunctional-portal/?buy_now=true&formula_id=${response.data.orderID}`)
            } else if (action === 'modify') {
                // window.open(`https://wp-staging.getvitaminlab.com/efunctional-portal/?formula_id=${response.data.orderID}`)
                window.open(`https://getvitaminlab.com/efunctional-portal/?formula_id=${response.data.orderID}`)
            }

        } catch (error) {

            console.log(error);

            // TODO make errors alerts and show user problem

        } finally {
            setIsCreateOrderLoading(false);
            onDrawerCloseHandler();  // destroy child's state too
        }

    }
    // VitaminLabs


    console.log(patientInfo?.user?.id);

    return <Spin spinning={isLoading}>

        <Row justify="center">

            <Col span={24}>
                <Typography.Title>
                    <Space>
                        {patientInfo && `${patientInfo?.first_name} ${patientInfo?.middle_name || ""} ${patientInfo?.last_name}`}&nbsp;
                        <Tag
                            style={{
                                display: "block",
                                background: "linear-gradient(to right, #01a9ac, #01dbdf)",
                                color: "#fff",
                                borderColor: "transparent"
                            }}
                        >
                            patient
                        </Tag>
                    </Space>
                </Typography.Title>


                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to={'/'}>
                            <HomeOutlined/>&nbsp;
                            Dashboard
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/patients`}>
                            Patients list
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        {patientInfo?.first_name} {patientInfo?.last_name}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>


            {/* Change the username and password */}
            <Space size={'small'}>
                <ActivateTheKitForPatient patientId={patientInfo?.id}/>
                <ChangeTheUsername authUserData={patientInfo?.user}/>
                <ChangeThePassword authUserId={patientInfo?.user?.id}/>
            </Space>
            {/* Change the username and password */}


            <Divider orientation="right" dashed={true}>
                <Typography.Title level={3}>
                    Information
                </Typography.Title>
            </Divider>


            <Col xs={24}>
                <p>
                    <strong>Full name:</strong>
                    <Divider type="vertical"/>
                    {patientInfo?.first_name} {patientInfo?.middle_name || ""} {patientInfo?.last_name}
                </p>

                <p>
                    <strong>DOB:</strong>
                    <Divider type="vertical"/>
                    {dayjs(patientInfo?.date_of_birth).format('MM/DD/YYYY')}
                </p>

                <p>
                    <strong>Email:</strong>
                    <Divider type="vertical"/>
                    {patientInfo?.email}
                </p>

                <p>
                    <strong>Gender:</strong>
                    <Divider type="vertical"/>
                    {patientInfo?.gender}
                </p>

                <p>
                    <strong>Phone:</strong>
                    <Divider type="vertical"/>
                    {patientInfo?.phone}
                </p>

                <p>
                    <strong>Status:</strong>
                    <Divider type="vertical"/>
                    {patientInfo?.active ? 'active patient' : 'not active patient'}
                </p>

                <p>
                    <strong>ID in Efunctional system:</strong>
                    <Divider type="vertical"/>
                    {patientInfo?.id}
                </p>

                <p>
                    <strong>Created At:</strong>
                    <Divider type="vertical"/>
                    {dayjs(patientInfo?.created).format('MM/DD/YYYY - HH:mm:ss A ')}
                </p>
            </Col>

            <Col xs={24}>

                <Divider orientation="right" dashed={true}>
                    <Typography.Title level={3}>
                        Kits list
                    </Typography.Title>
                </Divider>

                {patientKits && patientKits.map((kit, index) =>
                    <Col key={index}>

                        <Typography.Title level={4}>
                            {kit.barcode}
                        </Typography.Title>


                        <Typography.Paragraph>
                            <strong>Status:</strong> {kit?.status}
                        </Typography.Paragraph>

                        <Typography.Paragraph>
                            <strong>Activation:</strong>&nbsp;

                            {dayjs(kit?.dateActivated).isValid()
                                ? dayjs(kit?.dateActivated).format('MM/DD/YYYY - HH:mm:ss A')
                                : <Tag>TBD</Tag>
                            }
                        </Typography.Paragraph>

                        <Typography.Paragraph>
                            <strong>Collection date:</strong>&nbsp;
                            {dayjs(kit?.collectionDate).isValid()
                                ? dayjs(kit?.collectionDate).format('MM/DD/YYYY')
                                : <Tag>TBD</Tag>
                            }
                        </Typography.Paragraph>


                        <Typography.Paragraph>
                            <strong>Final date:</strong>&nbsp;
                            {dayjs(kit?.finalDate).isValid()
                                ? dayjs(kit?.finalDate).format('MM/DD/YYYY')
                                : <Tag>TBD</Tag>}

                        </Typography.Paragraph>


                        <Typography.Paragraph>
                            <strong>Reports:</strong>&nbsp;
                            <Space key={kit.id}>


                                {kit.test === "MN" && kit.status === 'Final'

                                    ?

                                    <>
                                        {kit?.vitamins === null || kit?.vitamins?.length === 0 ?
                                            <Tooltip placement="top" title={'Preparing vitamins report'}>
                                                <SyncOutlined spin={true}/>
                                            </Tooltip>

                                            : <>
                                                <Tooltip placement="top" title={'Vitamins list'}>
                                                    <Button icon={<ProfileOutlined/>}
                                                            onClick={() => showDrawerHandler(kit)}
                                                    />
                                                </Tooltip>

                                                <Tooltip placement="top" title={'Full report'}>
                                                    <Button icon={<LinkOutlined/>}
                                                            onClick={() => showModal(kit)}
                                                    />
                                                </Tooltip>

                                                <Tooltip placement="top" title={'IGS report'} zIndex={10}>
                                                    <Button
                                                        icon={<FilePdfOutlined/>}
                                                        onClick={() => openPersonalPdf(kit?.id, kit?.barcode)}
                                                    />
                                                </Tooltip>
                                            </>}
                                    </>

                                    : (kit.test === "AF" || kit.test === "AM") && kit.status === 'Final'

                                        ? <Space size={'small'}>
                                            <Tooltip placement="top" title={'ECL report'} zIndex={10}>
                                                <Button
                                                    type={'primary'}
                                                    icon={<FilePdfOutlined/>}
                                                    onClick={() => openECLPdf(kit?.id, kit?.barcode)}
                                                />
                                            </Tooltip>

                                            <Tooltip placement="top" title={'IGS report'} zIndex={10}>
                                                <Button
                                                    icon={<FilePdfOutlined/>}
                                                    onClick={() => openPersonalPdf(kit?.id, kit?.barcode)}
                                                />
                                            </Tooltip>

                                            <Tooltip placement="top" title={'Vitamins list'}>
                                                <Button icon={<ProfileOutlined/>}
                                                        onClick={() => showDrawerHandler(kit)}
                                                />
                                            </Tooltip>
                                        </Space>

                                        : (kit.test === "FS" || kit.test === "FE") && kit.status === 'Final'

                                            ? <Space>
                                                <Tooltip placement="top" title={'General info'} zIndex={10}>
                                                    <Button
                                                        icon={<FilePdfOutlined/>}
                                                        onClick={openGeneralPdf}
                                                    />
                                                </Tooltip>

                                                <Tooltip placement="top" title={'Personal report'} zIndex={10}>
                                                    <Button
                                                        icon={<FilePdfOutlined/>}
                                                        onClick={() => openPersonalPdf(kit?.id, kit?.barcode)}
                                                    />
                                                </Tooltip>
                                            </Space>

                                            : ["WM", "CE", "AP"].includes(kit?.test) && kit.status === 'Final'

                                                ? <Space>
                                                    <Tooltip placement="top" title={'Personal report'} zIndex={10}>
                                                        <Button
                                                            icon={<FilePdfOutlined/>}
                                                            onClick={() => openPersonalPdf(kit?.id, kit?.barcode)}
                                                        />
                                                    </Tooltip>
                                                </Space>

                                                : ["CC"].includes(kit?.test) && kit.status === 'Final' && kit?.finalDate >= '2023-12-29' // date for start
                                                    // using a new PDF report for CC test-kit

                                                    ? <Space>
                                                        <Tooltip placement="right" title={'Personal report'}
                                                                 zIndex={10}>
                                                            <Button
                                                                icon={<FilePdfOutlined/>}
                                                                onClick={() => openPersonalPdf(kit?.id, kit?.barcode)}
                                                            />
                                                        </Tooltip>
                                                    </Space>

                                                    : kit.test !== "MN" && kit.test !== "FE" && kit.status === 'Final'
                                                        ? <Tooltip placement="top" title={'Full report'}>
                                                            <Button icon={<LinkOutlined/>}
                                                                    onClick={() => showModal(kit)}
                                                            />
                                                        </Tooltip>
                                                        : <span>Pending</span>
                                }
                            </Space>
                        </Typography.Paragraph>

                        <Typography.Paragraph>
                            {kit?.provider_details
                                ? <>Bought this kit in <Tag>{kit?.provider_details?.name}</Tag></>
                                : kit?.partner
                                    ? `Partner (kit owner): ${kit?.partner}`
                                    : kit?.web_provider ? <>Bought this kit
                                            on <Tag>https://webprovider.efunctional.com</Tag></>
                                        : <>Bought this kit on <Tag>https://efunctional.com</Tag></>}
                        </Typography.Paragraph>


                        <Divider/>
                    </Col>
                )}
            </Col>


            {/*Elaborate Modal with IFrame ****---- START ----**** */}
            <ElaborateReportModal
                centered={true}
                destroyOnClose={true}
                closable={true}
                width={reportError ? window.innerWidth > 1200 ? '20%' : '92%' : '95%'}
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
            >

                {reportError && <Spin spinning={isReportLoading}>
                    <Row justify={"center"} gutter={[24, 24]} style={{padding: "20px"}}>
                        <Col>
                            <Alert style={{margin: "30px 0 0 0", textAlign: "center"}}
                                   message={reportError}/>
                        </Col>
                    </Row>
                </Spin>}

                {iframeLink && <iframe

                    width={'100%'}
                    height={'790px'}
                    loading={isReportLoading}
                    title={'Elaborate report'}
                    src={iframeLink}
                />}

                <Row justify={"center"} style={{padding: "0 0 20px 0"}}>
                    <Button
                        icon={reportError ? null : <CloseOutlined/>}
                        size={'small'}
                        type={reportError ? 'primary' : 'default'}
                        onClick={handleCancel}
                    >
                        {reportError ? 'Okay' : 'Close report'}
                    </Button>
                </Row>

            </ElaborateReportModal>
            {/*Elaborate Modal with IFrame ****---- END ----**** */}


            <Drawer
                destroyOnClose={true}
                bodyStyle={{width: '100%'}}
                size={'large'}
                placement={'right'}
                closeIcon={<CloseOutlined/>}
                width={window.innerWidth > 1200 ? '38%' : '92%'}
                closable={window.innerWidth <= 1200}
                onClose={onDrawerCloseHandler}
                open={isDrawerOpened}
            >
                <Row gutter={[12, 12]}>

                    <Col span={'24'}>
                        <Typography.Title
                            style={{
                                fontSize: 21,
                                fontWeight: 400,
                                textTransform: 'uppercase',
                                margin: '30px 0',
                                fontFamily: 'Montserrat',
                                color: '#353c4e'
                            }}>
                            CUSTOM SUPPLEMENTS
                        </Typography.Title>
                    </Col>

                    <Divider style={{margin: '-20px 0 20px 0'}}/>

                    <Col span={24}>
                        {selectedKIT?.vitamins.map(vitamin => <Row
                            style={{fontFamily: 'Montserrat', margin: '0 0 7px 0'}}>
                            <Col span={12} key={vitamin.id}>
                                <div>{vitamin.suggested}</div>
                            </Col>

                            <Col span={12}>
                                <strong>{vitamin.calc} {vitamin.frequency}</strong>
                            </Col>
                        </Row>)}
                    </Col>

                </Row>
            </Drawer>
        </Row>


    </Spin>
}

export default PatientsDetails;