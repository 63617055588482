import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";
import {Row, Col, Divider, Breadcrumb, Space, Typography} from "antd";
import SalePersonsList from "./SalePersonsList";
import NewSalesPersonForm from "./NewSalesPersonForm";
import RevenueDetails from "./RevenueDetails";

const SalesManagement = () => {
    const [salesList, setSalesList] = useState([]);

    return <Row justify="center">
        <Col xs={24}>
            <Breadcrumb>

                <Breadcrumb.Item>
                    <NavLink to={'/'}>
                        <HomeOutlined/>&nbsp;
                        Dashboard
                    </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    Salespersons
                </Breadcrumb.Item>
            </Breadcrumb>

            <Typography.Title level={1}>
                Salespersons
            </Typography.Title>

            <Space>
                <NewSalesPersonForm setSalesList={setSalesList}/>
                <RevenueDetails salesList={salesList}/>
            </Space>
        </Col>

        <Divider/>

        <Col xs={24}>
            <SalePersonsList
                salesList={salesList}
                setSalesList={setSalesList}
            />
        </Col>
    </Row>
};

export default SalesManagement;