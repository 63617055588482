import React from "react";
import {Breadcrumb, Col, Divider, Row, Spin, Typography} from "antd";
import {NavLink} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";

const Logs = ({clientID, isLoading, clientInfo}) => {

    return <Spin spinning={isLoading}>

        <Row>
            <Col span={24}>

                <Typography.Title>
                    {clientInfo?.name} | Logs
                </Typography.Title>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to={'/'}>
                            <HomeOutlined/>&nbsp;
                            Dashboard
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/clients`}>
                            Clients
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/clients/providers/${clientID}`}>
                            {clientInfo?.name}
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Logs</Breadcrumb.Item>
                </Breadcrumb>

                <Divider/>
            </Col>

        </Row>
    </Spin>;
}

export default Logs;