import React, {useState, useEffect} from 'react';
import dayjs from "dayjs";
import {StatesAutoCompleteUseForm} from "../../../../../../../reusable/StatesAutoComplete";
import {useAuthAPI} from "../../../../../../../../features/hooks/useAuthAPI";
import phoneNumberMask from "../../../../../../../../features/utils/phoneNumberMask";
import dateOfBirthMask from "../../../../../../../../features/utils/dateOfBirthMask";
import {PlusOutlined, MinusOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Drawer, Form, message, Row, Space, Spin, Typography, Input, Checkbox} from 'antd';


const MICRONUTRIENT_SELF_PHLEBOTOMY_PRODUCT_PRICE = 199;

// todo - when we create a new order - we don't re-render a new order to Orders List. Fix it and provide solution
const NewInfluencersOrderForm = ({clientInfo, authConfig}) => {
    const key = 'NewInfluencersOrderForm';

    const api = useAuthAPI();
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [submitErrors, setSubmitErrors] = useState(null);

    // new order state values
    const [isLoading, setIsLoading] = useState(true);
    const [orderList, setOrderList] = useState([]);

    const [emailInput, setEmailInput] = useState();
    const [dateValue, setDateValue] = useState('');
    const [isExpeditedShipping, setIsExpeditedShipping] = useState(false);

    const [shoppingCart, setShoppingCart] = useState([]);


    const validateDate = (_, value) => {
        if (!value || dayjs(value, 'MM-DD-YYYY', true).isValid()) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter a valid date'));
    };


    const handleManualInput = (e) => {
        const maskedValue = dateOfBirthMask(e.target.value);
        setDateValue(maskedValue);

        form.setFieldValue('date_of_birth', maskedValue);
    };
    const triggerSubmitHandler = () => form.submit();

    const submitForm = async (values) => {


        console.log(values);

        // check shopping cart, if - 0 -> stop
        if (shoppingCart.length === 0) {
            // setIsClicked(false);
            return false;
        }

        try {
            setIsLoading(true);

            // Prepare cart data for backend saving
            const cart = shoppingCart.map(
                p => ({product: parseInt(p.id), quantity: p.qty})
            );

            const orderData = {
                cart: [...cart],
                webProvider: parseInt(clientInfo?.id),
                isExpeditedShipping: isExpeditedShipping,
                ...values, // all data from the form with order receiver data
            };

            // Todo - change on backend side -> Add to generated kits `location` ID from `chosenLocation`

            await api.post(`/orders/save-new-influencer-order/`, orderData, authConfig);

            form.resetFields();
            setIsExpeditedShipping(false);
            setEmailInput('');
            resetCart();

            message?.success({
                content: 'Your order was successfully generated.', key, duration: 3,
            });

            setOpen(false);

        } catch (error) {

            if (error?.response?.data) {
                setSubmitErrors(error?.response?.data);
            }

            if (error?.messages) {
                setSubmitErrors(error?.messages);
            }

            setOpen(true);
            console.log(error);

            message?.error({
                content: `We have an errors! ${submitErrors}`, key, duration: 2,
            });
        } finally {
            setIsLoading(false);
        }
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };


    const loadProducts = async () => {

        try {
            setIsLoading(true);

            const response = await api.get('/products/', authConfig);


            if (clientInfo?.is_self_phlebotomy) {
                // console.log(response?.data?.results.filter(product => product?.code === 'MN'));
                const micronutrientProduct = response?.data?.results.filter(product => product?.code === 'MN')[0];
                micronutrientProduct.price = MICRONUTRIENT_SELF_PHLEBOTOMY_PRODUCT_PRICE;
                const allProducts = response?.data?.results.filter(product => product?.code !== 'MN');
                setOrderList([...allProducts, micronutrientProduct]);
                return;
            }


            setOrderList([...response?.data?.results]);

        } catch (error) {

            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (clientInfo) {
            (async () => {
                try {
                    await Promise.all([
                        loadProducts(),
                    ]);
                } catch (error) {
                    console.error('Error loading data:', error);
                    // Handle the error appropriately in your application
                }
            })();
        }
        // eslint-disable-next-line
    }, [clientInfo]);


    // Shopping cart code

    const addToCart = product => {
        const exist = shoppingCart.find((x) => x.id === product.id);

        if (exist && exist.qty < 20) {
            setShoppingCart(shoppingCart.map(x => x.id === exist.id ? {...exist, qty: exist.qty + 1} : x));
        }

        if (!exist) {
            setShoppingCart([...shoppingCart, {...product, qty: 1}]);
        }
    }

    const removeFromCart = product => {
        const exist = shoppingCart.find((x) => x.id === product.id);

        if (exist) {
            if (exist.qty === 1) {
                setShoppingCart(shoppingCart.filter((x) => x.id !== product.id));
            } else {
                setShoppingCart(shoppingCart.map((x) => x.id === product.id ? {...exist, qty: exist.qty - 1} : x));
            }
        }
    }

    const resetCart = () => {
        setShoppingCart([]);
    }


    const getProductQty = p => {
        const currentProduct = shoppingCart?.find(x => x.id === p.id);

        if (currentProduct) {
            return currentProduct.qty;
        } else {
            return 0;
        }
    }

    const emailValidationFunction = (email) => {
        // Email validation function
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    };

    return <>

        <Row justify={'space-between'}>
            <Col span={20}>
                <Space>
                    <Button size={'small'} type="primary" onClick={showDrawer} icon={<PlusOutlined/>}>
                        New Influencers order
                    </Button>
                </Space>
            </Col>
        </Row>

        <Drawer
            title={<h4>New order for influencer</h4>}
            width={window.innerWidth > 1200 ? '45%' : '100%'}
            onClose={onClose}
            open={open}
            bodyStyle={{paddingBottom: 80}}
            extra={<Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={triggerSubmitHandler} type="primary">
                    Place order
                </Button>
            </Space>}
        >
            <Spin spinning={isLoading}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={submitForm}
                    autoComplete="off"
                >

                    <Row gutter={16}>

                        <Col lg={24}>
                            <Form.Item
                                name="notes"
                                label={'Explanations about this order'}
                                rules={[{
                                    required: true, message: 'Please provide some notes about this order',
                                },]}
                            >
                                <Input.TextArea placeholder={"Pls provide some notes about this order"}/>

                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="first_name"
                                label="First name"
                                rules={[{
                                    required: true, message: 'Please input first name',
                                },]}
                            >
                                <Input placeholder={'First name'}/>
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="last_name"
                                label="Last name"
                                rules={[{required: true, message: ''}]}
                            >
                                <Input placeholder={'Last name'}/>
                            </Form.Item>
                        </Col>

                        {/*<Col lg={5} xs={24}>*/}
                        {/*    <Form.Item*/}
                        {/*        name="gender"*/}
                        {/*        label="Sex"*/}
                        {/*        rules={[{required: true, message: "Can't be empty"}]}*/}
                        {/*    >*/}
                        {/*        <Select*/}
                        {/*            menuItemSelectedIcon={<CheckOutlined/>}*/}
                        {/*            placeholder="Please choose a sex"*/}
                        {/*            defaultOpen={0}*/}
                        {/*            style={{width: '100%'}}*/}
                        {/*            options={[{label: "Male", value: "M"}, {label: "Female", value: "F"},]}*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}

                        {/*<Col lg={7} xs={24}>*/}
                        {/*    <Form.Item*/}
                        {/*        name="date_of_birth"*/}
                        {/*        label="Date of Birth"*/}
                        {/*        rules={[*/}
                        {/*            {required: true, message: 'Please enter your date of birth'},*/}
                        {/*            {validator: validateDate,}*/}
                        {/*        ]}*/}
                        {/*    >*/}
                        {/*        <Input*/}
                        {/*            value={dateValue}*/}
                        {/*            onChange={handleManualInput}*/}
                        {/*            placeholder="MM-DD-YYYY"*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}

                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter email',
                                    },
                                    {
                                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                        message: 'Please enter valid email',
                                    },
                                ]}
                            >
                                <Input
                                    name="email"
                                    allowClear={true}
                                    placeholder="Please enter email"
                                    value={emailInput}
                                />
                            </Form.Item>
                        </Col>


                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="phone"
                                label="Phone"
                                rules={[
                                    {required: true, message: 'Please enter phone',},
                                    {
                                        pattern: /\((\d{3})\)\s(\d{3})-(\d{4})/,
                                        message: 'Please enter valid phone number',
                                    }
                                ]}
                            >
                                <Input
                                    onChange={({target: {value}}) => {
                                        const prettyPhoneNumber = phoneNumberMask(value);

                                        form.setFieldValue('phone', prettyPhoneNumber);
                                    }}
                                    placeholder="Please enter phone"
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={10} xs={24}>
                            <Form.Item
                                name="address"
                                label="Address"
                                rules={[{
                                    required: true, message: 'Please input an address',
                                },]}
                            >
                                <Input placeholder={'Address'}/>
                            </Form.Item>
                        </Col>

                        <Col lg={6} xs={24}>
                            <Form.Item
                                name="city"
                                label="City"
                                rules={[{required: true, message: 'Please input a city'}]}
                            >
                                <Input placeholder={'City'}/>
                            </Form.Item>
                        </Col>

                        <Col lg={4} xs={24}>
                            <StatesAutoCompleteUseForm form={form}/>
                        </Col>


                        <Col lg={4} xs={24}>
                            <Form.Item
                                name="postcode"
                                label="Zip code"
                                rules={[{required: true, message: 'Please input a zip'}]}
                            >
                                <Input placeholder={'Zip code'}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider/>

                    {orderList?.map(product =>
                        <Row key={product.id}>
                            <Col span={10} xs={16} lg={14}>
                                <strong style={{fontSize: '12px'}}>
                                    {product.name} ({product.code})
                                </strong>
                            </Col>

                            <Col span={5} xs={0} lg={2}>
                                ${product.price}
                            </Col>

                            <Col span={12} xs={6} lg={8}>
                                <Space size={'large'}>
                                    <Button onClick={() => removeFromCart(product)} icon={<MinusOutlined/>}/>
                                    <Typography>{getProductQty(product)}</Typography>
                                    <Button icon={<PlusOutlined/>} onClick={() => addToCart(product)}/>
                                </Space>
                            </Col>

                            <Divider/>
                        </Row>
                    )}

                    {shoppingCart.length !== 0 &&
                        <Row>
                            <Col span={16}>
                                <h2>
                                    Total: ${shoppingCart.reduce((a, c) => a + c.qty * c.price, 0)}
                                </h2>
                            </Col>

                            <Col span={2}>
                                <Button size={'small'} onClick={() => resetCart()}>
                                    Reset order
                                </Button>
                            </Col>
                        </Row>
                    }


                    <Checkbox
                        value={isExpeditedShipping}
                        onChange={(e) => setIsExpeditedShipping(e.target.checked)}
                    >
                        Expedited Shipping
                    </Checkbox>
                </Form>
            </Spin>
        </Drawer>
    </>
        ;
};

export default NewInfluencersOrderForm;