import React, {useContext, useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import {AuthContext} from "../../../../../AuthContext";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import IndexPage from "./NestedPages/IndexPage";
import SalesGroupDetails from "./NestedPages/SalesGroupDetails";


const SalePersonDashboard = () => {
    /* Sales Portal */
    const api = useAuthAPI();
    const {authContext} = useContext(AuthContext);
    const salePersonID = authContext?.user?.user_info?.id
    const [isLoading, setIsLoading] = useState(true);
    const [clientInfo, setClientInfo] = useState(null);
    const [salesPersonData, setSalesPersonData] = useState(null);

    useEffect(() => {
        const getSalesDetails = async () => {
            try {
                const response = await api.get(`/sales-persons/${salePersonID}/`);
                response.data.first_name = response.data.first_name.toUpperCase();
                response.data.last_name = response.data.last_name.toUpperCase();
                return response.data;
            } catch (error) {
                console.log(error);
            }
        }

        const getSalesRevenue = async (salesDetails) => {
            try {
                const response = await api.get(`/sales-persons/${salePersonID}/revenue-detail/`);
                return {...salesDetails, revenue: response.data};
            } catch (error) {
                console.log(error);
            }
        }

        (async () => {
            try {
                const salesDetails = await getSalesDetails();
                const salesData = await getSalesRevenue(salesDetails);
                setSalesPersonData(salesData);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    // Create prop's object for nested components
    const props = {
        salesPersonData,
        setSalesPersonData,
        salePersonID,
        isLoading,
        clientInfo,
        setClientInfo,
    };

    return <Routes>
        <Route index element={<IndexPage {...props}/>}/>
        <Route path="details-for-salesgroup/:salesGroupId" element={<SalesGroupDetails {...props}/>}/>
        <Route path="*" element={<>404</>}/>
    </Routes>;
}

export default SalePersonDashboard;