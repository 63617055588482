import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import ContractEditModal from "./ContractEditModal";
import {Tag, Divider, List, Skeleton, Space, Spin} from 'antd';
import {CalendarOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";

const ContractsList = ({contractsList, setContractsList, userTokens}) => {
    const api = useAuthAPI();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const loadMoreData = async () => {
        if (loading) {
            return;
        }
        setLoading(true);

        await api.get(`/contracts/`)
            .then(response => {
                setContractsList(response.data.results);

                setData({
                    ...data,
                    count: response.data.count,
                    next: response.data.next
                })

                setLoading(false);
            })
            .catch(err => {
                // if (err?.response?.status === 401) {
                //     // console.log("getClientsList: 401");
                //     navigate('/session-expired');
                // } else {
                //     console.log("getClientsList Error", err);
                // }
                console.log("getClientsList Error", err);
                setLoading(false);
            });
    };

    useEffect(() => {
        (async () => {
            try {
                await loadMoreData();
            } catch (err) {
                console.error(err);
            }
        })();
        // eslint-disable-next-line
    }, []);

    return <Spin spinning={loading}>
        <InfiniteScroll
            dataLength={data.count || 0}
            next={loadMoreData}
            hasMore={data.next}
            loader={<Skeleton paragraph={{rows: 1}} active/>}
            endMessage={<Divider plain></Divider>}
            scrollableTarget="scrollableDiv"
        >
            <List
                dataSource={contractsList}
                renderItem={contract =>
                    <List.Item key={contract.id} extra={<Space>

                        <Tag>{contract?.client_details?.sales_group_details?.shortname}</Tag>


                        <Tag title={"Sales Group"}>
                            <CalendarOutlined/>&nbsp;
                            {contract?.start} - {contract?.finish}
                        </Tag>


                        {/*TODO money per employee ADD here*/}

                        <ContractEditModal
                            {...contract}
                            salesList={contractsList}
                            userTokens={userTokens}
                            setSalesList={setContractsList}
                        />

                    </Space>}>
                        <List.Item.Meta
                            title={`Contract #${contract.id} for ${contract?.client_details?.name}`}
                            description={
                                <Space>
                                    {contract?.active
                                        ? <Tag color={'green'}>Active contract <CheckOutlined/></Tag>
                                        : <Tag color={'red'}>Expired contract <CloseOutlined/></Tag>}
                                </Space>}
                        />

                    </List.Item>}
            />
        </InfiniteScroll>
    </Spin>
};

export default ContractsList;