import states from './states.json'
import React from 'react';
import {AutoComplete, Form} from 'antd';

const StatesAutoComplete = ({setFormData}) => {
    const statesArray = JSON.parse(states);
    const options = statesArray.map(state => ({
        label: state.name,
        value: state.name
    }));

    return <Form.Item
        name="state"
        label="State"
        rules={[
            {required: true, message: 'Please choose state'},
        ]}
    >
        <AutoComplete
            inputReadOnly
            aria-autocomplete={'none'}
            allowClear
            style={{width: "100%"}}
            options={options}
            placeholder="Please choose state"
            filterOption={(inputValue, option) =>
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onSelect={(value, {label}) => {
                const selectedState = statesArray.find((state) => state.name === label);

                // console.log(selectedState.postal);

                setFormData((prevState) => ({
                    ...prevState,
                    state: selectedState ? selectedState.postal : '',
                }));
            }}
        />
    </Form.Item>
}


const StatesAutoCompleteUseForm = ({form}) => {
    const statesArray = JSON.parse(states);
    const options = statesArray.map(state => ({label: state.name, value: state.postal}));

    return <Form.Item
        name="state"
        label="State"
        rules={[{required: true, message: 'Please choose state'}]}
    >
        <AutoComplete
            aria-autocomplete={'none'}
            allowClear
            style={{width: "100%"}}
            options={options}
            placeholder="Please choose state"
            filterOption={(inputValue, option) =>
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={value => {
                form.setFieldValue('state', value);
            }}
        />
    </Form.Item>
}

export {StatesAutoCompleteUseForm};

export default StatesAutoComplete;
