import React, {useState, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import getAllKits from "./services/getAllKits";
import searchKitByBarcode from "./services/searchKitByBarcode";
import PatientDetails from "./PatientDetails";
import OrderDetails from "./OrderDetails";

import {Col, Divider, Row, Breadcrumb, Typography, Button, Tag, Input, Space, Collapse, Card, message} from "antd";
import {FilePdfOutlined, HomeOutlined} from "@ant-design/icons";


const LyfeClientServiceApp = () => {
    const [lyfeKits, setLyfeKits] = useState(null);
    const [status, setStatus] = useState('Final');
    const [orderIdFilter, setOrderIdFilter] = useState(null);
    const [searchInputValue, setSearchInputValue] = useState('');

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             let kits;
    //             if (searchInputValue !== '') {
    //                 console.log("searchKitByBarcode() called!")
    //                 kits = await searchKitByBarcode(searchInputValue);
    //                 setSearchInputValue('')
    //             } else {
    //                 console.log("getAllKits() called!")
    //                 kits = await getAllKits(status, orderIdFilter);
    //             }
    //             setLyfeKits(kits);
    //         } catch (err) {
    //             console.error(err)
    //         }
    //     })();
    //
    // }, [status, orderIdFilter, searchInputValue]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                let kits;
                if (searchInputValue !== '') {
                    kits = await searchKitByBarcode(searchInputValue);
                } else {
                    kits = await getAllKits(status, orderIdFilter);
                }
                setLyfeKits(kits);
            } catch (err) {
                console.error(err)
                message.error('Error in fetching data');
            }
        };

        (async () =>
                await fetchData()
        )();

    }, [status, orderIdFilter, searchInputValue]);

    const getRightColor = (status) => {
        if (status === 'Final') return '#01a9ac';
        if (status === 'Activated') return 'green';
        return 'orange';
    }

    const isHidden = (kit) => {
        if (kit.status === 'Final') return false;
        return kit.status !== 'Activated';
    }

    const generateItemsDepend = kit => [
        {
            key: kit?.patient_id,
            label: <Button type={'text'} size={'small'}>Patient Details</Button>,
            children: <PatientDetails patientId={kit?.patient_id}/>,
            hidden: isHidden(kit),
            forceRender: true,
        },

        {
            key: `${kit?.order_id}-${new Date().getTime()}`,
            label: <Button type={'text'} size={'small'}>Order Details</Button>,
            children: <OrderDetails orderId={kit?.order_id}/>,
            forceRender: true,
        },
    ];

    return <Row justify="center">
        <Col xs={24}>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to={'/'}>
                        <HomeOutlined/>&nbsp;
                        Dashboard
                    </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    [LYFE Kits Management]
                </Breadcrumb.Item>
            </Breadcrumb>

            <Typography.Title level={1}>
                [LYFE Kits Management]
            </Typography.Title>
        </Col>

        <Col xs={24}>
            <Divider/>

            <Input.Search
                size={'small'}
                style={{margin: '0 0 10px 0'}}
                placeholder="Search by order ID or barcode..."
                onSearch={value => setSearchInputValue(value)}
                enterButton={true}
            />

            <Space>
                <Typography.Text style={{fontSize: 11}}>
                    Choose status (click to filter):
                </Typography.Text>

                <Tag.CheckableTag
                    checked={status === null}
                    bordered={false}
                    onClick={() => {
                        setSearchInputValue('');
                        setStatus(null)
                    }}
                    style={{cursor: 'pointer'}}
                >
                    All statuses
                </Tag.CheckableTag>

                <Tag.CheckableTag
                    checked={status === 'Final'}
                    bordered={false}
                    onClick={() => {
                        setSearchInputValue('');
                        setStatus('Final');
                    }}
                    style={{cursor: 'pointer'}}
                >
                    Final
                </Tag.CheckableTag>

                <Tag.CheckableTag
                    checked={status === 'Activated'}
                    bordered={false}
                    onClick={() => {
                        setSearchInputValue('');
                        setStatus('Activated')
                    }}
                    style={{cursor: 'pointer'}}
                >
                    Activated
                </Tag.CheckableTag>
            </Space>

            <Typography.Paragraph>
                <Typography.Text style={{fontSize: 11}}>Total kits in
                    database: {lyfeKits ? lyfeKits.count : 0}</Typography.Text>
            </Typography.Paragraph>

            <Row gutter={[24, 24]}>
                <Col xl={24}>

                    {lyfeKits
                        && [...lyfeKits.items]
                            .filter(kit => kit.order_id !== 23)  // test order for demo kits
                            .sort((kitA, kitB) => kitB.order_id - kitA.order_id)
                            .map((kit, index) => {
                                return <Card
                                    key={index}
                                    bordered={0}
                                    style={{background: "rgba(212,241,218,0.06)", marginBottom: 10}}
                                >

                                    <Row>
                                        <Col lg={4}>
                                            <Typography.Paragraph>
                                                <Typography.Text>Barcode:</Typography.Text>&nbsp;&nbsp;
                                                <Tag bordered={0}>{kit.barcode}</Tag>
                                            </Typography.Paragraph>

                                            <Typography.Paragraph>
                                                <Typography.Text>Status:</Typography.Text>&nbsp;&nbsp;
                                                <Tag
                                                    bordered={0}
                                                    color={getRightColor(kit.status)}
                                                >
                                                    {kit.status}
                                                </Tag>
                                            </Typography.Paragraph>

                                            {kit.status === 'Final'
                                                ? <Typography.Text>PDF-report:&nbsp;&nbsp;&nbsp;
                                                    <NavLink to={kit.pdf_link} target={'_blank'}>
                                                        <Button type={'dashed'} icon={<FilePdfOutlined/>}
                                                                size={'small'}/>
                                                    </NavLink>
                                                </Typography.Text>

                                                : null
                                            }
                                        </Col>

                                        <Col lg={16}>
                                            <Collapse items={generateItemsDepend(kit)} size={'small'} ghost={1}/>
                                        </Col>
                                    </Row>
                                </Card>
                            })}
                </Col>
            </Row>

        </Col>
    </Row>
}

export default LyfeClientServiceApp;