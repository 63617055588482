import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, {useEffect, useState, useCallback, useContext} from 'react';
import {NavLink, useNavigate} from "react-router-dom";

import {Button, Col, Input, List, Row, Space, Spin, Tag, Progress, Divider} from 'antd';
import {MedicineBoxOutlined, SolutionOutlined} from '@ant-design/icons';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import {AuthContext} from "../../../../../AuthContext";

// Use the plugins
dayjs.extend(utc);
dayjs.extend(timezone);


const ShowClientsSalesGroup = ({salesGroup}) => {
    const api = useAuthAPI();
    const [salesGroupData, setSalesGroupData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = useCallback(async () => {

        try {
            setIsLoading(true);

            const response = await api.get(`sales-groups/${salesGroup}/`);

            setSalesGroupData(response.data);

        } catch (error) {

            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }, [salesGroupData]);


    useEffect(() => {
        (async () => {
            if (salesGroupData === null) {
                await fetchData();
            }
        })();
    }, []);


    return <Spin spinning={isLoading}>
        {salesGroupData ? (

            <Tag color={'#01a9ac'}>
                {salesGroupData.shortname} (ID: {salesGroupData.id})
            </Tag>

        ) : (
            <div>No data available</div>
        )}
    </Spin>;
}


const ProviderInStockTotal = ({id}) => {
    const api = useAuthAPI();

    const [isLoading, setIsLoading] = useState(true);
    const [totalPending, setTotalPending] = useState(null);


    const getPendingKits = async () => {
        setIsLoading(true);

        try {

            const response = await api.get(`/kits/all/?status=Pending&provider=${id}`);
            setTotalPending(response.data.count);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    };

    useEffect(() => {
        if (totalPending === null) {
            (async () => {
                await getPendingKits();
            })();
        }
    }, [])


    return <Spin spinning={isLoading}>
        Kits In Stock: {totalPending}
    </Spin>

}


const ClientList = ({clientsList, setClientsList}) => {
    const api = useAuthAPI();
    const {authContext} = useContext(AuthContext);
    const {Search} = Input;
    const navigate = useNavigate();
    const [searchSTR, setSearchSTR] = useState(false);
    const [loading, setLoading] = useState(false);

    const [loadMoreObject, setLoadMoreObject] = useState({
        nextURL: null, totalCount: null, clickCounter: 0, nextSearchURL: null,
    });

    console.log(authContext?.user?.user_info?.id)
    console.log(authContext)

    const onSearch = async value => {
        /* Update state when user change searchSTR after clicking on Search btn*/
        if (searchSTR !== value) {
            setSearchSTR(value);

            setLoadMoreObject({
                ...loadMoreObject, clickCounter: 0, nextURL: null,
            });
        }
    };

    const loadMoreData = async () => {
        let apiURL = `/all-clients/sales_person_clients/?sales_person_id=${authContext?.user?.user_info?.id}`;  // custom action for sales person clients
        if (loading) {
            return;
        }

        // check a default case when search input don't touched and user clicked load more btn
        if (loadMoreObject.nextURL) {
            apiURL = loadMoreObject.nextURL;
        }

        // // check only search input and update all client list with searchSTR
        // if (searchSTR && searchSTR.length !== 0) {
        //     apiURL = `/all-clients/?search=${searchSTR}`;
        // }

        // if search used, and we have a new clicks from user to load more btn
        if (searchSTR && searchSTR.length !== 0 && loadMoreObject.nextURL) {
            apiURL = loadMoreObject.nextURL;
        }

        setLoading(true);

        // /* Check select value with query ID */
        // let filterParams;
        // if (filterValue === undefined || filterValue === "All") filterParams = '';
        // else filterParams = `?client_group=${filterValue}`;
        // /* ----- */

        await api.get(apiURL)
            .then((response) => {

                if (loadMoreObject.clickCounter === 0) {
                    setClientsList(response?.data?.results);
                } else {
                    setClientsList(prevState => [...prevState, ...response?.data?.results]);
                }

                setLoadMoreObject({
                    ...loadMoreObject, nextURL: response?.data?.next, totalCount: response?.data?.count,
                });
            })
            .catch(err => {
                if (err.response.status === 401) {
                    navigate('/session-expired');
                } else {
                    console.log("getClientsList Error", err);
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const buttonClickHandler = () => {
        setLoadMoreObject({...loadMoreObject, clickCounter: loadMoreObject.clickCounter + 1});
    }

    const loadMore = !loading ? (

        <Row style={{alignSelf: "center", justifyContent: "center", marginTop: 20}}>

            <Col xs={5}>
                <p style={{textAlign: "center"}}>
                    {clientsList?.length} client's on page from {loadMoreObject.totalCount}
                </p>
                <Progress
                    strokeColor={'#01a9ac'}
                    percent={Math.round((clientsList?.length / loadMoreObject.totalCount) * 100)}
                    status="active"
                />
            </Col>

            <Col xs={24}>
                <div style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                    display: (loadMoreObject.clickCounter === 0 && !loadMoreObject.nextURL) || (clientsList?.length === loadMoreObject.totalCount) ? 'none' : 'block',
                }}>
                    <Button onClick={buttonClickHandler} loading={loading} disabled={loading}>
                        loading more
                    </Button>
                </div>
            </Col>
        </Row>


    ) : null;

    // const handleChange = async (value) => {
    //     setFilterValue(value);
    // };

    useEffect(() => {
        if (clientsList?.length <= loadMoreObject.totalCount) {
            (async () => {
                try {
                    await loadMoreData();
                } catch (err) {
                    console.error(err);
                }
            })();
        }
        // eslint-disable-next-line
    }, [loadMoreObject.clickCounter, searchSTR]);

    return <Spin tip="Loading..." spinning={loading}>

        <Row gutter={10}>

            {/*<Col xs={24} lg={3}>*/}
            {/*    <FilterSelectByTypeOfClient*/}
            {/*        handleChange={handleChange} parentLoading={loading}*/}
            {/*    />*/}
            {/*</Col>*/}

            {/*<Col xs={24} lg={5}>*/}
            {/*    <Search*/}
            {/*        enterButton*/}
            {/*        allowClear*/}
            {/*        size={'small'}*/}
            {/*        placeholder="Search client (id, name)"*/}
            {/*        onSearch={onSearch}*/}
            {/*        style={{width: "100%",}}*/}
            {/*    />*/}

            {/*    /!*<hr/>*!/*/}
            {/*    /!*<h1>DEBUG</h1>*!/*/}
            {/*    /!*<p>*!/*/}
            {/*    /!*    <strong>Total count:</strong> {loadMoreObject.totalCount}*!/*/}
            {/*    /!*</p>*!/*/}

            {/*    /!*<p>*!/*/}
            {/*    /!*    <strong>Next URL:</strong> {loadMoreObject.nextURL}*!/*/}
            {/*    /!*</p>*!/*/}

            {/*    /!*<p>*!/*/}
            {/*    /!*    <strong>Next searchURL:</strong> {loadMoreObject.nextSearchURL}*!/*/}
            {/*    /!*</p>*!/*/}

            {/*    /!*<p>*!/*/}
            {/*    /!*    <strong>Click counter:</strong> {loadMoreObject.clickCounter}*!/*/}
            {/*    /!*</p>*!/*/}

            {/*    /!*<p>*!/*/}
            {/*    /!*    Clients on page: {clientsList.length}*!/*/}
            {/*    /!*</p>*!/*/}
            {/*    /!*<hr/>*!/*/}
            {/*</Col>*/}

            <Col xs={24} lg={5}>
                {searchSTR ? (<Tag>We find <strong>{loadMoreObject.totalCount}</strong> client's in Database</Tag>) : (
                    <Tag>We have <strong>{loadMoreObject.totalCount}</strong> client's in Database</Tag>)}
            </Col>
        </Row>

        <Divider type={'horizontal'}/>


        <List
            loadMore={loadMore}
            dataSource={clientsList}
            renderItem={client =>
                <List.Item key={client.id} extra={<Space>

                    <>
                        <strong>Last login date:</strong>
                        {dayjs(client?.user?.last_login).isValid()
                            ? <Tag>
                                {dayjs(client?.user?.last_login).tz('America/New_York').format('MM-DD-YYYY HH:mm:ss')}
                            </Tag>
                            : <Tag>Never</Tag>
                        }
                    </>

                    <Divider type={'vertical'}/>
                    <ShowClientsSalesGroup salesGroup={client?.sales_group}/>

                    <Divider type={'vertical'}/>

                    {/*{client?.is_employer*/}
                    {/*    ? <NewContractForm {...client}/>*/}
                    {/*    : <ProviderInStockTotal {...client} />*/}
                    {/*}*/}

                    {/*<Divider type={'vertical'}/>*/}
                </Space>
                }>

                    <List.Item.Meta
                        avatar={
                            client?.is_employer
                                ? <SolutionOutlined style={{fontSize: 35, marginTop: 7}}/>
                                : <MedicineBoxOutlined style={{fontSize: 35, marginTop: 7}}/>
                        }
                        // title={<NavLink
                        //     to={client?.is_employer ? `/clients/employers/${client.id}` : client?.is_provider && `/clients/providers/${client.id}`}
                        // >
                        //     {client.name}
                        // </NavLink>}

                        title={<>{client.name}</>}
                        description={<Space>
                            {client?.is_employer ? <>Employer</> : client?.is_provider && <>Provider</>}
                        </Space>}
                    />
                </List.Item>}
        />
    </Spin>;
};
export default ClientList;