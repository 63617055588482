import styled from "styled-components";
import dayjs from "dayjs";

import React, {useState, useEffect} from "react";

import {HomeOutlined, LoadingOutlined} from '@ant-design/icons';
import {Breadcrumb, Button, Col, DatePicker, Divider, Row, Segmented, Space, Spin, Typography} from "antd";
import {NavLink} from "react-router-dom";

import InventoryRouterComponent from "./InventoryRouterComponent";
import {useAuthAPI} from "../../../../../../../../features/hooks/useAuthAPI";


const MySegmentedElement = styled(Segmented)`
    margin: 0 0 20px 0;

    & .ant-segmented-item-selected {
        background: linear-gradient(to right, #01a9ac, #01dbdf);
        color: #f5f5f5;
    }

    & .ant-segmented-thumb-motion-appear {
        background: linear-gradient(to right, #01a9ac, #01dbdf);
        color: #f5f5f5;
    }
`;

const InventoryItem = styled.div`
    & span {
        display: block;
        font-size: 20px;
        font-weight: 500;
        padding: 5px 0 10px 0;
    }

    @media only screen and (max-width: 575px) {
        font-size: 12px !important;
    }
`;


const Inventory = ({authConfig, clientID, clientInfo, currentOrderType}) => {
    const api = useAuthAPI();

    const [isLoading, setIsLoading] = useState(true);

    const [month, setMonth] = useState(null);
    const [currentInventory, setCurrentInventory] = useState(null);

    const [ordersList, setOrdersList] = useState(null);
    const [finalizedKits, setFinalizedKits] = useState(null);
    const [activatedKits, setActivatedKits] = useState(null);
    const [pendingKits, setPendingKits] = useState(null);

    const [totalKitsValue, setTotalKitsValue] = useState(null);
    const [totalPending, setTotalPending] = useState(null);
    const [totalActivated, setTotalActivated] = useState(null);
    const [totalFinalized, setTotalFinalized] = useState(null);


    const onChangeMonthHandler = value => {
        const currentDate = dayjs(value);

        if (currentDate.isValid()) {
            const startDate = currentDate.startOf('month').format('YYYY-MM-DD');
            const endDate = currentDate.endOf('month').format('YYYY-MM-DD');

            setMonth({startDate, endDate});
            return;
        }
        setMonth(null);
    }

    const showTotalKitsForAllTime = () => {
        setMonth(null);
    }

    const loadAllProviderOrders = async () => {

        console.log(currentOrderType)
        console.log(clientID)

        try {

            if (!currentOrderType || !clientID) {
                console.log("Required data not available");
                return;
            }

            // Define base URLs for each order type
            const baseUrls = {
                webProvider: `/kits/all/?web_provider=${clientID}`,
                provider: `/kits/all/?provider=${clientID}`,
                // Add more URLs as needed
            };

            let requestURL = null;

            if (month !== null) {
                requestURL = baseUrls[currentOrderType];
                requestURL += `&created__gte=${month?.startDate}`;
                requestURL += `&created__lte=${month?.endDate}`;
                requestURL += `&ordering=-created`;
            }

            if (month === null) {
                requestURL = baseUrls[currentOrderType];
                requestURL += `&ordering=-created`;
            }

            const response = await api.get(`${requestURL}`, authConfig)

            setOrdersList(response?.data?.results);
            setTotalKitsValue(response.data.count);

        } catch (error) {
            console.log(error);
        }
    };


    const getFinalKits = async () => {

        try {
            if (!currentOrderType || !clientID) {
                console.log("Required data not available");
                return;
            }

            // Define base URLs for each order type
            const baseUrls = {
                webProvider: `/kits/all/?web_provider=${clientID}&status=Final`,
                provider: `/kits/all/?provider=${clientID}&status=Final`,
                // Add more URLs as needed
            };

            let requestURL = null;

            if (month !== null) {
                requestURL = baseUrls[currentOrderType];
                requestURL += `&created__gte=${month?.startDate}`;
                requestURL += `&created__lte=${month?.endDate}`;
                requestURL += `&ordering=-created`;
            }

            if (month === null) {
                requestURL = baseUrls[currentOrderType];
                requestURL += `&ordering=-created`;
            }

            const response = await api.get(`${requestURL}`, authConfig);

            setFinalizedKits(response.data.results);
            setTotalFinalized(response.data.count);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    };


    const getActiveKits = async () => {

        try {

            if (!currentOrderType || !clientID) {
                console.log("Required data not available");
                return;
            }

            // Define base URLs for each order type
            const baseUrls = {
                webProvider: `/kits/all/?web_provider=${clientID}&status=Activated`,
                provider: `/kits/all/?provider=${clientID}&status=Activated`,
                // Add more URLs as needed
            };

            let requestURL = null;

            if (month !== null) {
                requestURL = baseUrls[currentOrderType];
                requestURL += `&created__gte=${month?.startDate}`;
                requestURL += `&created__lte=${month?.endDate}`;
                requestURL += `&ordering=-created`;
            }

            if (month === null) {
                requestURL = baseUrls[currentOrderType];
                requestURL += `&ordering=-created`;
            }

            const response = await api.get(`${requestURL}`, authConfig);

            setActivatedKits(response.data.results);
            setTotalActivated(response.data.count);

        } catch (error) {
            console.log(error);
        }
    };

    const getPendingKits = async () => {

        try {
            if (!currentOrderType || !clientID) {
                console.log("Required data not available");
                return;
            }

            // Define base URLs for each order type
            const baseUrls = {
                webProvider: `/kits/all/?web_provider=${clientID}&status=Pending`,
                provider: `/kits/all/?provider=${clientID}&status=Pending`,
                // Add more URLs as needed
            };

            let requestURL = null;

            if (month !== null) {
                requestURL = baseUrls[currentOrderType];
                // requestURL = `/kits/all/?status=Pending&provider=${clientID}`;
                requestURL += `&created__gte=${month?.startDate}`;
                requestURL += `&created__lte=${month?.endDate}`;
                requestURL += `&ordering=-created`;
            }

            if (month === null) {
                requestURL = baseUrls[currentOrderType];
                // requestURL = `/kits/all/?status=Pending&provider=${clientID}`;
                requestURL += `&ordering=-created`;
            }

            const response = await api.get(`${requestURL}`, authConfig);

            // const response = await restAPI.get(`/kits/all/?status=Pending&provider=${authContext.providerData.id}`, authConfig);

            setPendingKits(response.data.results);
            setTotalPending(response.data.count);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            await Promise.all([
                    loadAllProviderOrders(),
                    getFinalKits(),
                    getActiveKits(),
                    getPendingKits()
                ]
            );
        })();

    }, [totalKitsValue, month, clientInfo, currentOrderType]);


    const inventories = [{
        label: (<InventoryItem>
            <strong>Total kits</strong>
            <span>{totalKitsValue}</span>
        </InventoryItem>), value: 'Total',
    },

        {
            label: (<InventoryItem>
                <strong>In Stock</strong>
                <span>{totalPending}</span>
            </InventoryItem>), value: 'In Stock',
        },

        {
            label: (<InventoryItem>
                <strong>Activated</strong>
                <span>{totalActivated}</span>
            </InventoryItem>), value: 'Activated',
        },

        {
            label: (<InventoryItem>
                <strong>Finalized</strong>
                <span>{totalFinalized}</span>
            </InventoryItem>), value: 'Finalized',
        }]

    const spinningHandler = isLoading || ordersList === null || finalizedKits === null || activatedKits === null || pendingKits === null

    return <Spin tip="Loading..." spinning={spinningHandler} indicator={<LoadingOutlined/>}>
        <Row>

            <Col xs={24} lg={24} xl={24}>
                <Typography.Title>
                    {clientInfo?.name} | Inventory
                </Typography.Title>


                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to={'/'}>
                            <HomeOutlined/>&nbsp;
                            Dashboard
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/clients`}>
                            Clients
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/clients/providers/${clientID}`}>
                            {clientInfo?.name}
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Inventory</Breadcrumb.Item>
                </Breadcrumb>

                <Divider/>
            </Col>

            {/*<Col xs={24} lg={24} xl={24}>*/}
            {/*    <Space style={{margin: '0 0 20px 0'}}>*/}
            {/*        <span>Filter kits by month:</span>*/}
            {/*        /!*<span style={{fontSize: 11}}>{month?.startDate} - {month?.endDate}</span>*!/*/}

            {/*        <DatePicker*/}
            {/*            defaultValue={null}*/}
            {/*            onChange={onChangeMonthHandler}*/}
            {/*            format={'YYYY-MM'}*/}
            {/*            picker="month"*/}
            {/*            inputReadOnly={true}*/}
            {/*            size={'small'}*/}
            {/*        />*/}

            {/*        <Button type={'link'} onClick={showTotalKitsForAllTime} style={{color: '#01a9ac'}}>*/}
            {/*            Show all kits*/}
            {/*        </Button>*/}
            {/*    </Space>*/}
            {/*</Col>*/}


            <Col xs={24} lg={8} xl={24}>
                <Row gutter={0} justify={'start'}>

                    <Col xs={24}>
                        <MySegmentedElement
                            onChange={value => setCurrentInventory(value)}
                            options={inventories}
                        />
                    </Col>

                    <Col xs={24}>
                        {/* This guy decides who will be shown */}
                        <InventoryRouterComponent
                            authConfig={authConfig}
                            currentInventory={currentInventory}
                            totalKits={ordersList}
                            finalizedKits={finalizedKits}
                            activatedKits={activatedKits}
                            pendingKits={pendingKits}
                        />
                    </Col>

                </Row>

            </Col>
        </Row>
    </Spin>
}

export default Inventory;