import dayjs from "dayjs";
import React, {useEffect, useState, useRef} from 'react';
import lyfeAPI from "./lyfeAPI";
import {EyeOutlined, PrinterOutlined} from "@ant-design/icons";
import {
    Button, Col, List, Row, Space, Spin, Tag, Progress, Typography, Divider, Select, Modal, Alert, message
} from 'antd';
import OrderDetailsWithAllKits from "./OrderDetailsWithAllKits";


const initialState = {
    nextURL: null, totalCount: null, clickCounter: 0, nextSearchURL: null,
}

const SingleOrderItemWithOrderedStatus = ({order, setSelectedOrder, setIsModalVisible, loading}) => {
    const assembledPercentage = order?.kit_count ? (order?.assembled_kit_count / order?.kit_count) * 100 : 0;

    return <Spin spinning={loading}>
        <List.Item
            key={order.id}
            extra={<Space>
                {Math.round(assembledPercentage) === 100 &&
                    <Alert style={{fontSize: 10}} type={'info'} message={"Ready for shipment"}/>}
                <Divider type="vertical"/>

                <Progress
                    steps={15}
                    percent={Math.round(assembledPercentage)}
                    strokeColor={'#01a9ac'}
                />
            </Space>}
        >
            <List.Item.Meta
                title={<Space>
                    <Typography.Title
                        style={{cursor: 'pointer'}}
                        level={4}
                        onClick={() => {
                            setSelectedOrder(order)
                            setIsModalVisible(prevState => !prevState)
                        }}
                    >
                        LYFE ORDER ID: {order.id} &nbsp;&nbsp;

                    </Typography.Title>
                </Space>}

                description={<Space>

                    <Button
                        type={'dashed'}
                        icon={<EyeOutlined/>}
                        size={'small'}
                        onClick={() => {
                            setSelectedOrder(order)
                            setIsModalVisible(prevState => !prevState)
                        }}>
                        open for details
                    </Button>

                    Order date:
                    {order?.created && <Tag bordered={0} color="black">
                        {dayjs(order?.created).format('MM/DD/YYYY')}
                    </Tag>}

                    <Divider type="vertical"/>
                    Status:{order?.status && <Tag bordered={0} color="black">{order?.status}</Tag>}

                    <Divider type="vertical"/>
                    Total kits: {order?.kit_count}

                    <Divider type="vertical"/>
                    Assembled kits: {order?.assembled_kit_count}
                </Space>}
            />
        </List.Item>
    </Spin>
}


const SingleOrderItemWithShippedStatus = ({order, setSelectedOrder, setIsModalVisible}) => {
    const assembledPercentage = order?.kit_count ? (order?.assembled_kit_count / order?.kit_count) * 100 : 0;

    return <List.Item
        key={order.id}
        extra={<Space>
            <Progress
                steps={15}
                percent={Math.round(assembledPercentage)}
                strokeColor={'#01a9ac'}
            />
        </Space>}
    >
        <List.Item.Meta
            title={<Space>


                <Typography.Title
                    style={{cursor: 'pointer'}}
                    level={4}
                    onClick={() => {
                        setSelectedOrder(order)
                        setIsModalVisible(prevState => !prevState)
                    }}
                >
                    LYFE ORDER ID: {order.id} &nbsp;&nbsp;

                </Typography.Title>
            </Space>}

            description={<Space>
                Order date:
                {order?.created && <Tag bordered={0} color="black">
                    {dayjs(order?.created).format('MM/DD/YYYY')}
                </Tag>}

                <Divider type="vertical"/>
                Status:{order?.status && <Tag bordered={0} color="black">{order?.status}</Tag>}

                <Divider type="vertical"/>
                Total kits: {order?.kit_count}

                <Divider type="vertical"/>
                Assembled kits: {order?.assembled_kit_count}
            </Space>}
        />
    </List.Item>
}

const OrdersList = ({ordersList, setOrdersList, printerDevice, setPrinterDevice}) => {
    // 123

    const zebraPrinterRef = useRef(null);  // Use this ref to hold the printer instance.

    const [loading, setLoading] = useState(true);  // initialize to true to show loading at the start

    /* ---- PRINTER ----- */
    const [deviceList, setDeviceList] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [chosenLabelSize, setChosenLabelSize] = useState(1);


    // Convert getLocalDevices to return a Promise
    const getLocalDevicesAsync = () => {
        return new Promise((resolve, reject) => {
            window.BrowserPrint.getLocalDevices((devices) => {
                if (devices && devices.printer) {
                    resolve(devices.printer);
                } else {
                    reject(new Error("No printers found"));
                }
            });
        });
    };

    const printTestLabel = () => {
        /* All labels sizes configured to the Lyfe purposes only */

        const barcode = 'LF12345-KCZZZZ1';

        if (!selectedDevice) {
            console.error("No device selected!");
            message?.error("No device selected! Make sure you have a printer connected.")
            return;
        }

        if (!barcode || barcode.trim() === '') {
            console.error("Invalid barcode provided.");
            message?.error("Invalid barcode provided.");
            return;
        }

        // const expDate = dayjs().add(12, 'month').format('MM/DD/YYYY');
        const expDate = dayjs().add(6, 'month').format('MM/DD/YYYY');


        let zplCommand;


        // This is a good label for 201 dpi, but shorter barcode for Florida labels
        if (chosenLabelSize === 1) {
            zplCommand = `
            ^XA
            ^FO30,40^BCN,100,Y,2,4^FD${barcode}^FS
            ^FO95,170^A0N,20,26^FDExp. Date: ${expDate}^FS
            ^XZ
        `;
        }

        // Label for 201 dpi, for my labels in New York
        if (chosenLabelSize === 2) {
            zplCommand = `
            ^XA
            ^FO20,40^BCN,150,Y,2,4^FD${barcode}^FS
            ^FO95,220^A0N,20,26^FDExp. Date: ${expDate}^FS
            ^XZ
        `;
        }

        // // Label for ZD410-300dpi ZPL
        // if (chosenLabelSize === 3) {
        //     zplCommand = `
        //     ^XA
        //     ^FO145,30^BCN,160,Y,200,200^FD${barcode}^FS
        //     ^FO190,220^A0N,20,26^FDExp. Date: ${expDate}^FS
        //     ^XZ
        // `;
        // }


        setLoading(true);

        // Create a new printer instance
        zebraPrinterRef.current = new window.Zebra.Printer(selectedDevice);

        // Send the label to the printer
        zebraPrinterRef.current.isPrinterReady().then(function () {

            for (let i = 0; i < 1; i++) {
                zebraPrinterRef.current.send(zplCommand);
            }

            console.log(`Sent label with barcode: ${barcode} to printer.`);
            setLoading(false);
        }).catch(function (error) {
            console.error("Error printing label:", error);
            message.error("Error printing label.");
            setLoading(false);
        });
    }


    const printLabel = barcode => {
        if (!selectedDevice) {
            console.error("No device selected!");
            message?.error("No device selected! Make sure you have a printer connected.")
            return;
        }

        if (!barcode || typeof barcode !== 'string' || barcode.trim() === '') {
            console.error("Invalid barcode provided.");
            message?.error("Invalid barcode provided.");
            return;
        }

        // const expDate = dayjs().add(12, 'month').format('MM/DD/YYYY');
        const expDate = dayjs().add(6, 'month').format('MM/DD/YYYY');


        let zplCommand;

        // This is a good label for 201 dpi, but shorter barcode for Florida labels
        if (chosenLabelSize === 1) {
            zplCommand = `
            ^XA
            ^FO30,40^BCN,100,Y,2,4^FD${barcode}^FS
            ^FO95,170^A0N,20,26^FDExp. Date: ${expDate}^FS
            ^XZ
        `;
        }

        // Label for 201 dpi, for my labels in New York
        if (chosenLabelSize === 2) {
            zplCommand = `
            ^XA
            ^FO20,40^BCN,150,Y,2,4^FD${barcode}^FS
            ^FO95,220^A0N,20,26^FDExp. Date: ${expDate}^FS
            ^XZ
        `;
        }

        // TODO - finish this label.
        //  I have not same printer to test it.
        // // Label for ZD410-300dpi ZPL
        // if (chosenLabelSize === 3) {
        //     zplCommand = `
        //     ^XA
        //     ^FO145,30^BCN,160,Y,200,200^FD${barcode}^FS
        //     ^FO190,220^A0N,20,26^FDExp. Date: ${expDate}^FS
        //     ^XZ
        // `;
        // }

        setLoading(true);

        // Create a new printer instance
        zebraPrinterRef.current = new window.Zebra.Printer(selectedDevice);

        // Send the label to the printer
        zebraPrinterRef.current.isPrinterReady().then(function () {

            for (let i = 0; i < 4; i++) {
                zebraPrinterRef.current.send(zplCommand);
            }

            console.log(`Sent label with barcode: ${barcode} to printer.`);
            setLoading(false);
        }).catch(function (error) {
            console.error("Error printing label:", error);
            message.error("Error printing label.");
            setLoading(false);
        });
    };


    useEffect(() => {
        (async () => {
            try {
                const printers = await getLocalDevicesAsync();
                console.log("Devices retrieved:", printers);

                // Filter printers to get those connected via USB
                const usbPrinters = printers.filter(printer => printer.connection === "usb");

                // If there are USB printers, set the first one as default
                if (usbPrinters.length > 0) {
                    setDeviceList([usbPrinters[0]]);
                    setSelectedDevice(usbPrinters[0]);
                } else {
                    console.warn("No USB printers found.");
                    message.error("No USB printers found.")
                    return
                }

                // message.success('Printer connected successfully!');

            } catch (error) {
                console.error(error);
                message.error(error.message)
            } finally {
                setLoading(false);  // set loading to false even if there's an error
            }

        })()
    }, []);
    /* ---- PRINTER ----- */


    /*  -----  OTHER COMP STUFF  -----  */
    const [searchSTR, setSearchSTR] = useState(false);

    /* State for the Modal window with Kit lists */
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);


    /*  Pagination state */
    const [responseData, setResponseData] = useState(null);

    // eslint-disable-next-line
    const [setMonth] = useState(null);

    // select state
    const [selectedStatus, setSelectedStatus] = useState("Ordered");


    const [loadMoreObject, setLoadMoreObject] = useState(initialState);


    const onSelectedStatusChange = value => {
        setSelectedStatus(value);
        setLoadMoreObject(initialState);
    }


    const showTotalKitsForAllTime = () => {
        setMonth(null);
    }

    const loadMoreData = async () => {
        try {
            setLoading(true);

            let requestURL = '';  // result API request URL

            if (!!!loadMoreObject.nextURL) {
                requestURL = `/orders/list/?status=${selectedStatus}`;
            } else {
                requestURL = `${loadMoreObject.nextURL}`;
            }

            const response = await lyfeAPI.get(`${requestURL}`);

            if (loadMoreObject.clickCounter === 0) {
                setOrdersList(response?.data?.orders);
                setResponseData(response?.data);
            } else {
                setOrdersList(prevState => [...prevState, ...response?.data?.orders]);
                setResponseData(response?.data);
            }

            setLoadMoreObject({
                ...loadMoreObject, nextURL: response?.data?.links?.next, totalCount: response?.data?.totalOrders,
            });

        } catch (error) {
            console.log(error);
        } finally {
            // TODO - to much loaaaaading
            // setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {

            try {
                await loadMoreData();
            } finally {
                // needed for next loadings. todo - fix this in future and take full control and expected result
                setLoading(false);
            }


        })();
        // eslint-disable-next-line
    }, [selectedStatus]);


    // Listen for Load More button click
    useEffect(() => {
        if (ordersList?.length <= loadMoreObject.totalCount) {
            (async () => {
                try {
                    await loadMoreData();
                } catch (err) {
                    console.error(err);
                }
            })();
        }
        // eslint-disable-next-line
    }, [loadMoreObject.clickCounter]);


    const buttonClickHandler = () => {
        setLoadMoreObject({...loadMoreObject, clickCounter: loadMoreObject?.clickCounter + 1});
    }


    // if (loading) {
    //     return <div>Loading...</div>;  // display loading message until data is loaded
    // }

    const loadMore = !loading ? (

        <Row style={{alignSelf: "center", justifyContent: "center", marginTop: 20}}>

            <Col xs={5}>
                <p style={{textAlign: "center"}}>
                    {ordersList?.length === 0 ? <>Nothing to show</> : <>
                        {ordersList?.length} order's on page from {loadMoreObject?.totalCount}

                        <Progress
                            strokeColor={'#01a9ac'}
                            percent={Math.round((ordersList?.length / loadMoreObject.totalCount) * 100)}
                            status="active"
                        />
                    </>}
                </p>
            </Col>

            <Col xs={24}>
                <div style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                    display: (loadMoreObject?.clickCounter === 0 && !loadMoreObject?.nextURL) || (ordersList?.length === loadMoreObject?.totalCount) ? 'none' : 'block',
                }}>
                    <Button onClick={buttonClickHandler} loading={loading} disabled={loading}>
                        loading more
                    </Button>
                </div>
            </Col>
        </Row>) : null;

    const renderFields = (selectedOrder, expectedKeys) => {

        const orderStructure = {
            "id": "LYFE ORDER ID",
            "status": "Status",
            "firstname": "First name",
            "lastname": "Last name",
            "email": "Email",
            "phone": "Phone",
            "address_1": "Address 1",
            "address_2": "Address 2",
            "city": "City",
            "state": "State",
            "postcode": "Postcode",
            "isexpeditedshipping": "Expedited shipping",
            "isfinished": "Is finished",
            "created": "Created",
            "updated": "Updated",
            "trackingnumber": "Tracking number",
            "trackingreturn": "Tracking return",
            "orderType": "Order type",
            "kit_count": "Total kits",
            "assembled_kit_count": "Assembled kits",
            "uniqueorderid": "Walmart Order ID",
        }

        return expectedKeys.map(expectedKey => {
            if (selectedOrder?.hasOwnProperty(expectedKey)) {
                // Special formatting for 'Created' key
                if (expectedKey === 'created' || expectedKey === 'updated') {
                    const formattedDate = dayjs(selectedOrder[expectedKey]).format('MM-DD-YYYY');

                    return (
                        <div key={expectedKey}>
                            <strong>{orderStructure[expectedKey]}:</strong> {formattedDate}
                        </div>
                    );
                } else {
                    // Default rendering for other keys
                    return (
                        <div key={expectedKey}>
                            <strong>{orderStructure[expectedKey]}:</strong> {selectedOrder[expectedKey]}
                        </div>
                    );
                }
            } else {
                return null; // Or you could return a placeholder component
            }
        });
    }

    const expectedKeys = [
        "id",
        "uniqueorderid",
        "status",
        "firstname",
        "lastname",
        "email",
        "phone",
        "address_1",
        "address_2",
        "city",
        "state",
        "postcode",
        "isexpeditedshipping",
        "isfinished",
        "created",
        "updated",
        "trackingnumber",
        "trackingreturn",
        "orderType",
        "kit_count",
        "assembled_kit_count",
    ];

    const renderedFields = renderFields(selectedOrder, expectedKeys);


    return <Spin spinning={loading}>

        <Row gutter={10}>

            <Col xs={24} lg={24} xl={24}>
                <Space style={{margin: '0 0 20px 0'}}>

                    {searchSTR ? (<Tag>We find <strong>{loadMoreObject?.totalCount}</strong> order's in
                        Database</Tag>) : (loadMoreObject?.totalCount === null || loadMoreObject?.totalCount === 0)
                        ? <>
                            Nothing to show</>
                        : (
                            <Tag>We have <strong>{loadMoreObject?.totalCount}</strong> order's in Database</Tag>)}

                    <span>Filter orders by status:</span>

                    <Select
                        onChange={onSelectedStatusChange}
                        defaultValue={selectedStatus}
                        options={[{
                            value: 'Ordered', label: 'Ordered',
                        }, {
                            value: 'Shipped', label: 'Shipped',
                        },]}
                    />

                    <Spin spinning={loading}>
                        {selectedDevice ? <Space>
                                <Divider type="vertical"/>
                                <Typography.Text type="secondary">
                                    <strong>USB Printer: </strong>
                                </Typography.Text>

                                <Select
                                    size={'small'}
                                    disabled={!!!selectedDevice}
                                    onChange={value => {
                                        // console.log(value)
                                        // console.log(deviceList)
                                        const selected = deviceList.find(device => device.uid === value);
                                        // console.log(selected)
                                        setSelectedDevice(selected);
                                    }}
                                    value={selectedDevice ? selectedDevice.uid : undefined}
                                >
                                    {deviceList.map(device => (<Select.Option key={device.uid} value={device.uid}>
                                        {device.name}
                                    </Select.Option>))}
                                </Select>

                                <Divider type="vertical"/>
                                <Typography.Text type="secondary">
                                    <strong>Label size: </strong>
                                </Typography.Text>

                                <Select
                                    disabled={!!!selectedDevice}
                                    onChange={value => setChosenLabelSize(value)}
                                    value={chosenLabelSize}
                                    size={'small'}
                                >
                                    <Select.Option key={1} value={1}>
                                        201 dpi (FL)
                                    </Select.Option>

                                    <Select.Option key={2} value={2}>
                                        201 dpi (NY)
                                    </Select.Option>

                                    {/*<Select.Option key={3} value={3}>*/}
                                    {/*    301 dpi (NY)*/}
                                    {/*</Select.Option>*/}
                                </Select>


                                <Button size={'small'} type={'primary'} onClick={printTestLabel} icon={<PrinterOutlined/>}>
                                    Print test label
                                </Button>

                            </Space>

                            : <>
                                <Divider type="vertical"/>
                                <Typography.Text type="secondary">
                                    <strong>USB Printer: <Tag color={'red'}>not connected</Tag> </strong>
                                </Typography.Text>
                            </>}

                    </Spin>

                </Space>
            </Col>

        </Row>

        <List
            loadMore={loadMore}
            dataSource={ordersList}
            renderItem={order => order?.status === 'Ordered'

                ? <SingleOrderItemWithOrderedStatus
                    loading={loading}
                    order={order}
                    setSelectedOrder={setSelectedOrder}
                    setIsModalVisible={setIsModalVisible}
                />

                : <SingleOrderItemWithShippedStatus
                    loading={loading}
                    order={order}
                    setSelectedOrder={setSelectedOrder}
                    setIsModalVisible={setIsModalVisible}
                />}

        />


        <Modal
            title={<Space>Order details</Space>}
            width={"50%"}
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            destroyOnClose={true}
            footer={null}  // This removes the default buttons
            maskClosable={false}  // Prevent closing the modal by clicking outside of it
            // closable={false}
            // keyboard={false}  // Prevent closing the modal with the ESC key
        >

            {selectedOrder?.status === 'Ordered'

                ? <OrderDetailsWithAllKits
                    loading={loading}
                    printLabel={printLabel}
                    setOrdersList={setOrdersList}
                    setSelectedOrder={setSelectedOrder}
                    selectedOrder={selectedOrder}
                    printerDevice={printerDevice}
                    setPrinterDevice={setPrinterDevice}
                    setIsModalVisible={setIsModalVisible}
                />

                :

                <>
                    {renderedFields}
                </>

                // <>
                //     {Object.entries(selectedOrder).map(([key, value]) => (
                //         <div key={key}>
                //             <strong>"{key}",</strong>
                //         </div>
                //     ))}
                // </>

            }


        </Modal>
    </Spin>;
};
export default OrdersList;