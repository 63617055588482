import React, {useState, useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom";
import useLocalStorage from "../../features/hooks/useLocalStorage";
import {AuthContext} from '../AuthContext';

import restAPI from "../../features/restAPI";
import SalesPersonUserMenu from "../Efunctional/AppUsers/Sales/Menu";

const getAccessToken = () => {
    const accessToken = localStorage.getItem('accessToken');
    return accessToken || null;
}

const getRefreshToken = () => {
    const refreshToken = localStorage.getItem('refreshToken');
    return refreshToken || null;
}


const RoleBasedAuth = ({allowedRoles, children}) => {
    const {authContext} = useContext(AuthContext);
    const [appUserRole, setAppUserRole] = useState(null);

    const getAppUserRole = () => {

        if (!authContext?.user?.is_active) {
            throw new Error('User is not active');
        }

        if (authContext?.user?.is_sales) {
            setAppUserRole('sales');
            return 'sales';
        }

        if (authContext?.user?.is_superuser) {
            setAppUserRole('superuser');
            return 'superuser';
        }

        throw new Error('Unknown user role');
    };

    useEffect(() => {
        getAppUserRole();
    }, [authContext]);

    // console.log("Exec inside the `RoleBasedAuth` component", authContext?.user);

    if (allowedRoles.includes(appUserRole)) {
        return <>{children}</>;
    }

    // Redirect or show a 'not authorized' message
    return <div>Not Authorized</div>;
};

export default RoleBasedAuth;


const AuthRequired = ({children}) => {
    /* Custom Auth controller component  */

    const navigate = useNavigate();
    const {authContext, accessToken} = useContext(AuthContext);

    useEffect(() => {
        if (!authContext || !accessToken) {
            navigate('/login');
        }
    }, [authContext]);

    return children;
}


const SuperUsersOnly = ({children}) => {
    /* SuperUsersOnly controller component  */

    const navigate = useNavigate();
    const {authContext, accessToken} = useContext(AuthContext);

    useEffect(() => {
        if (!authContext?.user?.is_superuser) {
            navigate('/403');  // todo - maybe we need show more detailed message.
        }
    }, [authContext]);

    return children;
}

const AuthProvider = ({children}) => {

    const [isAuth, setIsAuth] = useLocalStorage('userData', null);
    const [accessToken, setAccessToken] = useLocalStorage('accessToken', getAccessToken());
    const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', getRefreshToken());

    const [authContext, setAuthContext] = useState(isAuth);

    const login = async authUserData => {
        // Save access and refresh tokens in local storage
        setAccessToken(authUserData.access);
        setRefreshToken(authUserData.refresh);
        setIsAuth({...authUserData});  // save to localstorage
        setAuthContext({...authUserData}); // save tokens and parse user_id
    }

    const authConfig = () => {
        if (accessToken) {
            return {
                headers: {
                    Authorization: `ECL ${accessToken}`,
                },
            };
        }
        return {};
    };
    const logout = () => {
        setAuthContext(null);
        setIsAuth(null);

        // Clear all local storage keys
        setAccessToken(null);
        setRefreshToken(null);
    }
    const refresh = async () => {
        try {
            const response = await restAPI.post('/token/refresh/', {refresh: refreshToken});
            // Update the access token
            setAccessToken(response.data.access);
            setIsAuth({...authContext, access: response.data.access});
            setAuthContext({...authContext, access: response.data.access});

            return response.data.access; // Return the new access token
        } catch (error) {
            // Refresh token has expired or some error occurred, logout
            logout();
            throw error;
        }
    }

    return <AuthContext.Provider value={{authContext, authConfig, login, logout, refresh, accessToken, refreshToken}}>
        {children}
    </AuthContext.Provider>
}


export {AuthRequired, SuperUsersOnly, AuthProvider, RoleBasedAuth}
