import {phoneNumberMask} from '../../../../../../features/validation';
import React, {useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Col, Drawer, Form, Input, InputNumber, message, Row, Space} from 'antd';
import {useNavigate} from "react-router-dom";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";


const initialState = {
    last_name: null,
    first_name: null,
    email: null,
    active: true,
    phone: '',
    about: '',
    commission: null,
}

const SalePersonsList = ({setSalesList}) => {
    const {TextArea} = Input;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(initialState);
    // const authConfig = {headers: {Authorization: `ECL ${userTokens.access}`}};

    const api = useAuthAPI();

    const submitForm = async () => {
        console.log(formData);
        console.log();
        await api.post(`/sales-persons/`, formData)
            .then(response => {
                setSalesList(prevState => [{...response.data}, ...prevState].map(s => s));
            });
    };

    const key = 'newClient';

    const triggerSubmitHandler = () => form.submit();

    const sendFormWithMessage = () => {
        setOpen(false);
        message?.loading({
            content: 'Loading...',
            key,
        });
        setTimeout(() => {
            submitForm()
                .then(() => {
                    setFormData(initialState);
                    form.resetFields();
                    message?.success({
                        content: 'Sales person created!',
                        key,
                        duration: 2,
                    });
                })
                .catch((err) => {
                    console.log(err);

                    if (err.response.status === 401) {
                        navigate('/session-expired');
                    } else {
                        console.log("getClientsList Error", err);
                    }

                    message?.error({
                        content: 'We have an errors!',
                        key,
                        duration: 2,
                    });

                    setOpen(true);
                })
        }, 1000);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return <>
        <Space>
            <Button size={'small'} type="primary" onClick={showDrawer} icon={<PlusOutlined/>}>
                New sales person
            </Button>
        </Space>

        <Drawer
            title="Create a new sales person"
            width={window.innerWidth > 1200 ? 780 : '100%'}
            onClose={onClose}
            open={open}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={triggerSubmitHandler} type="primary">
                        Submit
                    </Button>
                </Space>
            }
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={sendFormWithMessage}
                autoComplete="off"
            >

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="last_name"
                            label="Last Name"
                            rules={[{required: true, message: 'Please enter last name'}]}
                        >
                            <Input
                                onChange={({target: {value}}) => setFormData({...formData, last_name: value})}
                                placeholder="Please enter last name"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="first_name"
                            label="First Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter first name',
                                },
                            ]}
                        >
                            <Input
                                style={{
                                    width: '100%',
                                }}
                                onChange={({target: {value}}) => setFormData({...formData, first_name: value})}
                                placeholder="Please enter first name"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{
                                required: true,
                                message: 'Please enter your email',

                            }, {
                                // eslint-disable-next-line
                                pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                message: 'Please enter valid email',
                            }]}
                        >
                            <Input
                                onChange={({target: {value}}) => setFormData({...formData, email: value})}
                                placeholder="Please enter your email"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="phone"
                            label="Phone number"
                            rules={[{required: true, message: 'Please enter phone number'},
                                {pattern: /\((\d{3})\)\s(\d{3})-(\d{4})/, message: 'Please enter valid phone number',}]}
                        >
                            <Input
                                onChange={({target: {value}}) => {
                                    const prettyPhoneNumber = phoneNumberMask(value);

                                    setFormData({...formData, phone: prettyPhoneNumber});
                                    form.setFieldValue('phone', prettyPhoneNumber);
                                }}
                                placeholder="Please enter phone number"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="commission"
                            label="Commission, %"
                            rules={[{
                                type: 'number',
                                required: true,
                                message: 'Please specify commission percent',
                                min: 1,
                                max: 100,
                            }]}
                        >
                            <InputNumber
                                style={{width: '100%',}}
                                min={1} max={100}
                                onChange={value => setFormData({
                                    ...formData,
                                    commission: value
                                })}
                                placeholder="Please specify percent"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="about" label="Some notes about this sales">
                            <TextArea
                                value={formData.about}
                                onChange={({target: {value}}) => setFormData({...formData, about: value})}
                                placeholder="Please add notes"
                            />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Drawer>
    </>;
};

export default SalePersonsList;