import React, {useEffect, useRef, useState, useContext} from "react";
import {useParams} from "react-router";
import {useAuthAPI} from "../../../../../../../../features/hooks/useAuthAPI";
import {NavLink} from "react-router-dom";
import {AuthContext} from "../../../../../../../AuthContext";


import {HomeOutlined, PrinterOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Col, Divider, message, Row, Space, Spin, Table, Typography, Switch, Form} from "antd";
import styled from "styled-components";
import {useReactToPrint} from "react-to-print";

const CustomSpace = styled(Space)`
    display: flex;
    align-items: baseline;
`;

const PrintingDiv = styled.div`
    @media print {
        & {
            padding: 80px;
        }
    }
`;

const PrintableComponent = React.forwardRef((props, ref) => {
    const {
        printHeaderVisible,
        data,
        title,
        showOnlyCommissioned,
        salesPersonFullName
    } = props;

    const sortedAndFilteredData = data && data
        .sort((a, b) => b.salesperson_commission - a.salesperson_commission)
        .filter(client => !showOnlyCommissioned || client.salesperson_commission !== 0);


    const columns = [
        {
            width: '70%',
            title: 'Client Name',
            dataIndex: 'client_name',
            key: 'client_name',
            render: (text, record) => <Typography.Title level={5}>{text} (ID: {record.client_id})</Typography.Title>,
        },
        {
            width: '10%',
            title: 'Commission',
            dataIndex: 'salesperson_commission',
            key: 'salesperson_commission',
            render: text => `$${text}`,
        },
        {
            width: '10%',
            title: 'Kits Sold',
            dataIndex: 'total_kits',
            key: 'total_kits',
        },
        {
            width: '10%',
            title: 'Popular Kit',
            dataIndex: 'most_popular_test_kit',
            key: 'most_popular_test_kit',
            render: kit => <strong>{kit ? kit.test : "TBD"}</strong>,
        }
    ];

    return <PrintingDiv ref={ref}>
        {printHeaderVisible && <Typography.Title level={2}>{title}</Typography.Title>}

        <Typography.Paragraph>
            This report shows the detailed information about the clients of this salesgroup, and show a total
            commission for {salesPersonFullName}
        </Typography.Paragraph>


        <Table
            dataSource={sortedAndFilteredData}
            columns={columns}
            rowKey={record => record.client_id}
            pagination={false}
        />

    </PrintingDiv>;
});

const SalePersonDetailPage = ({salesPersonData}) => {
    const api = useAuthAPI();
    const {authContext} = useContext(AuthContext);
    const componentRef = useRef();
    const {salesGroupId} = useParams();

    const salePersonID = authContext?.user?.user_info?.id

    const [isLoading, setIsLoading] = useState(true);
    const [responseData, setResponseData] = useState(null);
    const [showOnlyCommissioned, setShowOnlyCommissioned] = useState(true);
    const [printHeaderVisible, setPrintHeaderVisible] = useState(false);

    // if (!salesPersonData) navigate(`${pages.sales.url}/${salePersonID}`);
    // TODO check if sales group exists


    useEffect(() => {
        const getCommissionDetailsBySalesGroupID = async () => {
            try {
                setIsLoading(true);

                if (salePersonID === undefined || salesGroupId === undefined) {
                    throw new Error('We have an error with salesperson ID or salesgroup ID');
                }

                const response = await api.get(`/sales-persons/calculate-revenue-for-sales-group/?salesperson_id=${salePersonID}&sales_group_id=${salesGroupId}`);
                setResponseData(response.data);

            } catch (error) {
                // TODO check and display errors
                console.log(error);
                message.error(error.message);
            } finally {
                setIsLoading(false);
            }
        }

        (async () => {
            await getCommissionDetailsBySalesGroupID();
        })();

    }, []);


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                setPrintHeaderVisible(true);
                setTimeout(resolve, 0);
            });
        },
        onAfterPrint: () => setPrintHeaderVisible(false),
    });

    if (!salesPersonData) return null;

    return <Spin spinning={isLoading}>
        <Row>
            <Col span={24}>

                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to={'/'}>
                            <HomeOutlined/>&nbsp;
                            Dashboard
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        Details
                        of {salesPersonData?.revenue?.groups.find(g => g.group_id === parseInt(salesGroupId))?.group_name}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Typography.Title>
                    Details
                    of {salesPersonData?.revenue?.groups.find(g => g.group_id === parseInt(salesGroupId))?.group_name}
                </Typography.Title>

                <Divider/>

                <Row justify="space-between">

                    <Col xs={24}>
                        <CustomSpace>
                            Details
                            of {salesPersonData?.revenue?.groups.find(g => g.group_id === parseInt(salesGroupId))?.group_name}

                            <Button
                                icon={<PrinterOutlined/>}
                                onClick={handlePrint}
                                size={'small'}>
                                Print Report
                            </Button>

                            <div className="form-item-wrapper">
                                <Form.Item label="Show only commissioned clients" name={'showOnlyCommissioned'}>
                                    <Switch size={'small'} defaultChecked={showOnlyCommissioned} onChange={setShowOnlyCommissioned}/>
                                </Form.Item>
                            </div>

                        </CustomSpace>

                        <PrintableComponent
                            title={`Details
                                of Sales Group: ${salesPersonData?.revenue?.groups.find(g => g.group_id === parseInt(salesGroupId))?.group_name}`}
                            ref={componentRef}
                            printHeaderVisible={printHeaderVisible}
                            data={responseData && responseData.clients}
                            showOnlyCommissioned={showOnlyCommissioned}
                            salesPersonFullName={`${salesPersonData?.first_name} ${salesPersonData?.last_name}`}
                        />
                    </Col>

                </Row>


            </Col>
        </Row>
    </Spin>;
}

export default SalePersonDetailPage;