import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";
import {Col, Row, Breadcrumb, Typography} from "antd";
import ClientList from "./ClientList";


const ClientPage = () => {
    const [clientList, setClientList] = useState([]);

    return <Row justify="center">
        <Col xs={24}>
            <Breadcrumb>
                <Breadcrumb.Item><NavLink to={'/'}><HomeOutlined/>&nbsp;Dashboard</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item>Clients list</Breadcrumb.Item>
            </Breadcrumb>

            <Typography.Title level={1}>
                Clients list
            </Typography.Title>
        </Col>

        <Col xs={24}>
            <ClientList clientsList={clientList} setClientsList={setClientList}/>
        </Col>
    </Row>;
}

export default ClientPage;