import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, {useEffect, useState} from 'react';
import {Button, Col, List, Row, Space, Spin, Tag, Progress, Typography, Divider, DatePicker, Input} from 'antd';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import {NavLink} from "react-router-dom";


// Use the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const PatientsList = ({patientsList, setPatientsList}) => {
    const api = useAuthAPI();
    const {Search} = Input;

    const [searchSTR, setSearchSTR] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSearch = async value => {
        /* Update state when user change searchSTR after clicking on Search btn*/
        if (searchSTR !== value) {
            setSearchSTR(value);

            setLoadMoreObject({
                ...loadMoreObject, clickCounter: 0, nextURL: null,
            });
        }
    };

    const loadMoreData = async () => {
        setLoading(true);

        try {
            // (1) Stop if loading
            if (loading) return;

            let requestURL = `/patients/all/?ordering=-user__last_login`; // result API request URL

            // (2) Check a default case when search input doesn't touched and the user clicked the load more btn
            if (loadMoreObject.nextURL) {
                requestURL = loadMoreObject.nextURL;
            }

            // (3) Check only search input and update all the client list with searchSTR
            if (searchSTR && searchSTR.length !== 0) {
                requestURL = `/patients/all/?search=${searchSTR}&ordering=-user__last_login`;
            }

            // if search used, and we have a new clicks from user to load more btn
            if (searchSTR && searchSTR.length !== 0 && loadMoreObject.nextURL) {
                requestURL = loadMoreObject.nextURL;
            }

            // const response = await api.get(`${requestURL}`);
            const response = await api.get(requestURL)

            // Update state with new data
            if (loadMoreObject.clickCounter === 0) {
                setPatientsList(response?.data?.results);
            } else {
                setPatientsList(prevState => [...prevState, ...response?.data?.results]);
            }

            setLoadMoreObject({
                ...loadMoreObject, nextURL: response?.data?.next, totalCount: response?.data?.count,
            });

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    const [loadMoreObject, setLoadMoreObject] = useState({
        nextURL: null, totalCount: null, clickCounter: 0, nextSearchURL: null,
    });


    // Listen for Load More button click
    useEffect(() => {
        if (patientsList?.length <= loadMoreObject.totalCount) {
            (async () => {
                try {
                    await loadMoreData();
                } catch (err) {
                    console.error(err);
                }
            })();
        }
        // eslint-disable-next-line
    }, [loadMoreObject.clickCounter, searchSTR]);


    const buttonClickHandler = () => {
        setLoadMoreObject({...loadMoreObject, clickCounter: loadMoreObject.clickCounter + 1});
    }

    const loadMore = !loading ? (

        <Row style={{alignSelf: "center", justifyContent: "center", marginTop: 20}}>

            <Col xs={5}>
                <p style={{textAlign: "center"}}>
                    {patientsList?.length === 0
                        ? <>Nothing to show</>
                        : <>
                            {patientsList?.length} patients on page from {loadMoreObject.totalCount}

                            <Progress
                                strokeColor={'#01a9ac'}
                                percent={Math.round((patientsList?.length / loadMoreObject.totalCount) * 100)}
                                status="active"
                            />
                        </>
                    }
                </p>
            </Col>

            <Col xs={24}>
                <div style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                    display: (loadMoreObject.clickCounter === 0 && !loadMoreObject.nextURL) || (patientsList?.length === loadMoreObject.totalCount)
                        ? 'none'
                        : 'block',
                }}>
                    <Button onClick={buttonClickHandler} loading={loading} disabled={loading}>
                        loading more
                    </Button>
                </div>
            </Col>
        </Row>
    ) : null;


    return <Spin spinning={loading}>

        <Row gutter={10}>
            <Col xs={24} lg={24} xl={24}>
                <Space style={{margin: '0 0 20px 0'}}>

                    <Search
                        enterButton
                        allowClear
                        size={'small'}
                        placeholder="Search patient (first name, last name, email)"
                        onSearch={onSearch}
                        style={{width: "400px",}}
                    />

                    {
                        searchSTR
                            ? (<Tag>We find <strong>{loadMoreObject.totalCount}</strong> patient's in Database</Tag>)
                            : (loadMoreObject.totalCount === null || loadMoreObject.totalCount === 0)
                                ? <>Nothing to show</>
                                : (<Tag>We have <strong>{loadMoreObject.totalCount}</strong> patient's in Database</Tag>)
                    }
                </Space>

                {/*<hr/>*/}
                {/*<h1>DEBUG</h1>*/}
                {/*<p>*/}
                {/*    <strong>Total count:</strong> {loadMoreObject.totalCount}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    <strong>Next URL:</strong> {loadMoreObject.nextURL}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    <strong>Next searchURL:</strong> {loadMoreObject.nextSearchURL}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    <strong>Click counter:</strong> {loadMoreObject.clickCounter}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    Clients on page: {patientsList.length}*/}
                {/*</p>*/}
                {/*<hr/>*/}

            </Col>
        </Row>

        <List
            loadMore={loadMore}
            dataSource={patientsList}
            renderItem={patient =>
                <List.Item key={patient.id} extra={
                    <Space>
                        <>
                            <strong>Last login date:</strong>
                            {dayjs(patient?.user?.last_login).isValid()
                                ? <Tag>
                                    {dayjs(patient?.user?.last_login).tz('America/New_York').format('MM-DD-YYYY HH:mm:ss')}
                                </Tag>
                                : <Tag>Never</Tag>
                            }
                        </>
                    </Space>
                }>
                    <List.Item.Meta
                        title={
                            <NavLink to={`/patients/${patient.id}`}>
                                <Typography.Title level={5}>
                                    {patient?.first_name}&nbsp;{patient?.middle_name || ""} &nbsp;{patient?.last_name}

                                    <Divider type="vertical"/>
                                    <Tag>{patient?.email}</Tag>
                                </Typography.Title>
                            </NavLink>
                        }
                    />
                </List.Item>}
        />
    </Spin>;
};
export default PatientsList;