import React from 'react';
import {NavLink} from "react-router-dom";
import {Space, Button, List} from 'antd';
import {PhoneOutlined, MailOutlined, UserAddOutlined} from '@ant-design/icons';

const SaleGroupClientsList = ({saleGroupObject}) => {
    return <List
        dataSource={saleGroupObject?.salesPersonsDetails}
        renderItem={salePerson =>
            <List.Item key={salePerson.id} extra={<Space>

                {salePerson.phone &&
                    <Button type={'text'} size={'small'} href={`tel:${salePerson.phone}`}>
                        <PhoneOutlined/>
                    </Button>}

                {salePerson.email &&
                    <Button type={'text'} size={'small'} href={`mailto:${salePerson.email}`}>
                        <MailOutlined/>
                    </Button>}

            </Space>}>

                <List.Item.Meta
                    avatar={<UserAddOutlined style={{fontSize: 35, marginTop: 7}}/>}
                    title={<NavLink to={`/sales/${salePerson.id}`}>
                        {salePerson?.first_name}&nbsp;{salePerson?.last_name}
                    </NavLink>}
                    description={`${salePerson?.commission} %`}
                />
            </List.Item>}
    />;
};

export default SaleGroupClientsList;