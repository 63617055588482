/*
* This file it's main router config. All urls and components for this urls, texts for title and others in the future.
* Use this file firstly for creating new page.
* */
import React from "react";

import {AuthRequired, SuperUsersOnly} from "../../AuthProvider";

// visible pages
import NotFoundScreen from "../NotFoundScreen";
import LogOut from "../../Auth/LogOut";
import LoginForm from "../../Auth/LoginForm";
import RegisterForm from "../../Auth/RegisterForm";
import ForgotPasswordForm from "../../Auth/ForgotPasswordForm";
import YourSessionExpired from "../../Auth/YourSessionExpired";

// protected pages for superusers
import HomeScreen from "../HomeScreen";
import ClientManagement from "../AppUsers/SuperUser/pages/ClientManagement";
import ContractsManagement from "../AppUsers/SuperUser/pages/ContractsManagement";
import SalesManagement from "../AppUsers/SuperUser/pages/SalesManagement";
import SalesGroupManagement from "../AppUsers/SuperUser/pages/SalesGroupManagement";
import SaleGroupDetails from "../AppUsers/SuperUser/pages/SaleGroupDetails";
import EmployerDetails from "../AppUsers/SuperUser/pages/EmployerDetails";
import ProviderDetails from "../AppUsers/SuperUser/pages/ProviderDetails";
import OrdersManagement from "../AppUsers/SuperUser/pages/OrdersManagement";
import PatientsManagement from "../AppUsers/SuperUser/pages/PatientsManagement";
import PatientDetails from "../AppUsers/SuperUser/pages/PatientDetails";
import SuppliesOrder from "../AppUsers/SuperUser/pages/SuppliesOrder";
import SuppliesOrderListEfunctional from "../AppUsers/SuperUser/pages/SuppliesOrderListEfunctional";
import KitsManagement from "../AppUsers/SuperUser/pages/KitsManagement";
import AllBilledKitsManagement from "../AppUsers/SuperUser/pages/AllBilledKitsManagement";
import SalePersonDetailPage from "../AppUsers/SuperUser/pages/SalePersonDetailPage";

import SalesPortalDashboard from "../AppUsers/Sales/pages/Dashboard";

// Lyfe Apps
import SuppliesOrderLyfe from "../AppUsers/SuperUser/pages/SuppliesOrderLyfe";
import LyfeClientServiceApp from "../AppUsers/SuperUser/pages/LyfeClientServiceApp";


// protected pages for sales pers
import ClientPage from "../AppUsers/Sales/pages/ClientsPage";


const pages = [
    {
        path: '/',
        title: 'Dashboard',
        component: HomeScreen,  // [INFO] It's a main entry point for all auth users. Please check it!
        auth: false,
        roles: ['sales', 'superuser',],
    },
    {
        path: '/login',
        title: 'Login page',
        component: LoginForm,
        auth: false,
        roles: null,
    },
    {
        path: '/logout',
        title: 'Logout page',
        component: LogOut,
        auth: false,
        roles: null,
    },
    {
        path: '*',
        title: 'Not Found Screen',
        component: NotFoundScreen,
        auth: false,
        roles: null,
    },
    {
        path: '/session-expired',
        title: 'Your session expired',
        component: YourSessionExpired,
        auth: false,
        roles: null,
    },
];

const salesPortalPages = [
    {
        path: '/sales-portal/*',
        title: 'Dashboard',
        component: SalesPortalDashboard,
        auth: true,
        roles: ['sales',],
    },
    {
        path: '/clients-list',
        title: 'Clients list',
        component: ClientPage,
        auth: true,
        roles: ['sales',],
    },
]

const superusersPortalPages = [

    {
        path: '/kits',
        title: 'Kits',
        component: KitsManagement,
        auth: true,
        roles: ['superuser',],
    },

    {
        path: '/supply-orders',
        title: 'Supply orders',
        component: OrdersManagement,
        auth: true,
        roles: ['superuser',],
    },

    {
        path: '/sales',
        title: 'Sales persons',
        component: SalesManagement,
        auth: true,
        roles: ['superuser',],
    },

    {
        path: '/sales-groups',
        title: 'Sales groups',
        component: SalesGroupManagement,
        auth: true,
        roles: ['superuser',],
    },

    {
        path: '/clients',
        title: 'Clients',
        component: ClientManagement,
        auth: true,
        roles: ['superuser',],
    },

    {
        path: '/contracts',
        title: 'Contracts',
        component: ContractsManagement,
        auth: true,
        roles: ['superuser',],
    },

    {
        path: '/patients',
        title: 'Patients',
        component: PatientsManagement,
        auth: true,
        roles: ['superuser',],
    },

    // -------------- Printer Page For Lyfe Supplies  ------------ //
    {
        path: '/lyfe-supplies',
        title: '[LYFE] Supplies',
        component: SuppliesOrderLyfe,
        auth: true,
        roles: ['superuser',],
    },

    {
        path: '/lyfe-kits-management',
        title: '[LYFE Kits Management]',
        component: LyfeClientServiceApp,
        auth: true,
        roles: ['superuser',],
    },
    // -------------- Printer Page For Lyfe Supplies  ------------ //
    {
        path: '/efunctional-supplies',
        title: '[EF] Supplies',
        component: SuppliesOrderListEfunctional,
        auth: true,
        roles: ['superuser',]
    },

    {
        path: '/clients/employers/:clientID',
        title: 'Employer details',
        component: EmployerDetails,
        auth: true,
        roles: ['superuser',],
    },
    {
        path: '/clients/providers/:clientID/*',
        title: 'Provider details',
        component: ProviderDetails,
        auth: true,
        roles: ['superuser',],
    },

    {
        path: '/sales/:salePersonID/*',
        title: 'Sale detail',
        component: SalePersonDetailPage,
        auth: true,
        roles: ['superuser',],
    },
    {
        path: '/sales-groups/:saleGroupID',
        title: 'Sale group details',
        component: SaleGroupDetails,
        auth: true,
        roles: ['superuser',],
    },

    // {
    //     path: '/all-billed-kits',
    //     title: 'All billed kits',
    //     component: AllBilledKitsManagement,
    //     auth: true,
    //     roles: ['superuser',],
    // },


    {
        path: '/patients/:patientID/*',
        title: 'Patient details',
        component: PatientDetails,
        auth: true,
        roles: ['superuser',],
    },

    {
        path: '/printer',
        title: 'Printer page',
        component: SuppliesOrder,
        auth: true,
        roles: ['superuser',],
    },

]

// connect all pages
// pages.push(...salesPortalPages);

const routerConfig = [...pages, ...salesPortalPages, ...superusersPortalPages];


export {salesPortalPages, superusersPortalPages};

export default routerConfig;

// ------------- old pages ----------- //
//     'register': {
//         url: '/register',
//         title: 'Register page',
//         jsx: <RegisterForm/>,
//     },
//
//     'forgotPassword': {
//         url: '/forgot-password',
//         title: 'Forgot password page',
//         jsx: <ForgotPasswordForm/>,
//     },
//
