import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Card, Col, Divider, message, Modal, Row, Switch} from 'antd';
import {SettingOutlined} from "@ant-design/icons";

import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";


const EmployerTestSettingsModal = ({authConfig, clientInfo, employerTests}) => {

    const api = useAuthAPI();

    let navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleSwitchToggle = async (checked, employerTest) => {

        if (checked) {
            await api.patch(`/employer-tests/${employerTest?.id}/`, {'status': checked})
                .then(() => {
                    message.success({
                        content: `${employerTest?.product_details?.name} turn ON!`,
                        key: employerTest?.id + new Date().toTimeString(),
                        duration: 2,
                    });
                })
                .catch(err => {
                    console.log("Test Settings Errors", err);
                })

        } else {

            await api.patch(`/employer-tests/${employerTest?.id}/`, {
                'status': checked
            }, authConfig)
                .then(() => {
                    message.success({
                        content: `${employerTest?.product_details?.name} turn OFF!`,
                        key: employerTest?.id + new Date().toTimeString(),
                        duration: 2,
                    });
                })
                .catch(err => {
                    console.log("Test Settings Errors", err);
                })
        }
    }

    return <>
        <Button size={'small'} onClick={showModal} disabled>
            <SettingOutlined/>&nbsp;Test settings
        </Button>

        <Modal
            footer={null}
            width={window.innerWidth > 1200 ? "75%" : '100%'}
            title={`Test settings for ${clientInfo?.name}`}
            open={open}
            onCancel={handleCancel}
        >

            <Col xs={24}>
                <Row gutter={20}>
                    {employerTests && employerTests.length !== 0 && employerTests.map(employerTest =>
                        <Col xs={24} lg={4} key={employerTest?.id}>
                            <Card>
                                {employerTest?.product_details?.name} <br/>
                                $ {employerTest?.product_details?.price}
                                <Divider/>
                                <Switch
                                    defaultChecked={employerTest?.status === true}
                                    onChange={checked => handleSwitchToggle(checked, employerTest)}/>
                            </Card>
                        </Col>)}
                </Row>
            </Col>

        </Modal>
    </>;
}

export default EmployerTestSettingsModal;