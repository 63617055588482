import dayjs from "dayjs";
import React, {useState, useEffect} from 'react';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import {Button, Flex, Spin, Modal, Form, Input, message, InputNumber} from "antd";


const dateOfBirthMask = (value) => {
    let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
    return !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? '-' + x[3] : ''}`;
};

const ActivateTheKitForPatient = ({patientId}) => {
    const api = useAuthAPI();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        collectionDate: '',
        dateActivated: '',
    });

    const [dateValue, setDateValue] = useState('');
    const [errorDetail, setErrorDetail] = useState(null);
    const [kitBarcode, setKitBarcode] = useState(null);
    const [patientWeight, setPatientWeight] = useState(null);

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});

    useEffect(() => {
        // Set today's date as default
        const today = dayjs().format('MM-DD-YYYY');
        setDateValue(today);
        form.setFieldsValue({dateActivated: today});
        form.setFieldsValue({collectionDate: today});
    }, [form]);


    const handleManualInput = (e) => {
        const fieldName = e.target.getAttribute('data-name');
        const maskedValue = dateOfBirthMask(e.target.value);

        setFormValues(prevValues => ({
            ...prevValues,
            [fieldName]: maskedValue
        }));

        form.setFieldsValue({[fieldName]: maskedValue});
    };

    const validateDate = (_, value) => {
        if (!value) {
            return Promise.reject(new Error('Please enter the date of activation'));
        }

        // Regex to match `MM-DD-YYYY` format strictly with exact length check
        const dateFormatRegex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(\d{4})$/;

        if (!dateFormatRegex.test(value)) {
            return Promise.reject(new Error('Invalid date format'));
        }

        const [month, day, year] = value.split('-').map(Number);
        const date = new Date(year, month - 1, day);

        // Check if the year is greater than 1900
        if (year <= 1900) {
            return Promise.reject(new Error('Year must be greater than 1900'));
        }

        // Check if the date is valid and the components match (to prevent cases like 02-30-2022)
        if (!(date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day)) {
            return Promise.reject(new Error('Invalid date'));
        }

        return Promise.resolve();
    };

    const validateKitBarcode = (_, value) => {

        // Regex for allowed characters (letters, numbers, and '-')
        const allowedCharactersRegex = /^[A-Z0-9-]+$/;

        if (!allowedCharactersRegex.test(value)) {
            return Promise.reject(new Error('Barcode can only contain letters, numbers, and "-"'));
        }

        if (!value.startsWith('EF')) {
            return Promise.reject(new Error('Barcode must start with EF'));
        }

        const parts = value.split('-');
        if (parts.length !== 2 || parts[1].length !== 4) {
            return Promise.reject(new Error('Barcode must contain "-" and have 4 characters after "-"'));
        }

        return Promise.resolve();
    };

    const handleInputChange = (e) => {
        const upperCaseValue = e.target.value.toUpperCase();
        form.setFieldsValue({kitBarcode: upperCaseValue});
        setKitBarcode(upperCaseValue);
    };

    const convertToUTCFormat = (dateString) => {
        return dayjs(dateString, 'MM-DD-YYYY').format('YYYY-MM-DD');
    };

    const onFinish = async (values) => {
        setFormData(values);
        setLoading(true);

        const data = {
            patientId: patientId,
            kitBarcode: values.kitBarcode,
            collectionDate: convertToUTCFormat(values.collectionDate),
            dateActivated: convertToUTCFormat(values.dateActivated),
        }

        if (values.kitBarcode.includes("MN")) {
            data.patientWeight = parseInt(patientWeight, 10);
        }

        // message.success('Data: ' + JSON.stringify(data, null, 2));
        //
        // return

        // return console.log(data)

        try {
            await api.post(`/bo-activate-the-kit/`, data);
            // Close the modal on successful password change
            setOpen(false);
            message.success(`${values.kitBarcode} successfully activated!`);
        } catch (error) {
            console.error('Error o: ', error);

            if (error.response) {
                // Request made and server responded
                console.log('Error response: ', error.response.data);
                console.log('Error response status: ', error.response.status);
                console.log('Error response headers: ', error.response.headers);

                setErrorDetail(error.response.data.detail || error.response.data.error);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Spin spinning={loading}>

            <Flex wrap="wrap" gap="small">
                <Button type="primary" size={'small'} onClick={showModal}>
                    Activate the kit
                </Button>
            </Flex>

            <Modal
                title={`Activate the kit for the patient with id: ${patientId}`}
                open={open}
                onOk={() => form.submit()}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                maskClosable={false}
            >
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        name="passwordForm"
                        onFinish={onFinish}
                        scrollToFirstError
                        variant={'filled'}
                    >

                        <Form.Item
                            name="kitBarcode"
                            label="The kit barcode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the kit barcode',
                                },
                                {validator: validateKitBarcode},
                            ]}
                        >
                            <Input placeholder={'EF00001-CC01'} onChange={handleInputChange}/>
                        </Form.Item>


                        <Form.Item
                            name="collectionDate"
                            label="Collection Date"
                            rules={[{required: true, message: 'Please enter the collection date'}]}
                        >
                            <Input
                                value={formValues.collectionDate}
                                onChange={handleManualInput}
                                placeholder="MM-DD-YYYY"
                                data-name="collectionDate"
                            />
                        </Form.Item>


                        <Form.Item
                            name="dateActivated"
                            label="Date of Activation"
                            rules={[
                                {required: true, message: 'Please enter the date of activation'},
                                {validator: validateDate}
                            ]}
                        >
                            <Input
                                value={formValues.dateActivated}
                                onChange={handleManualInput}
                                placeholder="MM-DD-YYYY"
                                data-name="dateActivated"
                            />
                        </Form.Item>


                        {kitBarcode && kitBarcode.includes("MN")
                            ? <Form.Item
                                name="patientWeight"
                                label="Patient weight (lbs)"
                                rules={[
                                    {required: true, message: 'Please enter a patient weight'}
                                ]}
                            >
                                <InputNumber
                                    width={'100%'}
                                    value={formValues.patientWeight}
                                    onChange={(value) => setPatientWeight(value)}
                                    placeholder="Enter patient weight in lbs"
                                    data-name="patientWeight"
                                />
                            </Form.Item>
                            : null
                        }


                        {/*<pre>kitBarcode:&nbsp;*/}
                        {/*    {JSON.stringify(kitBarcode, null, 2)}*/}
                        {/*</pre>*/}

                        {/*<pre>patientWeight:&nbsp;*/}
                        {/*    {JSON.stringify(patientWeight, null, 2)}*/}
                        {/*</pre>*/}


                    </Form>

                    {errorDetail && (
                        <p style={{color: 'red'}}>{errorDetail}</p>
                    )}
                </Spin>
            </Modal>
        </Spin>
    );

}

export default ActivateTheKitForPatient;