// import restAPI from "../../../../../../features/restAPI";
import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    PhoneOutlined,
    MailOutlined,
    SolutionOutlined,
    MedicineBoxOutlined,
    HomeOutlined,
} from '@ant-design/icons';
import {Space, Button, Divider, List, Skeleton} from 'antd';

import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";

const SaleGroupSalesList = ({clientsList, setClientsList, saleGroupID}) => {
    const api = useAuthAPI();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const loadMoreData = async () => {
        if (loading) {
            return;
        }
        setLoading(true);

        await api.get(`/all-clients/?sales_group=${saleGroupID}`)
            .then((response) => {
                setClientsList(response?.data?.results);

                setData({
                    ...data,
                    count: response.data.count,
                    next: response.data.next,
                })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    navigate('/session-expired');
                } else {
                    console.log("getSales Error", err);
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };


    useEffect(() => {
        (async () => {
            try {
                await loadMoreData();
            } catch (err) {
                console.error(err);
            }
        })();
        // eslint-disable-next-line
    }, []);

    return <>
        <InfiniteScroll
            dataLength={data.count || 0}
            next={loadMoreData}
            hasMore={data.next}
            loader={
                <Skeleton
                    avatar
                    paragraph={{
                        rows: 1,
                    }}
                    active
                />
            }
            endMessage={<Divider plain/>}
            scrollableTarget="scrollableDiv"
        >
            <List
                dataSource={clientsList}
                renderItem={client =>
                    <List.Item key={client.id} extra={<Space>

                        {client.fax && <Button type={'text'} size={'small'} title={'Client fax'}>
                            FAX: {client.fax}
                        </Button>}

                        {client.address && <Button type={'text'} size={'small'} title={'Client address'}>
                            <HomeOutlined/>{client.address}
                        </Button>}

                        {client.key_client_contact && <Button type={'text'} size={'small'}>
                            <PhoneOutlined/>{client.key_client_contact}
                        </Button>}


                        {client.phone && <Button href={`tel:${client.phone}`} type={'text'} size={'small'}>
                            <PhoneOutlined/>{client.phone}
                        </Button>}

                        {client.email && <Button href={`mailto:${client.email}`} type={'text'} size={'small'}>
                            <MailOutlined/>{client.email}
                        </Button>}

                    </Space>}>

                        <List.Item.Meta
                            avatar={client?.monthly_amount_per_employee !== null
                                ? <MedicineBoxOutlined style={{fontSize: 35, marginTop: 7}}/>
                                : <SolutionOutlined style={{fontSize: 35, marginTop: 7}}/>}
                            title={
                                <NavLink to={
                                    client?.is_employer
                                        ? `/clients/employers/${client.id}`
                                        : client?.is_web_provider

                                            ? `/clients/providers/${client.id}`
                                            : client?.is_provider && `/clients/providers/${client.id}`}
                                >
                                    {client.name}
                                </NavLink>}
                            description={
                                client?.is_web_provider
                                    ? <>WebProvider</>
                                    : client?.is_employer
                                        ? <>Employer</>
                                        : client?.is_provider && <>Provider</>}
                        />
                    </List.Item>}
            />
        </InfiniteScroll>
    </>;
};
export default SaleGroupSalesList;