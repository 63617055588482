import dayjs from "dayjs";
import styled, {keyframes} from 'styled-components';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import React, {useEffect, useState, useRef} from 'react';
import {
    EyeOutlined,
    ThunderboltOutlined,
    PrinterOutlined,
    CheckSquareOutlined,
    NotificationOutlined
} from "@ant-design/icons";
import {
    Button,
    Col,
    List,
    Row,
    Space,
    Spin,
    Tag,
    Progress,
    Typography,
    Divider,
    Select,
    Modal,
    Alert, message, Input
} from 'antd';
import OrderDetailsWithAllKits from "./OrderDetailsWithAllKits";
import OrderDetailsWithAllKitsForShippedStatus from "./OrderDetailsWithAllKitsForShippedStatus";
import {debounce} from "lodash";


// -----  STYLES  ----- //
const blink = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const BlinkingTag = styled(Tag)`
    animation: ${blink} 1s linear infinite;
`;
// -----  STYLES  ----- //


const ordersStatuses = {
    ORDERED: "Ordered",
    SHIPPED: "Shipped",
    SHOW_ALL: "All",  // not real status, just for filter "All
}

const {ORDERED, SHIPPED, SHOW_ALL} = ordersStatuses;

const initialState = {
    nextURL: null, totalCount: null, clickCounter: 0, nextSearchURL: null,
}

const getBackgroundColor = (index) => {
    return index % 2 === 0 ? 'rgba(1,169,172,0.03)' : '#ffffff'; // Alternating colors
};

const SinglePromoKitsOrderItemWithOrderedStatus = ({
                                                       order,
                                                       setSelectedOrder,
                                                       setIsModalVisible,
                                                       loading,
                                                       index
                                                   }) => {
    const assembledPercentage = order?.kit_count ? (order?.assembled_kit_count / order?.kit_count) * 100 : 0;

    return <Spin spinning={loading}>
        <List.Item
            key={order.id}
            style={{backgroundColor: getBackgroundColor(index)}}
            extra={
                <Space>
                    {Math.round(assembledPercentage) === 100 &&
                        <Alert style={{fontSize: 10}} type={'info'} message={"Ready for shipment"}/>}
                    <Divider type="vertical"/>

                    <Progress
                        steps={15}
                        percent={Math.round(assembledPercentage)}
                        strokeColor={'#01a9ac'}
                    />
                </Space>
            }
        >
            <List.Item.Meta
                title={<Space>
                    <Typography.Title
                        style={{cursor: 'pointer', padding: "10px 10px 0px 10px", margin: 0}}
                        level={4}
                        onClick={() => {
                            setSelectedOrder(order)
                            setIsModalVisible(prevState => !prevState)
                        }}
                    >
                        ID: {order.id} <Divider type="vertical"/> {`${order.firstName} ${order.lastName}`.slice(0, 30)}
                        {`${order.firstName} ${order.lastName}`.length >= 30 ? '...' : ''}

                        {order?.isExpeditedShipping && <>
                            <Divider type="vertical"/>
                            <BlinkingTag icon={<ThunderboltOutlined/>} color={'red'}>
                                Expedited shipping
                            </BlinkingTag>
                        </>}


                        <Divider type="vertical"/>
                        <BlinkingTag icon={<CheckSquareOutlined/>} color={'#199dd9'}>
                            Promo kits order
                        </BlinkingTag>


                    </Typography.Title>
                </Space>}

                description={<Space style={{cursor: 'pointer', padding: "0 10px 10px 10px", margin: 0}}>

                    <Button
                        type={'dashed'}
                        icon={<EyeOutlined/>}
                        size={'small'}
                        onClick={() => {
                            setSelectedOrder(order)
                            setIsModalVisible(prevState => !prevState)
                        }}>
                        open for details
                    </Button>

                    Order date:
                    {order?.created && <Tag bordered={0} color="black">
                        {dayjs(order?.created).format('MM/DD/YYYY')}
                    </Tag>}

                    <Divider type="vertical"/>
                    Status:{order?.status && <Tag bordered={0} color="black">{order?.status}</Tag>}
                </Space>}
            />
        </List.Item>
    </Spin>
}

const SinglePromoMaterialOrderItemWithOrderedStatus = ({
                                                           order,
                                                           setSelectedOrder,
                                                           setIsModalVisible,
                                                           loading,
                                                           index
                                                       }) => {
    const assembledPercentage = order?.kit_count ? (order?.assembled_kit_count / order?.kit_count) * 100 : 0;

    return <Spin spinning={loading}>
        <List.Item
            key={order.id}
            style={{backgroundColor: getBackgroundColor(index)}}
            extra={
                <Space>
                    {Math.round(assembledPercentage) === 100 &&
                        <Alert style={{fontSize: 10}} type={'info'} message={"Ready for shipment"}/>}
                    <Divider type="vertical"/>

                    <Progress
                        steps={15}
                        percent={Math.round(assembledPercentage)}
                        strokeColor={'#01a9ac'}
                    />
                </Space>
            }
        >
            <List.Item.Meta
                title={<Space>
                    <Typography.Title
                        style={{cursor: 'pointer', padding: "10px 10px 0px 10px", margin: 0}}
                        level={4}
                        onClick={() => {
                            setSelectedOrder(order)
                            setIsModalVisible(prevState => !prevState)
                        }}
                    >
                        ID: {order.id} <Divider type="vertical"/> {`${order.firstName} ${order.lastName}`.slice(0, 30)}
                        {`${order.firstName} ${order.lastName}`.length >= 30 ? '...' : ''}

                        {order?.isExpeditedShipping && <>
                            <Divider type="vertical"/>
                            <BlinkingTag icon={<ThunderboltOutlined/>} color={'red'}>
                                Expedited shipping
                            </BlinkingTag>
                        </>}


                        <Divider type="vertical"/>
                        <BlinkingTag
                            icon={<NotificationOutlined/>}
                            color={'#174dbd'}
                        >
                            Marketing materials
                        </BlinkingTag>


                    </Typography.Title>
                </Space>}

                description={<Space style={{cursor: 'pointer', padding: "0 10px 10px 10px", margin: 0}}>

                    <Button
                        type={'dashed'}
                        icon={<EyeOutlined/>}
                        size={'small'}
                        onClick={() => {
                            setSelectedOrder(order)
                            setIsModalVisible(prevState => !prevState)
                        }}>
                        open for details
                    </Button>

                    Order date:
                    {order?.created && <Tag bordered={0} color="black">
                        {dayjs(order?.created).format('MM/DD/YYYY')}
                    </Tag>}

                    <Divider type="vertical"/>
                    Status:{order?.status && <Tag bordered={0} color="black">{order?.status}</Tag>}
                </Space>}
            />
        </List.Item>
    </Spin>
}


const SingleOrderItemWithOrderedStatus = ({order, setSelectedOrder, setIsModalVisible, loading, index}) => {
    const assembledPercentage = order?.kit_count ? (order?.assembled_kit_count / order?.kit_count) * 100 : 0;

    return <Spin spinning={loading}>
        <List.Item
            key={order.id}
            style={{backgroundColor: getBackgroundColor(index)}}
            extra={
                <Space>
                    {Math.round(assembledPercentage) === 100 &&
                        <Alert style={{fontSize: 10}} type={'info'} message={"Ready for shipment"}/>}
                    <Divider type="vertical"/>

                    <Progress
                        steps={15}
                        percent={Math.round(assembledPercentage)}
                        strokeColor={'#01a9ac'}
                    />
                </Space>
            }
        >
            <List.Item.Meta
                title={<Space>
                    <Typography.Title
                        style={{cursor: 'pointer', padding: "10px 10px 0px 10px", margin: 0}}
                        level={4}
                        onClick={() => {
                            setSelectedOrder(order)
                            setIsModalVisible(prevState => !prevState)
                        }}
                    >
                        ID: {order.id} <Divider type="vertical"/> {`${order.firstName} ${order.lastName}`.slice(0, 30)}
                        {`${order.firstName} ${order.lastName}`.length >= 30 ? '...' : ''}

                        {order?.isExpeditedShipping && <>
                            <Divider type="vertical"/>
                            <BlinkingTag icon={<ThunderboltOutlined/>} color={'red'}>
                                Expedited shipping
                            </BlinkingTag>
                        </>}


                    </Typography.Title>
                </Space>}

                description={<Space style={{cursor: 'pointer', padding: "0 10px 10px 10px", margin: 0}}>

                    <Button
                        type={'dashed'}
                        icon={<EyeOutlined/>}
                        size={'small'}
                        onClick={() => {
                            setSelectedOrder(order)
                            setIsModalVisible(prevState => !prevState)
                        }}>
                        open for details
                    </Button>

                    Order date:
                    {order?.created && <Tag bordered={0} color="black">
                        {dayjs(order?.created).format('MM/DD/YYYY')}
                    </Tag>}

                    <Divider type="vertical"/>
                    Status:{order?.status && <Tag bordered={0} color="black">{order?.status}</Tag>}

                    <Divider type="vertical"/>
                    Total kits: {order?.kit_count}

                    <Divider type="vertical"/>
                    Assembled kits: {order?.assembled_kit_count}
                </Space>}
            />
        </List.Item>
    </Spin>
}


const SingleOrderItemWithShippedStatus = ({order, setSelectedOrder, setIsModalVisible, index}) => {
    const assembledPercentage = order?.kit_count ? (order?.assembled_kit_count / order?.kit_count) * 100 : 0;

    return <List.Item
        key={order.id}
        style={{backgroundColor: getBackgroundColor(index)}}
        extra={
            <Space>
                <Progress
                    steps={15}
                    percent={Math.round(assembledPercentage)}
                    strokeColor={'#01a9ac'}
                />
            </Space>
        }
    >
        <List.Item.Meta
            title={<Space>
                <Typography.Title
                    style={{cursor: 'pointer', padding: "10px 10px 0px 10px", margin: 0}}
                    level={4}
                    onClick={() => {
                        setSelectedOrder(order)
                        setIsModalVisible(prevState => !prevState)
                    }}
                >
                    ID: {order.id} <Divider type="vertical"/> {`${order.firstName} ${order.lastName}`.slice(0, 30)}
                    {`${order.firstName} ${order.lastName}`.length >= 30 ? '...' : ''}
                </Typography.Title>
            </Space>}

            description={<Space style={{cursor: 'pointer', padding: "0 10px 10px 10px", margin: 0}}>

                Order date:
                {order?.created && <Tag bordered={0} color="black">
                    {dayjs(order?.created).format('MM/DD/YYYY')}
                </Tag>}

                <Divider type="vertical"/>
                Assembled kits: {order?.assembled_kit_count}

                <Divider type="vertical"/>
                Tracking number:
                <Tag bordered={0} color="black">
                    {order?.tracking_number}
                </Tag>

                {order?.isExpeditedShipping && <>
                    <Divider type="vertical"/>
                    <BlinkingTag icon={<ThunderboltOutlined/>} color={'red'}>
                        Expedited shipping
                    </BlinkingTag>
                </>}

            </Space>}
        />
    </List.Item>
}


const OrderItem = ({
                       order,
                       index,
                       loading,
                       setSelectedOrder,
                       setIsModalVisible,
                   }) => {

    let Component = SingleOrderItemWithShippedStatus; // Default component

    if (order.status === ORDERED) {
        if (order.orderType === null) {
            Component = SingleOrderItemWithOrderedStatus;
        } else if (order.orderType === 'PromoMaterialsOrder') {
            Component = SinglePromoMaterialOrderItemWithOrderedStatus;
        } else if (order.orderType === 'PromoKitsOrder') {
            // Component = SinglePromoKitsOrderItemWithOrderedStatus; // Your new component for PromoKitsOrder
            Component = SinglePromoKitsOrderItemWithOrderedStatus;
        }
    }

    return (
        <Component
            loading={loading}
            order={order}
            setSelectedOrder={setSelectedOrder}
            setIsModalVisible={setIsModalVisible}
            index={index}
        />
    );
};

const OrdersList = ({ordersList, setOrdersList, printerDevice, setPrinterDevice}) => {

    const zebraPrinterRef = useRef(null);  // Use this ref to hold the printer instance.

    const api = useAuthAPI();

    const [loading, setLoading] = useState(true);  // initialize to true to show loading at the start

    /* ---- PRINTER ----- */
    const [deviceList, setDeviceList] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [chosenLabelSize, setChosenLabelSize] = useState(1);


    // Convert getLocalDevices to return a Promise
    const getLocalDevicesAsync = () => {
        return new Promise((resolve, reject) => {
            window.BrowserPrint.getLocalDevices((devices) => {
                if (devices && devices.printer) {
                    resolve(devices.printer);
                } else {
                    reject(new Error("No printers found"));
                }
            });
        });
    };

    const printTestLabel = () => {

        const barcode = 'EF12345-ZZ99';

        if (!selectedDevice) {
            console.error("No device selected!");
            message?.error("No device selected! Make sure you have a printer connected.")
            return;
        }

        if (!barcode || barcode.trim() === '') {
            console.error("Invalid barcode provided.");
            message?.error("Invalid barcode provided.");
            return;
        }

        /// ======================================================= ///
        let expDate;

        // get a last four letters from barcode
        const lastFourLetters = barcode.slice(-4);

        // get a first two letters from lastFourLetters
        const firstTwoLetters = lastFourLetters.slice(0, 2);

        // compare firstTwoLetters with "MN", "AF", "AM"
        if (["MN", "AF", "AM"].includes(firstTwoLetters)) {
            expDate = dayjs().add(2, 'month').format('MM/DD/YYYY');
        } else {
            expDate = dayjs().add(6, 'month').format('MM/DD/YYYY');
        }
        /// ======================================================= ///


        let zplCommand;

        // This is a good label for 201 dpi, but shorter barcode for Florida labels
        if (chosenLabelSize === 1) {
            zplCommand = `
            ^XA
            ^FO60,40^BCN,80,Y,2,4^FD${barcode}^FS
            ^FO95,165^A0N,20,26^FDExp. Date: ${expDate}^FS
            ^XZ
        `;
        }

        // Label for 201 dpi, for my labels in New York
        if (chosenLabelSize === 2) {
            zplCommand = `
            ^XA
            ^FO60,40^BCN,150,Y,2,4^FD${barcode}^FS
            ^FO95,220^A0N,20,26^FDExp. Date: ${expDate}^FS
            ^XZ
        `;
        }

        // Label for ZD410-300dpi ZPL
        if (chosenLabelSize === 3) {
            zplCommand = `
            ^XA
            ^FO145,30^BCN,160,Y,200,200^FD${barcode}^FS
            ^FO190,220^A0N,20,26^FDExp. Date: ${expDate}^FS
            ^XZ
        `;
        }

        // // Create a new printer instance if one doesn't exist
        // if (!zebraPrinterRef.current) {
        //     zebraPrinterRef.current = new window.Zebra.Printer(selectedDevice);
        // }

        setLoading(true);

        // Create a new printer instance
        zebraPrinterRef.current = new window.Zebra.Printer(selectedDevice);

        // Send the label to the printer
        zebraPrinterRef.current.isPrinterReady().then(function () {

            for (let i = 0; i < 1; i++) {
                zebraPrinterRef.current.send(zplCommand);
            }

            console.log(`Sent label with barcode: ${barcode} to printer.`);
            setLoading(false);
        }).catch(function (error) {
            console.error("Error printing label:", error);
            message.error("Error printing label.");
            setLoading(false);
        });
    }


    const printLabel = barcode => {

        if (!selectedDevice) {
            console.error("No device selected!");
            message?.error("No device selected! Make sure you have a printer connected.")
            return;
        }

        if (!barcode || typeof barcode !== 'string' || barcode.trim() === '') {
            console.error("Invalid barcode provided.");
            message?.error("Invalid barcode provided.");
            return;
        }

        /// ======================================================= ///
        let expDate;

        // get a last four letters from barcode
        const lastFourLetters = barcode.slice(-4);

        // get a first two letters from lastFourLetters
        const firstTwoLetters = lastFourLetters.slice(0, 2);

        // compare firstTwoLetters with "MN", "AF", "AM"
        if (["MN", "AF", "AM"].includes(firstTwoLetters)) {
            expDate = dayjs().add(2, 'month').format('MM/DD/YYYY');
        } else {
            expDate = dayjs().add(6, 'month').format('MM/DD/YYYY');
        }
        /// ======================================================= ///

        let zplCommand;

        // This is a good label for 201 dpi, but shorter barcode for Florida labels
        if (chosenLabelSize === 1) {
            zplCommand = `
            ^XA
            ^FO60,40^BCN,80,Y,2,4^FD${barcode}^FS
            ^FO95,165^A0N,20,26^FDExp. Date: ${expDate}^FS
            ^XZ
        `;
        }

        // Label for 201 dpi, for my labels in New York
        if (chosenLabelSize === 2) {
            zplCommand = `
            ^XA
            ^FO60,40^BCN,150,Y,2,4^FD${barcode}^FS
            ^FO95,220^A0N,20,26^FDExp. Date: ${expDate}^FS
            ^XZ
        `;
        }

        // Label for ZD410-300dpi ZPL
        if (chosenLabelSize === 3) {
            zplCommand = `
            ^XA
            ^FO145,30^BCN,160,Y,200,200^FD${barcode}^FS
            ^FO190,220^A0N,20,26^FDExp. Date: ${expDate}^FS
            ^XZ
        `;
        }

        // // Create a new printer instance if one doesn't exist
        // if (!zebraPrinterRef.current) {
        //     zebraPrinterRef.current = new window.Zebra.Printer(selectedDevice);
        // }

        setLoading(true);

        // Create a new printer instance
        zebraPrinterRef.current = new window.Zebra.Printer(selectedDevice);

        // Send the label to the printer
        zebraPrinterRef.current.isPrinterReady().then(function () {

            for (let i = 0; i < 4; i++) {
                zebraPrinterRef.current.send(zplCommand);
            }

            console.log(`Sent label with barcode: ${barcode} to printer.`);
            setLoading(false);
        }).catch(function (error) {
            console.error("Error printing label:", error);
            message.error("Error printing label.");
            setLoading(false);
        });
    };

    useEffect(() => {
        (async () => {
            try {
                const printers = await getLocalDevicesAsync();
                console.log("Devices retrieved:", printers);

                // Filter printers to get those connected via USB
                const usbPrinters = printers.filter(printer => printer.connection === "usb");

                // Set the full list of USB printers
                setDeviceList(usbPrinters);

                // Set the first printer as the selected device if available
                if (usbPrinters.length > 0) {
                    setSelectedDevice(usbPrinters[0]);

                } else {
                    console.warn("No USB printers found.");
                    message.error("No USB printers found.")
                    return;
                }

                // message.success('Printer connected successfully!');
            } catch (error) {
                console.error(error);
                message.error(error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);
    /* ---- PRINTER ----- */


    /*  -----  OTHER COMP STUFF  -----  */
    const [searchSTR, setSearchSTR] = useState(false);

    /* State for the Modal window with Kit lists */
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);


    /*  Pagination state */
    const [responseData, setResponseData] = useState(null);

    // eslint-disable-next-line
    const [month, setMonth] = useState(null);

    // select state
    const [selectedStatus, setSelectedStatus] = useState(ORDERED);


    const [loadMoreObject, setLoadMoreObject] = useState(initialState);


    const onSelectedStatusChange = value => {
        setSelectedStatus(value);
        setLoadMoreObject(initialState);
    }


    const showTotalKitsForAllTime = () => {
        setMonth(null);
    }

    const filteringAndLoadMoreHandler = () => {
        /* Brain for filtering (by date and sort by status) and load more */
        /*** --- Read the code comments to understand how it works --- ***/

        let requestURL; // Initialize request URL. It will be different for different cases for this component.

        // Show all orders. Doesn't matter what status they have
        if (selectedStatus === SHOW_ALL) {
            requestURL = `/orders/supplies-orders-all/?ordering=-created&isTest=false`
        }

        // Show filtered orders by status
        if (selectedStatus === ORDERED || selectedStatus === SHIPPED) {
            requestURL = `/orders/supplies-orders-all/?ordering=-created&isTest=false&status=${selectedStatus}`
        }

        // Check a default case when search input doesn't touched and the user clicked the load more btn
        if (loadMoreObject.nextURL) {
            requestURL = loadMoreObject.nextURL;
        }

        // Filtering by month, starts working after user select month
        if (month !== null) {
            requestURL += `&created__gte=${month.startDate}`;
            requestURL += `&created__lte=${month.endDate}`;
        }

        return requestURL;
    }

    const updateComponentStateAfterRequest = response => {
        /* Update state data after filtering request or sorting */

        // First time when a user clicks the load more btn
        if (loadMoreObject.clickCounter === 0) {
            setOrdersList(response?.data?.results);
        }

        // Second and more times when a user clicks the load more btn
        if (loadMoreObject.clickCounter > 0) {
            setOrdersList(prevState => [...prevState, ...response?.data?.results]);
        }

        setLoadMoreObject({
            ...loadMoreObject, nextURL: response?.data?.next, totalCount: response?.data?.count,
        });
    }

    const loadMoreData = async () => {
        setLoading(true);

        try {
            let requestURL = filteringAndLoadMoreHandler();

            if (searchSTR && searchSTR.length > 1) {
                const response = await api.get(`/orders/all/?ordering=-created&isTest=false&search=${encodeURIComponent(searchSTR)}`);
                updateComponentStateAfterRequest(response);
                return
            }

            const response = await api.get(requestURL);
            updateComponentStateAfterRequest(response);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    // Listen for Load More button click
    useEffect(() => {
        // if (ordersList?.length <= loadMoreObject.totalCount) {
        (async () => {
            try {
                await loadMoreData();
            } catch (err) {
                console.error(err);
            }
        })();
        // }
        // eslint-disable-next-line
    }, [loadMoreObject.clickCounter, searchSTR, month, selectedStatus]);


    const buttonClickHandler = () => {
        setLoadMoreObject({...loadMoreObject, clickCounter: loadMoreObject?.clickCounter + 1});
    }

    const handleDeviceChange = (event) => {

        console.log(event.target.value)

        const selected = deviceList.find(device => device.id === event.target.value);
        setSelectedDevice(selected);
    };


    const loadMore = !loading ? (

        <Row style={{alignSelf: "center", justifyContent: "center", marginTop: 20}}>

            <Col xs={5}>
                <p style={{textAlign: "center"}}>
                    {ordersList?.length === 0
                        ? <>Nothing to show</>
                        : <>
                            {ordersList?.length} order's on page from {loadMoreObject?.totalCount}

                            <Progress
                                strokeColor={'#01a9ac'}
                                percent={Math.round((ordersList?.length / loadMoreObject.totalCount) * 100)}
                                status="active"
                            />
                        </>
                    }
                </p>
            </Col>

            <Col xs={24}>
                <div style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                    display: (loadMoreObject?.clickCounter === 0 && !loadMoreObject?.nextURL) || (ordersList?.length === loadMoreObject?.totalCount)
                        ? 'none'
                        : 'block',
                }}>
                    <Button onClick={buttonClickHandler} loading={loading} disabled={loading}>
                        loading more
                    </Button>
                </div>
            </Col>
        </Row>
    ) : null;

    const debouncedSearch = debounce(value => setSearchSTR(value), 300);


    return <Spin spinning={loading}>

        <Row gutter={10}>

            <Col xs={24}>
                <Input.Search
                    onChange={e => debouncedSearch(e.target.value)}
                    placeholder="Search by order ID, email, tracking number"
                    allowClear
                />
            </Col>

            <Col xs={24} lg={24} xl={24}>
                <Space style={{margin: '20px 0'}}>

                    {searchSTR
                        ? (<Tag>We find <strong>{loadMoreObject?.totalCount}</strong> order's in Database</Tag>)
                        : (loadMoreObject?.totalCount === null || loadMoreObject?.totalCount === 0)
                            ? <>Nothing to show</>
                            : (<Tag>We have <strong>{loadMoreObject?.totalCount}</strong> order's in Database</Tag>)
                    }


                    <span>Filter orders by status:</span>

                    <Select
                        size={'small'}
                        onChange={onSelectedStatusChange}
                        defaultValue={selectedStatus}
                        options={[
                            {
                                value: ORDERED,
                                label: ORDERED,
                            },
                            {
                                value: SHIPPED,
                                label: SHIPPED,
                            },
                        ]}
                    />

                    <Spin spinning={loading}>
                        {selectedDevice ? <Space>
                                <Divider type="vertical"/>
                                <Typography.Text type="secondary">
                                    <strong>USB Printer: </strong>
                                </Typography.Text>

                                <Select
                                    size={'small'}
                                    disabled={!!!selectedDevice}
                                    onChange={value => {
                                        // console.log(value)
                                        // console.log(deviceList)
                                        const selected = deviceList.find(device => device.uid === value);
                                        // console.log(selected)
                                        setSelectedDevice(selected);
                                    }}
                                    value={selectedDevice ? selectedDevice.uid : undefined}
                                >
                                    {deviceList.map(device => (
                                        <Select.Option key={device.uid} value={device.uid}>
                                            {device.name}
                                        </Select.Option>
                                    ))}
                                </Select>

                                <Divider type="vertical"/>
                                <Typography.Text type="secondary">
                                    <strong>Label size: </strong>
                                </Typography.Text>

                                <Select
                                    disabled={!!!selectedDevice}
                                    onChange={value => setChosenLabelSize(value)}
                                    value={chosenLabelSize}
                                    size={'small'}
                                >
                                    <Select.Option key={1} value={1}>
                                        201 dpi (FL)
                                    </Select.Option>

                                    <Select.Option key={2} value={2}>
                                        201 dpi (NY)
                                    </Select.Option>

                                    <Select.Option key={3} value={3}>
                                        301 dpi (NY)
                                    </Select.Option>
                                </Select>


                                <Button size={'small'} type={'primary'} onClick={printTestLabel} icon={<PrinterOutlined/>}>
                                    Print test label
                                </Button>

                            </Space>

                            : <>
                                <Divider type="vertical"/>
                                <Typography.Text type="secondary">
                                    <strong>USB Printer: <Tag color={'red'}>not connected</Tag> </strong>
                                </Typography.Text>
                            </>
                        }
                    </Spin>
                </Space>
            </Col>
        </Row>


        <List
            loadMore={loadMore}
            dataSource={ordersList}
            renderItem={(order, index) => (
                <OrderItem
                    order={order}
                    index={index}
                    loading={loading}
                    setSelectedOrder={setSelectedOrder}
                    setIsModalVisible={setIsModalVisible}
                />
            )}
        />


        {/*<List*/}
        {/*    loadMore={loadMore}*/}
        {/*    dataSource={ordersList}*/}
        {/*    renderItem={(order, index) =>*/}
        {/*        order?.status === ORDERED && order?.orderType === null*/}
        {/*            ? <SingleOrderItemWithOrderedStatus*/}
        {/*                loading={loading}*/}
        {/*                order={order}*/}
        {/*                setSelectedOrder={setSelectedOrder}*/}
        {/*                setIsModalVisible={setIsModalVisible}*/}
        {/*                index={index}*/}
        {/*            />*/}

        {/*            : order?.status === ORDERED && order?.orderType === 'PromoMaterialsOrder'*/}

        {/*                ? <SinglePromoMaterialOrderItemWithOrderedStatus*/}
        {/*                    loading={loading}*/}
        {/*                    order={order}*/}
        {/*                    setSelectedOrder={setSelectedOrder}*/}
        {/*                    setIsModalVisible={setIsModalVisible}*/}
        {/*                    index={index}*/}
        {/*                />*/}
        {/*                : <SingleOrderItemWithShippedStatus*/}
        {/*                    loading={loading}*/}
        {/*                    order={order}*/}
        {/*                    setSelectedOrder={setSelectedOrder}*/}
        {/*                    setIsModalVisible={setIsModalVisible}*/}
        {/*                    index={index}*/}
        {/*                />*/}
        {/*    }*/}
        {/*/>*/}

        {/*PromoKitsOrder*/}

        <Modal
            title={<Space>Order details</Space>}
            width={"50%"}
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            destroyOnClose={true}
            footer={null}  // This removes the default buttons
            maskClosable={false}  // Prevent closing the modal by clicking outside it
        >
            {selectedOrder?.status === ORDERED

                ? <OrderDetailsWithAllKits
                    loading={loading}
                    printLabel={printLabel}
                    setOrdersList={setOrdersList}
                    setSelectedOrder={setSelectedOrder}
                    selectedOrder={selectedOrder}
                    printerDevice={printerDevice}
                    setPrinterDevice={setPrinterDevice}
                    setIsModalVisible={setIsModalVisible}
                />

                : selectedOrder?.status === SHIPPED

                    ? <OrderDetailsWithAllKitsForShippedStatus
                        loading={loading}
                        printLabel={printLabel}
                        setOrdersList={setOrdersList}
                        setSelectedOrder={setSelectedOrder}
                        selectedOrder={selectedOrder}
                        printerDevice={printerDevice}
                        setPrinterDevice={setPrinterDevice}
                        setIsModalVisible={setIsModalVisible}
                    />
                    : null
            }
        </Modal>
    </Spin>
        ;
};
export default OrdersList;