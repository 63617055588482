import React, {useState} from 'react';
import {useAuthAPI} from "../../../../../../../../features/hooks/useAuthAPI";
import {Button, Flex, Spin, Modal, Form, Input, message} from "antd";


const ChangeTheUsername = ({authUserId, username}) => {
    const api = useAuthAPI();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [errorDetail, setErrorDetail] = useState(null);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    const [form] = Form.useForm();

    const onFinish = async (values) => {

        const data = {
            id: authUserId,  // each account has a unique User id
            newUsername: values.email,
        }

        try {
            setLoading(true);
            await api.post(`/bo-change-username/`, data);
            // Close the modal on successful username change
            setOpen(false);
            message.success('Username changed successfully');

            setTimeout(() => {
                window.location.reload(true);
            }, 500);

        } catch (error) {
            console.error('Error changing username: ', error);
            // Handle the error appropriately in your application

            if (error.response) {
                // Request made and server responded
                console.log('Error response: ', error.response.data);
                console.log('Error response status: ', error.response.status);
                console.log('Error response headers: ', error.response.headers);

                setErrorDetail(error.response.data.detail);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Flex wrap="wrap" gap="small">
                <Button type="dashed" size={'small'} onClick={showModal}>
                    Change an username for login
                </Button>
            </Flex>

            <Modal
                title="Change an username"
                open={open}
                onOk={() => form.submit()}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                maskClosable={false}
            >
                <Spin spinning={loading}>
                    <p>
                        <b>Current username:</b> {username}
                    </p>

                    <p>Enter a new username for login or cancel this action</p>


                    <Form
                        form={form}
                        name="usernameForm"
                        onFinish={onFinish}
                        scrollToFirstError
                        variant={'filled'}
                    >
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                {
                                    type: 'email',
                                    message: 'The input is not a valid email!',
                                },
                                {
                                    max: 256,  // Optional: Adjust the maximum length as needed
                                    message: 'Email must be less than 256 characters!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Form>
                </Spin>

                {errorDetail && (
                    <p style={{color: 'red'}}>{errorDetail}</p>
                )}

            </Modal>
        </>
    );

}

export default ChangeTheUsername;