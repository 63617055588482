import React, {useState} from 'react';
import {Col, Divider, Form, Input, Row, Checkbox} from 'antd';
import StatesAutoComplete from "../../../components/StatesAutoComplete";
import {phoneNumberMask, isValidNPI} from "../../../../../../../features/validation";

const PHONE_PATTERN = /\((\d{3})\)\s(\d{3})-(\d{4})/;
const EMAIL_PATTERN = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const ZIP_PATTERN = /^\d{5}$/;

const Provider = ({formData, setFormData, form}) => {
    const [isPhysicianNetwork, setIsPhysicianNetwork] = useState(true);
    const [isBeAbleToSeePatientResults, setIsBeAbleToSeePatientResults] = useState(false);
    const [isSelfPhlebotomy, setIsSelfPhlebotomy] = useState(false);

    const changeAbilityToSeePatientResults = ({target: {checked}}) => {
        /* Create formObj field and Update value */
        setIsBeAbleToSeePatientResults(checked);
        setFormData({...formData, be_able_to_see_patients_results: checked});
    }

    const changePhlebotomyMethod = ({target: {checked}}) => {
        /* Create formObj field and Update value */
        setIsSelfPhlebotomy(checked);
        setFormData({...formData, is_self_phlebotomy: checked});
    }


    const handleInputChange = (key) => ({target: {value}}) => {
        setFormData(prev => ({...prev, [key]: value}));
    };

    const handleCheckboxChange = (setter, formKey) => ({target: {checked}}) => {
        setter(checked);
        setFormData(prev => ({...prev, [formKey]: checked}));
    };

    const handlePhoneChange = ({target: {value}}) => {
        const prettyPhoneNumber = phoneNumberMask(value);

        form.setFieldValue('phone', prettyPhoneNumber); // This assumes you have a method `setFieldValue` on your form object. Adjust as necessary.
        setFormData(prev => ({...prev, phone: prettyPhoneNumber}));
    };

    const npiValidator = (_, value) => {
        if (!value || isValidNPI(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error("Invalid NPI!"));
    };

    return <>
        <Row gutter={16}>

            <Col span={22}>
                <Form.Item>
                    <Checkbox
                        checked={isPhysicianNetwork}
                        onChange={handleCheckboxChange(setIsPhysicianNetwork, 'is_physician_network')}
                    >
                        Physician network
                    </Checkbox>
                </Form.Item>
            </Col>

            {isPhysicianNetwork || (
                <Col span={12}>
                    <Form.Item
                        name="doctor_npi"
                        label="Provider NPI"
                        rules={[{required: true, message: 'Please enter Provider NPI',}, {validator: npiValidator}]}
                    >
                        <Input
                            onChange={handleInputChange('doctor_npi')}
                            placeholder="Please enter Provider NPI"
                        />
                    </Form.Item>
                </Col>
            )}

            <Col span={12}>
                <Form.Item
                    name="name"
                    label="Client name"
                    rules={[{required: true, message: 'Please enter client name',},]}
                >
                    <Input
                        onChange={handleInputChange('name')}
                        placeholder="Please enter client name"
                    />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="fax"
                    label="Fax"
                >
                    <Input
                        onChange={handleInputChange('fax')}
                        placeholder="Please enter Fax"
                    />
                </Form.Item>
            </Col>
        </Row>

        <Row gutter={16}>
            <Col span={12}>

                <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                        {required: true, message: 'Please enter phone',},
                        {pattern: PHONE_PATTERN, message: 'Please enter valid phone number',}
                    ]}
                >
                    <Input
                        onChange={handlePhoneChange}
                        placeholder="Please enter phone"
                    />
                </Form.Item>

            </Col>

            <Col span={12}>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter email',

                        },
                        {
                            // eslint-disable-next-line
                            pattern: EMAIL_PATTERN,
                            message: 'Please enter valid email',
                        }
                    ]}
                >
                    <Input
                        // onChange={({target: {value}}) => setFormData({...formData, email: value})}
                        onChange={handleInputChange('email')}
                        placeholder="Please enter email"
                    />
                </Form.Item>
            </Col>
        </Row>


        <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                    name="city"
                    label="City"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter city',
                        },
                    ]}
                >
                    <Input
                        onChange={handleInputChange('city')}
                        placeholder="Please enter city"
                    />
                </Form.Item>
            </Col>

            <Col span={12}>
                <StatesAutoComplete setFormData={setFormData}/>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="zip"
                    label="Zip code"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter zip code',
                        },
                        {
                            // eslint-disable-next-line
                            pattern: ZIP_PATTERN,
                            message: 'Please enter zip code. We expect 5 numbers',
                        }
                    ]}
                >
                    <Input
                        onChange={handleInputChange('zip')}
                        placeholder="Please enter zip code"
                    />
                </Form.Item>
            </Col>
        </Row>

        <Row gutter={16}>
            <Col span={12}>
                <Form.Item>
                    <Checkbox checked={isBeAbleToSeePatientResults} onChange={changeAbilityToSeePatientResults}>
                        Be able to see patients results
                    </Checkbox>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item>
                    <Checkbox checked={isSelfPhlebotomy} onChange={changePhlebotomyMethod}>
                        Is self phlebotomy?
                    </Checkbox>
                </Form.Item>
            </Col>
        </Row>


        <Row gutter={16}>
            <Col span={24}>
                <Form.Item
                    name="address"
                    label="Address"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter address',
                        },
                    ]}
                >
                    <Input
                        onChange={handleInputChange('address')}
                        placeholder="Please enter address"
                    />
                </Form.Item>
            </Col>
        </Row>

        <Divider/>

        <Row gutter={16}>
            <Col span={24}>
                <Form.Item
                    name="billing_email"
                    label="Billing email"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter billing email',

                        },
                        {
                            // eslint-disable-next-line
                            pattern: EMAIL_PATTERN,
                            message: 'Please enter valid email',
                        }
                    ]}
                >
                    <Input
                        onChange={handleInputChange('billing_email')}
                        placeholder="Please enter billing email"
                    />
                </Form.Item>
            </Col>
        </Row>
    </>;
}
export default Provider;