import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {Route, Routes} from 'react-router-dom';
import pages from "../../../../Root/routerConfig";
import IndexPage from "./NestedPages/IndexPage";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import SalesGroupDetails from "./NestedPages/SalesGroupDetails";


const SalePersonDetailPage = () => {
    const {salePersonID} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [clientInfo, setClientInfo] = useState(null);
    const [salesPersonData, setSalesPersonData] = useState(null);

    // Create prop's object for nested components
    const props = {
        salesPersonData,
        setSalesPersonData,
        salePersonID,
        isLoading,
        clientInfo,
        setClientInfo,
    };

    return <Routes>
        <Route index element={<IndexPage {...props}/>}/>
        <Route path="sales-group-details/:salesGroupId" element={<SalesGroupDetails {...props}/>}/>
        <Route path="*" element={<> 404 </>}/>
    </Routes>;
}

export default SalePersonDetailPage;