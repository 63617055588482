import React from "react";

import {NavLink} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";
import {Col, Row, Tabs, Breadcrumb, Divider, Spin, Typography} from 'antd';

import PersonalInformation from "./PersonalInformation";
import ChangePassword from "./ChangePassword";


const ManageProviderInformation = ({clientID, isLoading, clientInfo, authConfig, setClientInfo}) => {

    const items = [
        {
            key: '1',
            label: `Personal info`,
            children: <PersonalInformation
                clientID={clientID}
                clientInfo={clientInfo}
                authConfig={authConfig}
                setClientInfo={setClientInfo}
            />,
        },

        {
            key: '2',
            label: `Change password`,
            children: <ChangePassword
                clientID={clientID}
                clientInfo={clientInfo}
                authConfig={authConfig}
            />,
            disabled: true
        },
    ];

    return <Spin spinning={isLoading}>

        <Row>
            <Col span={24}>

                <Typography.Title>
                    {clientInfo?.name} | Manage info
                </Typography.Title>

                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to={'/'}>
                            <HomeOutlined/>&nbsp;
                            Dashboard
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/clients`}>
                            Clients
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/clients/providers/${clientID}`}>
                            {clientInfo?.name}
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Manage info</Breadcrumb.Item>
                </Breadcrumb>

                <Divider/>
            </Col>


            <Col span={24}>
                <Row>
                    <Tabs defaultActiveKey="1" items={items}/>
                </Row>
            </Col>

        </Row>
    </Spin>;
}

export default ManageProviderInformation;