import React, {useState} from 'react';
import SalesGroupList from "./SalesGroupList";
import NewSalesGroupForm from "./NewSalesGroupForm";
import {Row, Col, Divider, Breadcrumb, Typography} from "antd";
import {NavLink} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";


const SalesManagement = () => {
    const [salesGroupsList, setSalesGroupsList] = useState([]);


    return <Row justify="center">

        <Col xs={24}>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to={'/'}>
                        <HomeOutlined/>&nbsp;
                        Dashboard
                    </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    Sales Groups
                </Breadcrumb.Item>
            </Breadcrumb>

            <Typography.Title level={1}>
                Sales Groups
            </Typography.Title>

            <NewSalesGroupForm setSalesGroupsList={setSalesGroupsList}/>
        </Col>

        <Divider/>

        <Col xs={24}>
            <SalesGroupList
                salesGroupsList={salesGroupsList}
                setSalesGroupsList={setSalesGroupsList}
            />
        </Col>

    </Row>
};

export default SalesManagement;