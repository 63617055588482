import dayjs from "dayjs";
import {debounce} from 'lodash';
import React, {useEffect, useState} from 'react';

import {SolutionOutlined} from '@ant-design/icons';
import {
    Button, Col, List, Row, Space, Spin, Tag, Progress, Typography, Divider, DatePicker, Select, Modal, Input,
} from 'antd';

import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import OrderDetailsWithAllKitsForShippedStatus
    from "../SuppliesOrderListEfunctional/OrderDetailsWithAllKitsForShippedStatus";


const ordersStatuses = {
    ORDERED: "Ordered",
    SHIPPED: "Shipped",
    SHOW_ALL: "All",  // not real status, just for filter "All
}

const {ORDERED, SHIPPED, SHOW_ALL} = ordersStatuses;

const initialState = {
    nextURL: null, totalCount: null, clickCounter: 0, nextSearchURL: null,
}

const getBackgroundColor = (index) => {
    return index % 2 === 0 ? 'rgba(1,169,172,0.03)' : '#ffffff'; // Alternating colors
};

const OrdersList = ({ordersList, setOrdersList}) => {
    const [debugMode, setDebugMode] = useState(false);
    const api = useAuthAPI();

    // select state
    const [selectedStatus, setSelectedStatus] = useState(SHOW_ALL);

    const [searchSTR, setSearchSTR] = useState(null);
    const [loading, setLoading] = useState(false);

    // eslint-disable-next-line
    const [month, setMonth] = useState(null);

    const [loadMoreObject, setLoadMoreObject] = useState(initialState);

    const filteringAndLoadMoreHandler = () => {
        // Use loadMoreObject.nextURL if available
        if (loadMoreObject.nextURL) {
            return loadMoreObject.nextURL;
        }

        let requestURL = '/orders/all/?ordering=-created&isTest=false'; // Base URL

        // Append status to the URL if a specific status is selected
        if (selectedStatus === ORDERED || selectedStatus === SHIPPED) {
            requestURL += `&status=${selectedStatus}`;
        }

        // Append date filters if month is selected
        if (month !== null) {
            requestURL += `&created__gte=${month.startDate}`;
            requestURL += `&created__lte=${month.endDate}`;
        }

        return requestURL;
    }


    const updateComponentStateAfterRequest = (response, isSearchActive) => {
        // Append new results or set new results
        if (loadMoreObject.clickCounter > 0) {
            setOrdersList(prevState => [...prevState, ...response?.data?.results]);
        } else {
            setOrdersList(response?.data?.results);
        }

        setLoadMoreObject(prev => ({
            ...prev,
            nextURL: isSearchActive ? null : response?.data?.next,
            nextSearchURL: isSearchActive ? response?.data?.next : null,
            totalCount: response?.data?.count,
        }));
    }


    const loadMoreData = async () => {
        if (loading) return;
        setLoading(true);

        try {
            let requestURL;

            // Check if we are continuing a search or starting a new one
            if (searchSTR && !loadMoreObject.nextSearchURL) {
                // New search
                requestURL = `/orders/all/?ordering=-created&isTest=false&search=${encodeURIComponent(searchSTR)}`;
            } else if (searchSTR && loadMoreObject.nextSearchURL) {
                // Continuing an existing search
                requestURL = loadMoreObject.nextSearchURL;
            } else {
                // Normal pagination (not a search)
                requestURL = loadMoreObject.nextURL || filteringAndLoadMoreHandler();
            }

            const response = await api.get(requestURL);
            updateComponentStateAfterRequest(response, !!searchSTR);
        } catch (error) {
            console.error("Error in loadMoreData:", error);
        } finally {
            setLoading(false);
        }
    };


    const onSelectedStatusChange = value => {
        setSelectedStatus(value);
        setLoadMoreObject(initialState);
    }

    const onChangeMonthHandler = value => {
        const currentDate = dayjs(value);

        if (currentDate.isValid()) {
            const startDate = currentDate.startOf('month').format('YYYY-MM-DD');
            const endDate = currentDate.endOf('month').format('YYYY-MM-DD');

            setMonth({startDate, endDate});
            return;
        }
        setMonth(null);
    }

    // const buttonClickHandler = () => {
    //     setLoadMoreObject({...loadMoreObject, clickCounter: loadMoreObject.clickCounter + 1});
    // }

    const buttonClickHandler = () => {
        setLoadMoreObject(prevState => ({
            ...prevState,
            clickCounter: prevState.clickCounter + 1
        }));
    }


    const showDebugInfo = () => <>
        <hr/>
        <h1>DEBUG</h1>
        <p>
            <strong>Total count:</strong> {loadMoreObject.totalCount}
        </p>

        <p>
            <strong>Next URL:</strong> {loadMoreObject.nextURL}
        </p>

        <p>
            <strong>Next searchURL:</strong> {loadMoreObject.nextSearchURL}
        </p>

        <p>
            <strong>Click counter:</strong> {loadMoreObject.clickCounter}
        </p>

        <p>
            Orders on page: {ordersList?.length}
        </p>
        <hr/>

        <p>
            Start Date: {month?.startDate}
        </p>

        <p>
            End Date: {month?.endDate}
        </p>
        <hr/>
    </>


    // Modal
    /* State for the Modal window with Kit lists */
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    // Modal


    // Listen for Load More button click
    useEffect(() => {

        (async () => {
            if (loadMoreObject.clickCounter > 0) {
                try {
                    await loadMoreData();
                } catch (err) {
                    console.error(err);
                }
            } else {
                try {
                    // setLoadMoreObject(initialState)
                    await loadMoreData();
                } catch (err) {
                    console.error(err);
                }
            }
        })();
        // }
        // eslint-disable-next-line
    }, [loadMoreObject.clickCounter, searchSTR, month, selectedStatus]);

    useEffect(() => {
        // Reset pagination and load data for new search or filter changes
        const resetAndLoadData = async () => {
            setLoadMoreObject({...initialState, clickCounter: 0});
            try {
                await loadMoreData();
            } catch (err) {
                console.error(err);
            }
        };

        resetAndLoadData();
        // eslint-disable-next-line
    }, [searchSTR, month, selectedStatus]);


    const debouncedSearch = debounce(value => setSearchSTR(value), 300);

    const loadMore = !loading ? (

        <Row style={{alignSelf: "center", justifyContent: "center", marginTop: 20}}>

            <Col xs={5}>
                <p style={{textAlign: "center"}}>
                    {ordersList?.length === 0
                        ? <>Nothing to show</>
                        : <>
                            {ordersList?.length} orders on page from {loadMoreObject.totalCount}

                            <Progress
                                strokeColor={'#01a9ac'}
                                percent={Math.round((ordersList?.length / loadMoreObject.totalCount) * 100)}
                                status="active"
                            />
                        </>
                    }
                </p>
            </Col>

            <Col xs={24}>
                <div style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                    // Display the button only if there are more items to load and it's not currently loading
                    display: ordersList.length < loadMoreObject.totalCount && !loading ? 'block' : 'none',
                }}>
                    <Button onClick={buttonClickHandler} loading={loading} disabled={loading}>
                        Load More
                    </Button>
                </div>
            </Col>

        </Row>
    ) : null;


    return <Spin spinning={loading}>

        <Row gutter={10}>

            <Col xs={24}>
                <Input.Search
                    onChange={e => debouncedSearch(e.target.value)}
                    placeholder="Search by order ID, email, tracking number"
                    allowClear
                />
            </Col>

            <Col xs={24} lg={24} xl={24}>
                <Space style={{margin: '20px 0'}}>

                    {searchSTR
                        ? (<Tag>We find <strong>{loadMoreObject.totalCount}</strong> order's in Database</Tag>)
                        : (loadMoreObject.totalCount === null || loadMoreObject.totalCount === 0)
                            ? <Tag>Nothing to show</Tag>
                            : (<Tag>We have <strong>{loadMoreObject.totalCount}</strong> order's in Database</Tag>)
                    }


                    <Divider type={'vertical'}/>

                    <span>Filter orders by status:</span>

                    <Select
                        style={{width: 100}}
                        size={'small'}
                        onChange={onSelectedStatusChange}
                        defaultValue={selectedStatus}
                        options={[
                            {value: ORDERED, label: ORDERED,},
                            {value: SHIPPED, label: SHIPPED,},
                            {value: SHOW_ALL, label: SHOW_ALL,},
                        ]}
                    />

                    <Divider type={'vertical'}/>

                    <span>Filter orders by month:</span>

                    <DatePicker
                        onChange={onChangeMonthHandler}
                        format={'YYYY-MM'}
                        picker="month"
                        inputReadOnly={true}
                        size={'small'}
                    />
                </Space>

                {debugMode && showDebugInfo()}
            </Col>
        </Row>

        <List
            loadMore={loadMore}
            dataSource={ordersList}
            renderItem={order =>
                <List.Item
                    key={order.id}
                    style={{backgroundColor: getBackgroundColor(order.id)}}
                    extra={
                        <Space>
                            <Divider/>
                            <Typography.Paragraph>
                                Total kits in order: {order?.order_kits?.length}
                            </Typography.Paragraph>
                            <Divider/>
                        </Space>
                    }
                >

                    <List.Item.Meta
                        title={<Typography.Title
                            style={{cursor: 'pointer', padding: "10px 10px 10px 10px", margin: 0}}
                            level={5}
                            underline={1}
                        >
                            {order?.isNewClient
                                ? `Paid order | ID: ${order.id} | Customer: ${order?.firstName} ${order?.lastName}`
                                : order?.isProvider
                                    ? `Supply order | ID: ${order.id} | Provider: ${order?.firstName}`
                                    : order?.isWebProvider
                                        ? `Supply order to Web Provider Patient | ID: ${order.id} | Patient: ${order?.firstName} ${order?.lastName}`
                                        : order?.isCorporate
                                            ? "Corporate order"
                                            : "Order"}
                        </Typography.Title>}
                        description={<Space style={{cursor: 'pointer', padding: "0 10px 10px 10px", margin: 0}}>

                            <Button
                                icon={<SolutionOutlined/>}
                                type="primary"
                                size={'small'}
                                onClick={() => {
                                    setSelectedOrder(order);
                                    setIsModalVisible(true);
                                }}
                            >Order details</Button>


                            {/*Order date:*/}
                            {/*{order?.created && <Tag bordered={0} color="grey">*/}
                            {/*    {dayjs(order?.created).format('MM/DD/YYYY')}*/}
                            {/*</Tag>}*/}

                            <Divider type={'vertical'}/>

                            Order status:
                            {order?.status && <Tag bordered={0} color="grey">
                                {order?.status}
                            </Tag>}

                            {/*Tracking number:*/}
                            {/*{order?.tracking_notes ?*/}
                            {/*    <Tag bordered={0} color="grey">*/}
                            {/*        {order?.tracking_notes}*/}
                            {/*    </Tag>*/}
                            {/*    : <Tag bordered={0} color="grey">TBD</Tag>}*/}

                            <Divider type={'vertical'}/>

                            {/*Order source:*/}
                            {order?.isTest && <Tag bordered={0} color="red">test</Tag>}
                            {order?.isNewClient && <Tag bordered={0} color="#01a9ac">efunctional.com</Tag>}
                            {order?.isProvider && <Tag bordered={0} color="black">provider.efunctional.com</Tag>}
                            {order?.isCorporate && <Tag bordered={0} color="black">corporate.efunctional.com</Tag>}
                            {order?.isWebProvider && <Tag bordered={0} color="black">webprovider.efunctional.com</Tag>}
                            <Divider type={'vertical'}/>

                        </Space>}
                    />
                </List.Item>}
        />


        <Modal
            title={<Space>Order details</Space>}
            width={"50%"}
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            destroyOnClose={true}
            footer={null}
            maskClosable={false}
        >
            <OrderDetailsWithAllKitsForShippedStatus
                loading={loading}
                setOrdersList={setOrdersList}
                setSelectedOrder={setSelectedOrder}
                selectedOrder={selectedOrder}
                setIsModalVisible={setIsModalVisible}
            />

        </Modal>

    </Spin>;
};
export default OrdersList;