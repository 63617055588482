import {isValidNPI, phoneNumberMask} from "../../../../../../../../features/validation";
import React, {useEffect, useState} from "react";
import {LoadingOutlined} from "@ant-design/icons";
import {Button, Col, Form, Input, message, Row, Switch, Spin, Checkbox, Divider, Typography} from "antd";
import styled from "styled-components";

import {StatesAutoCompleteUseForm} from "../../../../components/StatesAutoComplete";
import {useNavigate} from "react-router-dom";

import {useAuthAPI} from "../../../../../../../../features/hooks/useAuthAPI";


const NonSelectedForm = styled(Form)`
  user-select: none;

  & * {
    user-select: none
  }
`;

const PersonalInformation = ({clientID, clientInfo, authConfig, clients, setClientInfo}) => {

    const api = useAuthAPI();
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const isPhysicianNetwork = Form.useWatch('is_physician_network', form);

    const [formState, setFormState] = useState(false);  // `Edit mode` handler state
    const [isLoading, setIsLoading] = useState(false);

    const npiValidator = (_, value) => {
        if (!value || isValidNPI(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error("Invalid NPI!"));
    };


    const submitForm = async (formData) => {
        try {
            const updatedData = await api.patch(`/provider-clients/${clientID}/`, formData, authConfig);
            setClientInfo({...updatedData.data});  // update component state

        } catch (error) {
            // todo - print some errors info from server
            console.log(error);
        }
    };

    const onFinish = async (values) => {
        /* Submit valid form */

        try {
            setIsLoading(true);

            await submitForm(values);

            form.resetFields();

            message.success({
                content: `Your information successfully updated!`, duration: 2,
            });

            navigate(`${clients.url}/providers/${clientID}`)

        } catch (error) {

            // todo - print some errors info from server
            console.log(error);

            message.error({
                content: 'We have an errors!', duration: 2,
            });

        } finally {
            setIsLoading(false);
        }
    }

    const triggerSubmitHandler = () => form.submit();

    const onChange = value => {
        setFormState(value);
    }

    useEffect(() => {
        form.setFieldsValue({
            ...clientInfo
        });
        // eslint-disable-next-line
    }, []);


    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>

        <Row>
            <Col>
                <Form>
                    <Form.Item
                        label={'Edit mode: '}
                        name="name"
                    >
                        <Switch checked={formState} onChange={onChange}/>
                    </Form.Item>
                </Form>
            </Col>

            <Divider orientation="right" dashed={true}>
                <Typography.Title level={4}>Main information</Typography.Title>
            </Divider>

            <Col xs={24} lg={24} xl={24}>

                <NonSelectedForm
                    disabled={!formState}
                    layout="vertical"
                    size={'middle'}
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off"
                >

                    <Row gutter={[24, 24]}>
                        <Col lg={8}>
                            <Form.Item name="is_physician_network" valuePropName="checked">
                                <Checkbox>Physician network</Checkbox>
                            </Form.Item>
                        </Col>

                        {!isPhysicianNetwork &&
                            <Col lg={8}>
                                <Form.Item name="is_self_phlebotomy" valuePropName="checked">
                                    <Checkbox>Is self phlebotomy?</Checkbox>
                                </Form.Item>
                            </Col>
                        }

                        {isPhysicianNetwork &&
                            <Col lg={8}>
                                <Form.Item name="be_able_to_see_patients_results" valuePropName="checked">
                                    <Checkbox>Be able to see patients results</Checkbox>
                                </Form.Item>
                            </Col>
                        }

                        {!isPhysicianNetwork &&
                            <Col xs={12} lg={24}>
                                <Form.Item
                                    label={'NPI'}
                                    name="doctor_npi"
                                    rules={[
                                        {required: true, message: 'Please input your NPI!',},
                                        {validator: npiValidator}
                                    ]}
                                >
                                    <Input placeholder="NPI"/>
                                </Form.Item>
                            </Col>
                        }

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label={'Name'}
                                name="name"
                                rules={[
                                    {required: true, message: 'Please input Provider name!',}
                                ]}
                            >
                                <Input placeholder="Provider name"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'Phone'}
                                name="phone"
                                rules={[
                                    {required: true, message: 'Please enter phone',},
                                    {
                                        pattern: /\((\d{3})\)\s(\d{3})-(\d{4})/,
                                        message: 'Please enter valid phone number',
                                    }
                                ]}
                            >
                                <Input
                                    onChange={({target: {value}}) => {

                                        const prettyPhoneNumber = phoneNumberMask(value);
                                        form.setFieldValue('phone', prettyPhoneNumber);
                                    }}

                                    placeholder="Please input phone"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'Email'}
                                name="email"
                                rules={[
                                    {required: true, message: 'Please input email!'},

                                    {
                                        // eslint-disable-next-line
                                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                        message: 'Please enter valid email',
                                    }
                                ]}
                            >
                                <Input placeholder="Email"/>
                            </Form.Item>
                        </Col>


                        <Divider orientation="right" dashed={true}>
                            <Typography.Title level={4}>Address</Typography.Title>
                        </Divider>


                        <Col xs={24} lg={6}>
                            <Form.Item
                                label={'Address'}
                                name="address"
                                rules={[{
                                    required: true, message: 'Please input address!',
                                }]}
                            >
                                <Input placeholder="Address"/>
                            </Form.Item>
                        </Col>


                        <Col xs={10} lg={6}>
                            <Form.Item
                                label={'City'}
                                name="city"
                                rules={[{
                                    required: true, message: 'Please input city!',
                                }]}
                            >
                                <Input placeholder="City"/>
                            </Form.Item>
                        </Col>

                        <Col xs={5} lg={6}>
                            <StatesAutoCompleteUseForm form={form}/>
                        </Col>

                        <Col xs={9} lg={6}>
                            <Form.Item
                                label={'Postal code'}
                                name="zip"

                                rules={[
                                    {required: true, message: 'Please input postal code!',},
                                    // eslint-disable-next-line
                                    {pattern: /^\d{5}$/, message: 'Please enter zip code. We expect 5 numbers',}
                                ]}
                            >
                                <Input placeholder="Postal code"/>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Divider orientation="right" dashed={true}>
                        <Typography.Title level={4}>Billing</Typography.Title>
                    </Divider>

                    <Row>
                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'Billing email'}
                                name="billing_email"
                                rules={[
                                    {required: true, message: 'Please input billing email!'},
                                    {
                                        // eslint-disable-next-line
                                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                        message: 'Please enter valid billing email',
                                    }
                                ]}
                            >
                                <Input placeholder="Email"/>
                            </Form.Item>
                        </Col>

                        <Divider/>

                        <Col span={24}>
                            <Button
                                hidden={!formState}
                                style={{marginBottom: 50,}}
                                type="primary"
                                loading={false}
                                onClick={triggerSubmitHandler}
                            >
                                Update information
                            </Button>
                        </Col>
                    </Row>

                </NonSelectedForm>

            </Col>
        </Row>
    </Spin>
}


export default PersonalInformation;