import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import pages from "../../../../Root/routerConfig";

import {Col, Divider, Row, Breadcrumb, Typography} from "antd";
import {HomeOutlined} from "@ant-design/icons";

import KitsList from "./KitsList";

const PatientsManagement = () => {
    const {kitsManagement} = pages;
    const [patientsList, setPatientsList] = useState([]);


    return <Row justify="center">

        <Col xs={24}>

            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to={'/'}>
                        <HomeOutlined/>&nbsp;
                        Dashboard
                    </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    Kits Management
                </Breadcrumb.Item>
            </Breadcrumb>

            <Typography.Title level={1}>
                Kits Management
            </Typography.Title>
        </Col>

        <Col xs={24}>
            <Divider/>
            <KitsList patientsList={patientsList} setPatientsList={setPatientsList}/>
        </Col>

    </Row>;
}

export default PatientsManagement;