import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PlusOutlined} from '@ant-design/icons';
import {Alert, Button, Col, Drawer, Form, Input, message, Row, Space} from 'antd';

import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";

const initialState = {
    client: null,
    name: null,
}

const NewEmployerEmployeeForm = ({clientInfo, setEmployees}) => {

    const api = useAuthAPI();

    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [submitErrors, setSubmitErrors] = useState(null);

    const triggerSubmitHandler = () => form.submit();

    const submitForm = async () => {
        const {name} = formData;
        await api.post(`/employer-employees/`, {
            client: clientInfo.id,
            name: name.trim(),
        })
            .then(response => {
                setEmployees(prevState => [response.data, ...prevState].map(c => c));
            });
    };

    const key = 'newEmployerEmployee';

    const sendFormWithMessage = () => {
        setOpen(false);
        message.loading({
            content: 'Loading...',
            key,
        });
        setTimeout(() => {
            submitForm()
                .then(response => {

                    setFormData(initialState);
                    form.resetFields();

                    message.success({
                        content: 'Employee created!',
                        key,
                        duration: 2,
                    });
                })
                .catch(err => {

                    if (err.response.status === 401) {
                        navigate('/session-expired');
                    } else {
                        console.log("getClientsList Error", err);
                    }

                    if (err?.response?.data) {
                        setSubmitErrors(err?.response?.data);
                    }


                    if (err?.messages) {
                        setSubmitErrors(err?.messages);
                    }


                    setOpen(true);
                    console.log(err);

                    message.error({
                        content: 'We have an errors!',
                        key,
                        duration: 2,
                    });
                })
        }, 1000);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return <>
        <Button size={'small'} type="primary" onClick={showDrawer} icon={<PlusOutlined/>} disabled>
            Add a new employee
        </Button>

        <Drawer
            title={`Create a new employee for ${clientInfo?.name}`}
            width={window.innerWidth > 1200 ? 780 : '100%'}
            onClose={onClose}
            open={open}
            bodyStyle={{paddingBottom: 80}}
            extra={<Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={triggerSubmitHandler} type="primary">
                    Save
                </Button>
            </Space>}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={sendFormWithMessage}
                autoComplete="off"
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Doctor name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter doctor name',
                                },
                            ]}
                        >
                            <Input
                                onChange={({target: {value}}) => setFormData({...formData, name: value})}
                                placeholder="Please enter doctor name"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {submitErrors && <Row gutter={16}>
                    <Col span={24}>
                        <Alert
                            message="Fill required"
                            // eslint-disable-next-line
                            description={Object.entries(submitErrors).map(([key, value]) => {
                                if (key === "name") return <div>{`Doctor name. ${value}`}</div>;
                                if (key === "message") return <div>{`${value}`}</div>;
                            })}
                            type="error"
                        />
                    </Col>
                </Row>}

            </Form>
        </Drawer>
    </>;
};

export default NewEmployerEmployeeForm;