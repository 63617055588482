import {useAuthAPI} from "../../../../../../../features/hooks/useAuthAPI";
import React, {useState, useEffect} from 'react';

import {PlusOutlined} from '@ant-design/icons';
import {Button, Col, Drawer, Form, message, Row, Select, Space, Badge, Tag, Divider, Typography} from 'antd';

import Employer from "./Employer";
import Provider from "./Provider";
import WebProvider from "./WebProvider";

const portals = {
    PROVIDER_PORTAL: "provider.efunctional.com",
    EMPLOYER_PORTAL: "corporate.efunctional.com",
    WEB_PROVIDER_PORTAL: "webprovider.efunctional.com"
}

const {
    PROVIDER_PORTAL,
    EMPLOYER_PORTAL,
    WEB_PROVIDER_PORTAL
} = portals;


// Prepare clientTypes object for render different forms
const clientTypes = {
    PROVIDER: {
        label: 'Provider',
        value: 'provider',
        badge: null,
        description: <Typography.Text type={'secondary'}>
            Makes a free supply order. Sold the kit and then pay. <Tag>{PROVIDER_PORTAL}</Tag>
        </Typography.Text>,
        component: Provider
    },
    EMPLOYER: {
        label: 'Employer',
        value: 'employer',
        badge: null,
        description: <Typography.Text type={'secondary'}>
            Signed up the Contract and game on! <Tag>{EMPLOYER_PORTAL}</Tag>
        </Typography.Text>,
        component: Employer
    },
    WEB_PROVIDER: {
        label: 'Web Provider',
        value: 'web_provider',
        badge: true,
        description: <Typography.Text type={'secondary'}>
            Makes order and immediately pays. <Tag>{WEB_PROVIDER_PORTAL}</Tag>
        </Typography.Text>,
        component: WebProvider
    }
};

// Filter the clientTypes object to get an array of objects with only the label and value properties
const selectOptions = Object.values(clientTypes).map(type => ({
    label: type.label,
    value: type.value,
    badge: type.badge,
    description: type.description,
}));

const {Option} = Select;

const NewClientForm = ({setClientsList}) => {
    const api = useAuthAPI();
    const [form] = Form.useForm();


    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(null);

    const [salesGroups, setSalesGroups] = useState([]);
    const [clientTYPE, setClientTYPE] = useState(null);

    /* This approach utilizes a mapping of component types and then conditionally renders. toUpperCase() important */
    const CurrentComponent = clientTYPE && clientTypes[clientTYPE.toUpperCase()]?.component;

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const loadSalesGroups = async () =>
        await api.get(`/sales-groups/`)
            .then(response => {
                setIsLoading(true);
                setSalesGroups(response?.data?.results.map(salesGroup => ({
                    label: `id: ${salesGroup.id} | ${salesGroup.shortname}`,
                    value: salesGroup.id
                })));
            })
            .catch(err => console.log(err));

    useEffect(() => {
        (async () => {
            try {
                await loadSalesGroups();
                setIsLoading(false);
            } catch (err) {
                console.log(err);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const triggerSubmitHandler = () => form.submit();

    const submitForm = async () => {

        // Map client types to their respective URLs
        const urlMap = {
            [clientTypes.PROVIDER.value]: '/provider-clients/create-new-with-default-location/',
            [clientTypes.WEB_PROVIDER.value]: '/web-provider-clients/create-new-web-provider/',
            [clientTypes.EMPLOYER.value]: '/employer-clients/',

        };

        // Get the save URL for the given client type
        const saveURL = urlMap[clientTYPE];
        if (!saveURL) {
            message.error("Invalid client type:", clientTYPE);
            return false;
        }

        // Calculate booleans once
        const is_provider = clientTYPE === clientTypes.PROVIDER.value;
        const is_web_provider = clientTYPE === clientTypes.WEB_PROVIDER.value;
        const is_employer = clientTYPE === clientTypes.EMPLOYER.value;

        // Prepare the data to send
        const updatedFormData = {
            ...formData,
            user: {
                username: formData?.email,
                email: formData?.email,
            },
            is_provider,
            is_web_provider,
            is_employer,
        };

        try {
            const response = await api.post(saveURL, updatedFormData);

            setClientsList(prevState => [
                {...response.data, is_provider, is_employer, is_web_provider},
                ...prevState
            ]);
        } catch (error) {
            // More descriptive error handling
            console.error("Failed to submit form. Error:", error);
            message.error("Failed to submit form. Error:", error);
        }
    };


    const key = 'newClient';

    const sendFormWithMessage = () => {
        setOpen(false);
        message?.loading({
            content: 'Loading...',
            key,
        });
        setTimeout(() => {
            submitForm()
                .then(() => {
                    setFormData(null);
                    form.resetFields();
                    message?.success({
                        content: 'Client created!',
                        key,
                        duration: 2,
                    });
                })
                .catch(() => {
                    message?.error({
                        content: 'We have an errors!',
                        key,
                        duration: 2,
                    });
                })
        }, 1000);
    };


    return <>

        <Row justify={'space-between'}>
            <Col span={20}>
                <Space>
                    <Button size={'small'} type="primary" onClick={showDrawer} icon={<PlusOutlined/>}>
                        New client
                    </Button>
                </Space>
            </Col>
        </Row>

        <Drawer
            title="Create a new Efunctional client"
            width={window.innerWidth > 1200 ? 780 : '100%'}
            onClose={onClose}
            open={open}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={<Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={triggerSubmitHandler} type="primary">
                    Submit
                </Button>
            </Space>}
        >
            <Form layout="vertical" form={form} onFinish={sendFormWithMessage} autoComplete="off">

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name={'client_type'}
                            label="Client type"
                            rules={[{required: true, message: 'Please choose client type'}]}
                        >
                            <Select
                                disabled={isLoading}
                                placeholder="Please choose client type"
                                style={{width: '100%'}}
                                onChange={setClientTYPE}
                            >
                                {selectOptions.map(option => (
                                    <Option key={option.value} value={option.value}>
                                        <Badge>
                                            <Space>
                                                {option.label}
                                                {option?.badge ? <Tag color={'#01a9ac'}>new</Tag> : null}
                                                <Divider type={'vertical'}/>
                                                {option.description}
                                            </Space>
                                        </Badge>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="sales_group"
                            label="Sales group"
                            rules={[{
                                required: true,
                                message: 'Please choose sales group',
                            }]}
                        >
                            <Select
                                disabled={isLoading}
                                placeholder="Please choose sales group"
                                style={{width: '100%'}} options={salesGroups}
                                onChange={value => setFormData({...formData, sales_group: value})}
                            />
                        </Form.Item>
                    </Col>
                </Row>


                {/*This approach utilizes a mapping of component types and then conditionally renders */}
                {/*the appropriate component based on the clientTYPE value. */}
                {/*The result is cleaner, more maintainable code.*/}
                {CurrentComponent &&
                    <CurrentComponent
                        key={clientTYPE}
                        formData={formData}
                        setFormData={setFormData}
                        form={form}
                    />
                }

            </Form>
        </Drawer>
    </>;
};

export default NewClientForm;