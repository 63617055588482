import React, {useEffect, useState} from "react";
import dayjs from "dayjs";

import {NavLink} from "react-router-dom";
import {CalendarOutlined, CloseOutlined, HomeOutlined, ProfileOutlined, SolutionOutlined} from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Divider,
    Drawer,
    List,
    Modal,
    Row,
    Skeleton,
    Space,
    Spin, Tag,
    Typography
} from "antd";

import NewProviderOrderForm from "./NewProviderOrderForm";
import {useAuthAPI} from "../../../../../../../../features/hooks/useAuthAPI";
import OrderDetailsWithAllKitsForShippedStatus
    from "../../../SuppliesOrderListEfunctional/OrderDetailsWithAllKitsForShippedStatus";
import NewInfluencersOrderForm from "./NewInfluencersOrderForm";
import NewPromoMaterialsOrderForm from "./NewPromoMaterialsOrderForm";
import NewProviderPromoKitsOrderForm from "./NewProviderPromoKitsOrderForm";


const Orders = ({clientID, clientInfo, authConfig, currentOrderType}) => {
    const api = useAuthAPI();

    const [isLoading, setIsLoading] = useState(true);
    const [ordersList, setOrdersList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState([]);

    // eslint-disable-next-line
    const [month, setMonth] = useState(null);


    // drawer state
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isDrawerOpened, setIsDrawerOpened] = useState(false);

    const showKitsListForCurrentOrder = currentOrder => {
        setSelectedOrder(currentOrder);
        setIsDrawerOpened(true);
    }

    const closeDrawerHandler = () => {
        setIsDrawerOpened(false);
        setSelectedOrder(null);
    }

    const onChangeMonthHandler = value => {
        const currentDate = dayjs(value);

        if (currentDate.isValid()) {
            const startDate = currentDate.startOf('month').format('YYYY-MM-DD');
            const endDate = currentDate.endOf('month').format('YYYY-MM-DD');

            setMonth({startDate, endDate});
            return;
        }
        setMonth(null);
    }

    const loadAllProviderOrders = async () => {
        try {
            if (!currentOrderType || !clientID) {
                console.log("Required data not available");
                return;
            }

            // Define base URLs for each order type
            const baseUrls = {
                webProvider: `/orders/web-provider/?web_provider=${clientID}`,
                provider: `/orders/provider/?provider=${clientID}`,
                // Add more URLs as needed
            };

            // Select the base URL based on the order type
            // Build the rest of the URL
            let requestURL = baseUrls[currentOrderType];
            if (month) {
                requestURL += `&created__gte=${month.startDate}`;
                requestURL += `&created__lte=${month.endDate}`;
            }
            requestURL += `&ordering=-created`;

            const response = await api.get(requestURL, authConfig);

            setOrdersList(response?.data?.results);
            setIsLoading(false);

        } catch (error) {
            console.error("Error in loadAllProviderOrders:", error);
        }
    };

    useEffect(() => {
        (async () => await loadAllProviderOrders())()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month, currentOrderType]);


    return <Spin spinning={isLoading}>

        <Row>
            <Col span={24}>

                <Typography.Title>
                    {clientInfo?.name} | Orders
                </Typography.Title>

                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to={'/'}>
                            <HomeOutlined/>&nbsp;
                            Dashboard
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/clients`}>
                            Clients
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/clients/providers/${clientID}`}>
                            {clientInfo?.name}
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Orders</Breadcrumb.Item>
                </Breadcrumb>

                <Divider/>
            </Col>
        </Row>


        {/*Orders from Provider portal*/}

        <Drawer
            closeIcon={<CloseOutlined/>}

            // width={window.innerWidth > 1200 ? '80%' : '92%'}
            // closable={window.innerWidth <= 1200}
            onClose={closeDrawerHandler}
            open={isDrawerOpened}
            title={`Kits list for orderID: ${selectedOrder?.id}`}
        >
            <p>
                <strong>Total kits:</strong> {selectedOrder?.order_kits.length}
            </p>
            <Divider/>

            {selectedOrder?.order_kits.map(kit => <>
                <div>Kit barcode: {kit.barcode}</div>
                <div>Status: {kit.status}</div>
                <div>Test name: {kit?.orderItem?.product?.name}</div>
                <Divider/>
            </>)}
        </Drawer>

        <Row style={{padding: "0 10px"}}>

            {/*<Col xs={24} lg={24} xl={24}>*/}
            {/*    <MainPageTitle>{history.title}</MainPageTitle>*/}
            {/*</Col>*/}


            <Col xs={24} lg={24} xl={24}>
                <Space style={{margin: '0 0 20px 0'}}>

                    {currentOrderType === 'provider' && (
                        <Space>
                            <NewProviderOrderForm
                                currentOrderType={currentOrderType}
                                authConfig={authConfig}
                                clientInfo={clientInfo}
                            />

                            <NewPromoMaterialsOrderForm
                                currentOrderType={currentOrderType}
                                authConfig={authConfig}
                                clientInfo={clientInfo}
                            />
                        </Space>
                    )}


                    {currentOrderType === 'webProvider' && clientID === "1199" && (
                        <Space>
                            <NewInfluencersOrderForm
                                currentOrderType={currentOrderType}
                                authConfig={authConfig}
                                clientInfo={clientInfo}
                            />
                        </Space>
                    )}


                    <NewProviderPromoKitsOrderForm
                        currentOrderType={currentOrderType}
                        authConfig={authConfig}
                        clientInfo={clientInfo}
                    />


                    <span>Filter orders by month:</span>

                    <DatePicker
                        // defaultValue={dayjs()}
                        onChange={onChangeMonthHandler}
                        format={'YYYY-MM'}
                        picker="month"
                        inputReadOnly={true}
                        size={'small'}
                    />

                </Space>

            </Col>

            <Col xs={24} lg={24}>

                <List
                    className="demo-loadmore-list"
                    loading={isLoading}
                    itemLayout="horizontal"
                    dataSource={ordersList}
                    renderItem={(orderItem) => (<List.Item
                        key={orderItem?.id}
                        actions={[

                            // <p key={`list-created-date-${orderItem?.id}`}>
                            //     <CalendarOutlined/> {dayjs(orderItem?.created).format('MM-DD-YYYY')}
                            // </p>,
                            //
                            // <Button
                            //     key={`show-kits-list-for-order-${orderItem?.id}`}
                            //     onClick={() => showKitsListForCurrentOrder(orderItem)}
                            // >
                            //     Kits list
                            // </Button>,


                        ]}
                    >
                        <Skeleton avatar title={false} loading={isLoading} active>
                            <List.Item.Meta
                                title={<span>Order ID: {orderItem?.id}</span>}
                                description={
                                    <Space>
                                        <Button
                                            icon={<SolutionOutlined/>}
                                            type="dashed"
                                            size={'small'}
                                            onClick={() => {
                                                setSelectedOrder(orderItem);
                                                setIsModalVisible(true);
                                            }}
                                        >Order details</Button>

                                        {/*<Typography.Text>*/}
                                        {/*    <Tag color="blue">{orderItem?.status}</Tag>*/}
                                        {/*</Typography.Text>*/}

                                        <Divider type="vertical"/>

                                        <Typography.Text>
                                            <Tag bordered={false}>Status:</Tag>{orderItem?.status}
                                        </Typography.Text>

                                        <Divider type="vertical"/>

                                        <Typography.Text>
                                            <Tag bordered={false}>Order
                                                receiver:</Tag>{orderItem?.firstName} {orderItem?.lastName}
                                        </Typography.Text>

                                        <Divider type="vertical"/>

                                        <Typography.Text>
                                            <Tag bordered={false}>Notes:</Tag>{orderItem?.metadata?.notes}
                                        </Typography.Text>
                                    </Space>}
                            />
                        </Skeleton>
                    </List.Item>)}
                />
            </Col>
        </Row>


        {selectedOrder && (
            <Modal
                title={<Space>Order details</Space>}
                width={"50%"}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                destroyOnClose={true}
                footer={null}
                maskClosable={false}
            >
                <OrderDetailsWithAllKitsForShippedStatus
                    loading={isLoading}
                    setOrdersList={setOrdersList}
                    setSelectedOrder={setSelectedOrder}
                    selectedOrder={selectedOrder}
                    setIsModalVisible={setIsModalVisible}
                />
            </Modal>
        )}
    </Spin>;
}

export default Orders;