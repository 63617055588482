import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import {List, Space, Button, Spin, Row, Col, Input, Tag, Progress} from 'antd';
import {PhoneOutlined, MailOutlined, UserAddOutlined} from '@ant-design/icons';
import SalesPersonDeleteConfirmModal from "./SalesPersonDeleteConfirmModal";
import SalesPersonEditModal from "./SalesPersonEditModal";


const SalePersonsList = ({salesList, setSalesList, userTokens}) => {
    const {Search} = Input;
    const [searchSTR, setSearchSTR] = useState(false);
    const [loading, setLoading] = useState(false);

    const api = useAuthAPI();

    const [loadMoreObject, setLoadMoreObject] = useState({
        nextURL: null,
        totalCount: null,
        clickCounter: 0,
    });

    const onSearch = async value => {
        /* Update state when user change searchSTR after clicking on Search btn*/
        if (searchSTR !== value) {
            setSearchSTR(value);

            setLoadMoreObject({
                ...loadMoreObject,
                clickCounter: 0,
                nextURL: null,
            });
        }
    };

    const loadMoreData = async () => {
        let apiURL = '/sales-persons/';  // default base url for all clients
        if (loading) {
            return;
        }

        // check a default case when search input don't touched and user clicked load more btn
        if (loadMoreObject.nextURL) {
            apiURL = loadMoreObject.nextURL;
        }

        // check only search input and update all client list with searchSTR
        if (searchSTR && searchSTR.length !== 0) {
            apiURL = `/sales-persons/?search=${searchSTR}`;
        }

        // if search used, and we have a new clicks from user to load more btn
        if (searchSTR && searchSTR.length !== 0 && loadMoreObject.nextURL) {
            apiURL = loadMoreObject.nextURL;
        }

        setLoading(true);

        await api.get(`${apiURL}`)
            .then(response => {

                if (loadMoreObject.clickCounter === 0) {
                    setSalesList(response?.data?.results);
                } else {
                    setSalesList(prevState => [...prevState, ...response?.data?.results]);
                }

                setLoadMoreObject({
                    ...loadMoreObject,
                    nextURL: response?.data?.next,
                    totalCount: response?.data?.count,
                });


            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const buttonClickHandler = () => {
        setLoadMoreObject({...loadMoreObject, clickCounter: loadMoreObject.clickCounter + 1});
    }

    const loadMore = !loading ? (
        <Row style={{alignSelf: "center", justifyContent: "center"}}>

            <Col xs={5}>
                <p style={{textAlign: "center"}}>
                    {salesList.length} sales person's on page from {loadMoreObject.totalCount}
                </p>
                <Progress
                    strokeColor={'#01a9ac'}
                    percent={Math.round((salesList.length / loadMoreObject.totalCount) * 100)}
                    status="active"
                />
            </Col>

            <Col xs={24}>
                <div style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                    display: (loadMoreObject.clickCounter === 0 && !loadMoreObject.nextURL) || (salesList.length === loadMoreObject.totalCount) ? 'none' : 'block',
                }}>
                    <Button onClick={buttonClickHandler} loading={loading}>loading more</Button>
                </div>
            </Col>
        </Row>

    ) : null;

    useEffect(() => {
        if (salesList.length <= loadMoreObject.totalCount) {
            (async () => {
                try {
                    await loadMoreData();
                } catch (err) {
                    console.error(err);
                }
            })();
        }

        // eslint-disable-next-line
    }, [loadMoreObject.clickCounter, searchSTR]);


    return <Spin tip="Loading..." spinning={loading}>

        <Row gutter={10}>

            <Col xs={24} lg={5}>
                <Search
                    enterButton
                    allowClear
                    size={'small'}
                    placeholder="Search client (id, name)"
                    onSearch={onSearch}
                    style={{width: "100%",}}
                />

                {/*<hr/>*/}

                {/*<h1>DEBUG</h1>*/}
                {/*<p>*/}
                {/*    <strong>Total count:</strong> {loadMoreObject.totalCount}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    <strong>Next URL:</strong> {loadMoreObject.nextURL}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    <strong>Next searchURL:</strong> {loadMoreObject.nextSearchURL}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    <strong>Click counter:</strong> {loadMoreObject.clickCounter}*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*    Clients on page: {salesList.length}*/}
                {/*</p>*/}
                {/*<hr/>*/}
            </Col>

            <Col xs={24} lg={5}>
                {searchSTR ? (
                    <Tag>We find <strong>{loadMoreObject.totalCount}</strong> sales person's in Database</Tag>
                ) : (
                    <Tag>We have <strong>{loadMoreObject.totalCount}</strong> sales person's in Database</Tag>
                )}
            </Col>
        </Row>


        <List
            dataSource={salesList}
            loadMore={loadMore}
            renderItem={salesPerson => (
                <List.Item key={salesPerson.id} extra={<Space>

                    {salesPerson?.email && <Button type={'text'} size={'small'}>
                        <MailOutlined/>{salesPerson?.email}
                    </Button>}

                    {salesPerson?.phone && <Button type={'text'} size={'small'}>
                        <PhoneOutlined/>{salesPerson?.phone}
                    </Button>}

                    <SalesPersonEditModal
                        {...salesPerson}
                        salesList={salesList}
                        userTokens={userTokens}
                        setSalesList={setSalesList}
                    />

                    <SalesPersonDeleteConfirmModal
                        {...salesPerson}
                        userTokens={userTokens}
                        setSalesList={setSalesList}
                    />

                </Space>}>
                    <List.Item.Meta
                        avatar={<UserAddOutlined style={{fontSize: 35, marginTop: 7}}/>}
                        title={<NavLink to={`/sales/${salesPerson.id}`}>
                            {`${salesPerson.first_name} ${salesPerson.last_name}`.toUpperCase()}
                        </NavLink>}

                        description={<Space>Sales person</Space>}
                    />
                </List.Item>)}
        />
    </Spin>
};

export default SalePersonsList;