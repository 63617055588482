export const phoneNumberMask = value => {
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return (value = !x[2]
        ? x[1]
        : `(${x[1]}) ${x[2]}${x[3] ? "-" + x[3] : ""}`);
}

export const isValidNPI = npi => {
    /* Validates an NPI using both the pattern and Luhn algorithm check */

    const regex = /^\d{10}$/;

    if (!regex.test(npi)) {
        return false;
    }

    let sum = 24;
    for (let i = 0; i < npi.length - 1; i++) {
        let digit = parseInt(npi[i], 10);

        if (i % 2 === 0) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }

        sum += digit;
    }

    const checkDigit = (10 - (sum % 10)) % 10;

    return parseInt(npi[9], 10) === checkDigit;
}