import React, {useState} from 'react';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import {Button, Flex, Spin, Modal, Form, Input, message} from "antd";


const ChangeThePassword = ({authUserId}) => {
    const api = useAuthAPI();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});

    const onFinish = async (values) => {
        setFormData(values);

        const data = {
            id: authUserId,
            password: values.password,
        }

        try {
            await api.post(`/bo-change-password/`, data);
            // Close the modal on successful password change
            setOpen(false);
            message.success('Password changed successfully');
        } catch (error) {
            console.error('Error changing password: ', error);
            // Handle the error appropriately in your application
        }
    };

    return (
        <Spin spinning={loading}>
            <Flex wrap="wrap" gap="small">
                <Button type="primary" size={'small'} onClick={showModal}>
                    Change a password
                </Button>
            </Flex>

            <Modal
                title="Change a password"
                open={open}
                onOk={() => form.submit()}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="passwordForm"
                    onFinish={onFinish}
                    scrollToFirstError
                    variant={'filled'}
                >
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            {
                                min: 8,
                                message: 'Password must be at least 8 characters long!',
                            },
                            {
                                pattern: /[^A-Za-z0-9]/,
                                message: 'Password must include at least one special character! (@,#, $, %, ^, &, *)',
                            },
                            {
                                pattern: /\d/,
                                message: 'Password must include at least one number!',
                            },
                            {
                                validator: (_, value) => {
                                    if (value && value.includes(' ')) {
                                        return Promise.reject(new Error('Password cannot contain spaces'));
                                    }
                                    return Promise.resolve();
                                },
                                message: 'Password cannot contain spaces',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </Spin>
    );

}

export default ChangeThePassword;