import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {Route, Routes} from 'react-router-dom';
import IndexPage from "./NestedPages/IndexPage";  // first rendered page for this component
import Inventory from "./NestedPages/Inventory";
import Orders from "./NestedPages/Orders";
import Billing from "./NestedPages/Billing";
import ManageProviderInformation from "./NestedPages/ManageProviderInformation";
import Referrals from "./NestedPages/Referrals";
import Locations from "./NestedPages/Locations";
import Logs from "./NestedPages/Logs";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";


const orderTypes = {
    webProvider: 'is_web_provider',
    provider: 'is_provider',
    employer: 'is_employer'
};


const checkOrderType = (orderData) => {
    console.log("Received orderData:", orderData);
    for (const type in orderTypes) {
        const key = orderTypes[type];
        if (orderData.hasOwnProperty(key) && orderData[key] === true) {
            console.log("Match found:", type);
            return type;
        }
    }
    console.log("No match found, returning false");
    return false;
};


const ProviderDetails = () => {
    /* Provider management component with own routing */
    const api = useAuthAPI();
    const {clientID} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [clientInfo, setClientInfo] = useState(null);
    const [currentOrderType, setCurrentOrderType] = useState(null);


    // Create prop's object for nested components
    const props = {
        clientID,
        isLoading,
        clientInfo,
        setClientInfo,
        currentOrderType,
    };


    const getClientDetailsByID = async () => {
        /* Get current Provider Client by ID */
        try {
            setIsLoading(true);
            // const response = await api.get(`/provider-clients/${clientID}`);
            const response = await api.get(`/all-clients/${clientID}`);
            setClientInfo(response.data);
        } catch (error) {
            // TODO check and display errors
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        if (clientInfo === null) {
            (async () => {
                try {
                    await getClientDetailsByID();
                } catch (err) {
                    console.log(err); // todo - handle error
                }
            })();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (clientInfo) {
            setCurrentOrderType(checkOrderType(clientInfo));
        }
    }, [clientInfo]);

    return <Routes>
        <Route index element={<IndexPage {...props}/>}/>
        <Route path="inventory" element={<Inventory {...props}/>}/>
        <Route path="orders" element={<Orders {...props}/>}/>
        <Route path="billing" element={<Billing {...props}/>}/>
        <Route path="manage-info" element={<ManageProviderInformation {...props}/>}/>
        <Route path="referrals" element={<Referrals {...props}/>}/>
        <Route path="locations" element={<Locations {...props}/>}/>
        <Route path="logs" element={<Logs {...props}/>}/>
        <Route path="*" element={<> 404 </>}/>
    </Routes>;
}

export default ProviderDetails;