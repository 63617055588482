import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {PlusOutlined, MinusOutlined, CheckSquareOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Drawer, Form, message, Row, Select, Space, Spin, Tag, InputNumber} from 'antd';
import {useAuthAPI} from "../../../../../../../../features/hooks/useAuthAPI";


// todo - when we create a new order - we don't re-render a new order to Orders List. Fix it and provide solution
const NewProviderPromoKitsOrderForm = ({clientInfo, authConfig, currentOrderType}) => {
    const key = 'NewProviderPromoKitsOrderForm';

    const api = useAuthAPI();
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [submitErrors, setSubmitErrors] = useState(null);

    // new order state values
    const [isLoading, setIsLoading] = useState(true);
    const [locationsList, setLocationsList] = useState(null);
    const [locationsListOptions, setLocationsListOptions] = useState(null);
    const [chosenLocation, setChosenLocation] = useState(null);
    const [orderList, setOrderList] = useState([]);


    const [shoppingCart, setShoppingCart] = useState([]);

    const triggerSubmitHandler = () => form.submit();

    const submitForm = async () => {

        // check shopping cart, if - 0 -> stop
        if (shoppingCart.length === 0) {
            // setIsClicked(false);
            return false;
        }

        try {
            setIsLoading(true);

            // Prepare cart data for backend saving
            const cart = shoppingCart.map(
                p => ({
                    product: parseInt(p.id),
                    quantity: p.qty,
                })
            );

            // Save needed address data fro current order
            const currentLocation = locationsList.find(
                location => location.id === chosenLocation
            );


            /*
            API URL: /orders/promo-kits-order/

            {
                "cart": [{"product": 2, "quantity": 1,}, {"product": 1, "quantity": 1,}],
                "client": 1030,
                "name": "Dr House (Test Provider)",
                "email": "zelenchuk.serhii@gmail.com",
                "phone": "(929) 888-9999",
                "firstName": "Serhii",
                "lastName": "Zelenchuk",
                "address_1": "Fake Address 3000 Ocean Parkway",
                "address_2": "14U",
                "city": "Brooklyn",
                "state": "NY",
                "postcode": "11235",
                "isExpeditedShipping": false
            }
            */

            const orderData = {
                cart: [...cart],  // all products with qty
                client: clientInfo?.id, // any client type id
                name: clientInfo?.name,

                email: currentLocation?.location_manager_contact_email || clientInfo?.email,
                phone: currentLocation?.location_manager_contact_phone || clientInfo?.phone,
                firstName: currentLocation?.location_manager_first_name || clientInfo?.name,
                lastName: currentLocation?.location_manager_last_name || clientInfo?.id,

                address_1: currentLocation?.address_1,
                address_2: currentLocation?.address_2 || '',
                city: currentLocation?.city,
                state: currentLocation?.state,
                postcode: currentLocation?.postcode,

                isExpeditedShipping: false, // TODO don't showing on frontend
            };

            // console.log('orderData', orderData)
            //
            // return

            // return
            await api.post(`/orders/promo-kits-order/`, orderData, authConfig);

            form.resetFields();
            resetCart();

            message?.success({
                content: 'Your order was successfully generated.', key, duration: 3,
            });


            setOpen(false);

        } catch (error) {

            if (error?.response?.data) {
                setSubmitErrors(error?.response?.data);
            }

            if (error?.messages) {
                setSubmitErrors(error?.messages);
            }

            setOpen(true);
            console.log(error);

            message?.error({
                content: `We have an errors! ${submitErrors}`, key, duration: 2,
            });
        } finally {
            setIsLoading(false);
        }
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };


    const loadProducts = async () => {

        try {
            setIsLoading(true);

            const response = await api.get('/products/', authConfig);

            setOrderList([...response?.data?.results]);

        } catch (error) {

            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }


    const loadAllProviderLocations = async () => {

        try {
            setIsLoading(true);

            const response = await api.get(`/provider-locations/?provider_id=${clientInfo?.id}`);

            setLocationsList(response?.data?.results); // save all locations data in a list

            // prepare options for select
            setLocationsListOptions(
                response?.data?.results.map(location => ({
                    label: `${location?.state}, ${location?.city}, ${location?.postcode}, ${location?.address_1}  ${location?.address_2 || ''}`,
                    value: location.id
                }))
            );

        } catch (error) {

            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        if (clientInfo) {
            (async () => {
                try {
                    await Promise.all([
                        loadAllProviderLocations(),
                        loadProducts(),
                    ]);
                } catch (error) {
                    console.error('Error loading data:', error);
                    // Handle the error appropriately in your application
                }
            })();
        }
        // eslint-disable-next-line
    }, [clientInfo]);


    const addToCart = product => {
        const exist = shoppingCart.find((x) => x.id === product.id);

        if (exist) {
            updateProductQty(product, exist.qty + 1);
        } else {
            setShoppingCart([...shoppingCart, {...product, qty: 1}]);
        }
    }

    const removeFromCart = product => {
        const exist = shoppingCart.find((x) => x.id === product.id);

        if (exist) {
            if (exist.qty === 1) {
                setShoppingCart(shoppingCart.filter((x) => x.id !== product.id));
            } else {
                setShoppingCart(shoppingCart.map((x) => x.id === product.id ? {...exist, qty: exist.qty - 1} : x));
            }
        }
    }

    const resetCart = () => {
        setShoppingCart([]);
    }


    const getProductQty = p => {
        const currentProduct = shoppingCart?.find(x => x.id === p.id);

        if (currentProduct) {
            return currentProduct.qty;
        } else {
            return 0;
        }
    }

    const updateProductQty = (product, qty) => {
        const exist = shoppingCart.find((x) => x.id === product.id);

        if (exist) {
            setShoppingCart(shoppingCart.map(x => x.id === product.id ? {...exist, qty: qty} : x));
        } else if (qty > 0) {
            setShoppingCart([...shoppingCart, {...product, qty: qty}]);
        }
    }

    return <>
        <Row justify={'space-between'}>
            <Col span={20}>
                <Space>
                    <Button
                        // we show this button only for the Provider clients
                        hidden={currentOrderType !== 'provider'}
                        size={'small'} type="dashed" onClick={showDrawer} icon={<CheckSquareOutlined/>}>
                        New promo kits order
                    </Button>
                </Space>
            </Col>
        </Row>

        <Drawer
            title={<h4>New promo kits order</h4>}
            width={window.innerWidth > 1200 ? '45%' : '100%'}
            onClose={onClose}
            open={open}
            bodyStyle={{paddingBottom: 80}}
            extra={<Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={triggerSubmitHandler} type="primary">
                    Save order
                </Button>
            </Space>}
        >
            <Spin spinning={isLoading}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={submitForm}
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name={'location'}
                                label="Existing locations"
                                rules={[{required: true, message: 'Please choose location for order'}]}
                            >
                                <Select
                                    disabled={isLoading}
                                    placeholder="Please choose location for order"
                                    style={{width: '100%'}}
                                    options={locationsListOptions}
                                    onChange={setChosenLocation}
                                    value={chosenLocation}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider/>

                    {chosenLocation !== null && orderList?.map(product =>
                        <Row key={product.id}>
                            <Col span={10} xs={16} lg={14}>
                                <strong style={{fontSize: '12px'}}>
                                    {product.name} ({product.code})
                                </strong>
                            </Col>

                            <Col span={5} xs={0} lg={2}>
                                $0.00
                            </Col>

                            <Col span={12} xs={6} lg={8}>
                                <Space size={'large'}>
                                    <Button onClick={() => removeFromCart(product)} icon={<MinusOutlined/>}/>
                                    <InputNumber
                                        style={{width: '65px'}}
                                        min={0}
                                        max={99}
                                        value={getProductQty(product)}
                                        onChange={(value) => updateProductQty(product, value)}
                                    />
                                    <Button icon={<PlusOutlined/>} onClick={() => addToCart(product)}/>
                                </Space>
                            </Col>

                            <Divider/>
                        </Row>
                    )}

                    {shoppingCart.length !== 0 &&
                        <Row>
                            <Col span={16}>
                                <h2>
                                    Total: ${shoppingCart.reduce((a, c) => a + c.qty * c.price, 0)}
                                </h2>
                            </Col>

                            <Col span={2}>
                                <Button size={'small'} onClick={() => resetCart()}>
                                    Reset order
                                </Button>
                            </Col>
                        </Row>
                    }
                </Form>
            </Spin>
        </Drawer>
    </>;
};

export default NewProviderPromoKitsOrderForm;