import styled from "styled-components";
import React from 'react';
import {NavLink} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";
import {Row, Col, Divider, Breadcrumb, Space, Typography, Statistic, Spin, Descriptions, Card} from "antd";
import ChangeTheUsername from "./ChangeTheUsername";
import ChangeThePassword from "./ChangeThePassword";


const CustomCard = styled(Card)`
    //cursor: pointer;
    user-select: none;
    text-align: center;
    border-width: 2px;
    border-color: #01a9ac;
    //background: rgba(1, 169, 172, 0.06);
    background: linear-gradient(to right, rgba(1, 169, 172, 0.12), rgba(1, 219, 223, 0.11));

    & .anticon {
        font-size: 3.5em;
        margin: 0 0 20px 0;
        //color: #01a9ac;
    }

    & .ant-typography {
        //color: #01a9ac;
    }

    &:hover {
        background: linear-gradient(to right, #01a9ac, #01dbdf);

        & .ant-typography {
            color: #fff;
        }

        & .anticon {
            color: #fff;
        }
    }
`

const StyledDescriptions = styled(Descriptions)`
    //background-color: #f5f5f5;
    background: linear-gradient(to right, rgba(1, 169, 172, 0.12), rgba(1, 219, 223, 0.11));
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    .ant-descriptions-header {
        margin-bottom: 16px;
        color: #01a9ac;
    }

    .ant-descriptions-item-label {
        font-weight: bold;
        color: #01a9ac;
    }

    .ant-descriptions-item-content {
        color: #333;
    }

    @media (max-width: 768px) {
        .ant-descriptions-item-label,
        .ant-descriptions-item-content {
            display: block;
            color: #333;
        }
    }
`;


const SalePersonDetailPageIndex = ({salesPersonData, isLoading, salePersonID}) => <Spin spinning={isLoading}>
    <Row justify="center">
        <Col xs={24}>

            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to={'/'}>
                        <HomeOutlined/>&nbsp;
                        Dashboard
                    </NavLink>
                </Breadcrumb.Item>
            </Breadcrumb>

            <Typography.Title level={1}>
                Dashboard
            </Typography.Title>

            <Space>
                <ChangeTheUsername
                    authUserId={salesPersonData?.user_details?.id}
                    username={salesPersonData?.user_details?.username}
                />
                <ChangeThePassword authUserId={salesPersonData?.user_details?.id}/>
            </Space>
        </Col>

        <Divider/>

        <Col xs={24}>
            <StyledDescriptions layout="vertical" bordered size={"small"}>

                <Descriptions.Item label="Full Name">
                    {salesPersonData?.first_name} {salesPersonData?.last_name}
                </Descriptions.Item>

                <Descriptions.Item label="Phone">
                    <a style={{color: "#01a9ac"}} href={`tel:${salesPersonData?.phone}`}>
                        {salesPersonData?.phone}
                    </a>
                </Descriptions.Item>

                <Descriptions.Item label="Email">
                    <a style={{color: "#01a9ac"}}
                       href={`mailto:${salesPersonData?.user_details?.username || salesPersonData?.email}`}>
                        {salesPersonData?.user_details?.username || salesPersonData?.email}
                    </a>
                </Descriptions.Item>

            </StyledDescriptions>
        </Col>

        <Col xs={24}>
            <Row gutter={16}>

                <Col xs={24} style={{marginTop: 30}}>
                    <Typography.Title level={4}>
                        Total commission (all Efunctional lifetime): <br/>
                        <strong style={{fontSize: 32}}>
                            ${salesPersonData?.revenue?.total_commission}
                        </strong>
                    </Typography.Title>
                </Col>

                <Divider/>

                <Col xs={24}>
                    <Typography.Title level={4}>
                        Commission Details (by Salesgroup)
                    </Typography.Title>
                </Col>

                {salesPersonData?.revenue?.groups?.length !== 0
                    ? salesPersonData?.revenue?.groups.map((item, index) =>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6} key={index}>
                            <NavLink to={`/sales-portal/details-for-salesgroup/${item.group_id}`}>
                                <CustomCard bordered={false}>
                                    <Statistic
                                        title={item.group_name}
                                        value={item.salesperson_commission}
                                        precision={2}
                                        prefix={'$'}
                                    />
                                </CustomCard>
                            </NavLink>
                        </Col>
                    )
                    : <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <CustomCard bordered={false}>
                            <Statistic
                                title={'No Groups'}
                                value={0}
                                precision={2}
                                prefix={'$'}
                            />
                        </CustomCard>

                    </Col>
                }


            </Row>
        </Col>

    </Row>
</Spin>;

export default SalePersonDetailPageIndex;