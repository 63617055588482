import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
// import EmployeeDeleteConfirmModal from "./EmployeeDeleteConfirmModal";
// import EmployeeEditModal from "./EmployeeEditModal";
import {UserOutlined} from '@ant-design/icons';
import {Space, Divider, List, Skeleton} from 'antd';

import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";

const EmployerEmployeesList = ({clientID, employees, setEmployees}) => {

    const api = useAuthAPI();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const loadMoreData = async () => {
        if (loading) {
            return;
        }
        setLoading(true);

        await api.get(`/employer-employees/?client=${clientID}`)
            .then((response) => {
                setEmployees(response?.data?.results);

                setData({
                    ...data,
                    count: response?.data?.count,
                    next: response?.data?.next
                })
            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    navigate('/session-expired');
                } else {
                    console.log("getClientsList Error", err);
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        (async () => {
            try {
                await loadMoreData();
            } catch (err) {
                console.error(err);
            }
        })();
        // eslint-disable-next-line
    }, []);

    return <>

        <InfiniteScroll
            dataLength={data?.count || 0}
            next={loadMoreData}
            hasMore={data?.next}
            loader={<Skeleton avatar paragraph={{rows: 1}} active/>}
            endMessage={<Divider plain/>}
            scrollableTarget="scrollableDiv"
        >
            <List
                dataSource={employees}
                renderItem={employee =>
                    <List.Item key={employee?.id} extra={
                        <Space>
                            {/*<EmployeeEditModal*/}
                            {/*    {...employee}*/}
                            {/*    clientID={clientID}*/}
                            {/*    employees={employees}*/}
                            {/*    clientInfo={clientInfo}*/}
                            {/*    authConfig={authConfig}*/}
                            {/*    setEmployees={setEmployees}*/}
                            {/*/>*/}

                            {/*<EmployeeDeleteConfirmModal*/}
                            {/*    {...employee}*/}
                            {/*    authConfig={authConfig}*/}
                            {/*    setEmployees={setEmployees}*/}
                            {/*/>*/}
                        </Space>}>

                        <List.Item.Meta
                            avatar={<UserOutlined style={{fontSize: 35, marginTop: 7}}/>}
                            title={`id: ${employee?.id} | ${employee?.first_name} ${employee?.last_name}`}
                            description='Employee'
                        />
                    </List.Item>}
            />
        </InfiniteScroll>
    </>;
};

export default EmployerEmployeesList;