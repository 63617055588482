import lyfeAPI from '../lyfeAPI'

const fetchPatientData = async (patientId) => {
    try {
        if (!patientId) throw new Error('Patient ID is required');

        const patientData = await lyfeAPI.get(`/patients/${patientId}/`);

        return patientData.data;
    } catch (err) {
        throw new Error('Error fetching patient data');
    }
}

export default fetchPatientData;