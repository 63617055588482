import dayjs from "dayjs";
import React, {useEffect, useState} from 'react';
import {Button, Col, List, Row, Space, Spin, Tag, Progress, Typography, Divider, DatePicker} from 'antd';
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";

import PrinterPaired from "./PrinterPaired";
import PrintButton from "./PrintButton";


const OrdersList = ({ordersList, setOrdersList, printerDevice, setPrinterDevice}) => {
    const api = useAuthAPI();

    const [searchSTR, setSearchSTR] = useState(false);
    const [loading, setLoading] = useState(false);

    // eslint-disable-next-line
    const [month, setMonth] = useState(null);

    const showTotalKitsForAllTime = () => {
        setMonth(null);
    }

    const loadMoreData = async () => {
        setLoading(true);

        try {

            let requestURL = ``; // result API request URL


            // filter by month, starts working after user select month
            if (month !== null) {
                requestURL = `/orders/all/`;
                requestURL += `?created__gte=${month.startDate}`;
                requestURL += `&created__lte=${month.endDate}`;
                requestURL += `&ordering=-created`;
                requestURL += `&isTest=false`;
            }

            // default filter
            if (month === null) {
                const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
                const endDate = dayjs().endOf('month').format('YYYY-MM-DD');

                requestURL = `/orders/all/`;
                requestURL += `?ordering=-created`;
                requestURL += `&isTest=false`;
                requestURL += `&created__gte=${startDate}`;
                requestURL += `&created__lte=${endDate}`;
            }

            const response = await api.get(`${requestURL}`);

            if (loadMoreObject.clickCounter === 0) {
                setOrdersList(response?.data?.results);
            } else {
                setOrdersList(prevState => [{...prevState, ...response?.data?.results}]);
            }

            setLoadMoreObject({
                ...loadMoreObject, nextURL: response?.data?.next, totalCount: response?.data?.count,
            });

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const onChangeMonthHandler = value => {
        const currentDate = dayjs(value);

        if (currentDate.isValid()) {
            const startDate = currentDate.startOf('month').format('YYYY-MM-DD');
            const endDate = currentDate.endOf('month').format('YYYY-MM-DD');

            setMonth({startDate, endDate});
            return;
        }
        setMonth(null);
    }

    // Listen for month change
    useEffect(() => {
        (async () => {
            await loadMoreData();
        })();
        // eslint-disable-next-line
    }, [month]);

    const [loadMoreObject, setLoadMoreObject] = useState({
        nextURL: null, totalCount: null, clickCounter: 0, nextSearchURL: null,
    });

    // Listen for Load More button click
    useEffect(() => {
        if (ordersList.length <= loadMoreObject.totalCount) {
            (async () => {
                try {
                    await loadMoreData();
                } catch (err) {
                    console.error(err);
                }
            })();
        }
        // eslint-disable-next-line
    }, [loadMoreObject.clickCounter]);


    const buttonClickHandler = () => {
        setLoadMoreObject({...loadMoreObject, clickCounter: loadMoreObject.clickCounter + 1});
    }

    const loadMore = !loading ? (

        <Row style={{alignSelf: "center", justifyContent: "center", marginTop: 20}}>

            <Col xs={5}>
                <p style={{textAlign: "center"}}>
                    {ordersList.length === 0
                        ? <>Nothing to show</>
                        : <>
                            {ordersList.length} order's on page from {loadMoreObject.totalCount}

                            <Progress
                                strokeColor={'#01a9ac'}
                                percent={Math.round((ordersList.length / loadMoreObject.totalCount) * 100)}
                                status="active"
                            />
                        </>
                    }
                </p>
            </Col>

            <Col xs={24}>
                <div style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                    display: (loadMoreObject.clickCounter === 0 && !loadMoreObject.nextURL) || (ordersList.length === loadMoreObject.totalCount)
                        ? 'none'
                        : 'block',
                }}>
                    <Button onClick={buttonClickHandler} loading={loading} disabled={loading}>
                        loading more
                    </Button>
                </div>
            </Col>
        </Row>
    ) : null;


    return <Spin spinning={loading}>

        <Row gutter={10}>

            <Col xs={24} lg={24} xl={24}>
                <Space style={{margin: '0 0 20px 0'}}>

                    {searchSTR
                        ? (<Tag>We find <strong>{loadMoreObject.totalCount}</strong> order's in Database</Tag>)
                        : (loadMoreObject.totalCount === null || loadMoreObject.totalCount === 0)
                            ? <>Nothing to show</>
                            : (<Tag>We have <strong>{loadMoreObject.totalCount}</strong> order's in Database</Tag>)
                    }

                    <span>Filter orders by month:</span>

                    <DatePicker
                        // value={month}
                        defaultValue={dayjs} // just decoration
                        onChange={onChangeMonthHandler}
                        format={'YYYY-MM'}
                        picker="month"
                        inputReadOnly={true}
                        size={'small'}
                    />

                    {/* todo - finish this feature */}
                    {/*<Button type={'link'} onClick={showTotalKitsForAllTime} style={{color: '#01a9ac'}}>*/}
                    {/*    Show all kits*/}
                    {/*</Button>*/}

                </Space>
            </Col>

        </Row>

        <List
            loadMore={loadMore}
            dataSource={ordersList}
            renderItem={order =>
                <List.Item
                    key={order.id}
                    extra={
                        <Space>
                            <Divider/>
                            <Typography.Paragraph>
                                Total kits in order: {order?.order_kits.length}
                            </Typography.Paragraph>
                            <Divider/>

                            {printerDevice
                                ? <PrintButton printerDevice={printerDevice} kitsList={order?.order_kits}/>
                                : <PrinterPaired setPrinterDevice={setPrinterDevice}/>}
                        </Space>
                    }
                >

                    <List.Item.Meta
                        title={<Typography.Title level={5} underline={1}>
                            OrderID: {order.id} | {order?.firstName} {order?.lastName}
                        </Typography.Title>}
                        description={<Space>
                            {/*{order?.is_employer ? <>Employer</> : order?.is_provider && <>Provider</>}*/}

                            Order source:
                            {order?.isTest && <Tag bordered={0} color="red">test</Tag>}
                            {order?.isNewClient && <Tag bordered={0} color="black">efunctional.com</Tag>}
                            {order?.isProvider &&
                                <Tag bordered={0} color="black">provider.efunctional.com</Tag>}

                            Order date:
                            {order?.created && <Tag bordered={0} color="grey">
                                {dayjs(order?.created).format('MM/DD/YYYY')}
                            </Tag>}

                        </Space>}
                    />
                </List.Item>}
        />
    </Spin>;
};
export default OrdersList;