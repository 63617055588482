import React, {useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, message, Modal} from 'antd';
import {EditOutlined} from "@ant-design/icons";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";

const SalesGroupEditModal = (props) => {
    const api = useAuthAPI();
    const {
        id,
        shortname,
        split_percent,
        description,
        setSalesGroupsList,
        salesGroupsList
    } = props;

    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setConfirmLoading(false);
            triggerSubmitHandler();
        }, 1000);
    };

    const submitForm = async (formData) => {
        formData.active = true;

        await api.put(`/sales-groups/${id}/`, formData)
            .then(response => {
                setSalesGroupsList(prevState =>
                    prevState.map(oneGroup =>
                        oneGroup.id === id ? response.data : oneGroup));
            })
            .catch(err => console.log(err));
    };

    const onFinish = async (formData) => {

        await submitForm(formData)
            .then(() => {
                setOpen(false);
                form.resetFields();
                message.success({
                    content: `${formData.shortname} updated!`,
                    duration: 2,
                });
            })
            .catch(() => {
                setOpen(true);
                message.error({
                    content: 'We have an errors!',
                    duration: 2,
                });
            })
    }

    const triggerSubmitHandler = () => form.submit();

    useEffect(() => {
        form.setFieldsValue({
            shortname,
            split_percent,
            description,
        });
        // eslint-disable-next-line
    }, [salesGroupsList]);

    return <>
        <Button size={'small'} onClick={showModal} disabled={true}>
            <EditOutlined/>&nbsp;Edit
        </Button>

        <Modal
            title={`Edit Sale Group : ${shortname}`}
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
        >
            <Form onFinish={onFinish} form={form} autoComplete="off">

                <Form.Item
                    label={'Shortname'}
                    name="shortname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input sale group shortname!',
                        },
                    ]}
                >
                    <Input placeholder="Shortname"/>
                </Form.Item>

                <Form.Item
                    label={'Split percent'}
                    name="split_percent"
                    rules={[
                        {
                            max: 100,
                            min: 1,
                            required: true,
                            message: 'Split percent from 1 to 100%',
                            type: 'number',
                        },
                    ]}
                >
                    <InputNumber placeholder="Input Split percent"/>
                </Form.Item>


                <Form.Item label={'Description'} name="description">
                    <Input.TextArea placeholder="Input description"/>
                </Form.Item>


            </Form>

        </Modal>
    </>;
}

export default SalesGroupEditModal;