import {phoneNumberMask} from "../../../../../../../features/validation";
import React from 'react';
import {Col, Divider, Form, Input, Row} from 'antd';

const Employer = ({formData, setFormData, form}) =>
    <>
        <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                    name="name"
                    label="Client name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter client name',
                        },
                    ]}
                >
                    <Input
                        onChange={({target: {value}}) => setFormData({...formData, name: value})}
                        placeholder="Please enter client name"
                    />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter email',
                        },
                        {
                            // eslint-disable-next-line
                            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            message: 'Please enter valid email',
                        }
                    ]}
                >
                    <Input
                        onChange={({target: {value}}) => setFormData({...formData, email: value})}
                        placeholder="Please enter email"
                    />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                        {required: true, message: 'Please enter phone',},
                        {pattern: /\((\d{3})\)\s(\d{3})-(\d{4})/, message: 'Please enter valid phone number',}
                    ]}
                >
                    <Input
                        onChange={({target: {value}}) => {
                            const prettyPhoneNumber = phoneNumberMask(value);

                            form.setFieldValue('phone', prettyPhoneNumber);
                            setFormData({...formData, phone: prettyPhoneNumber});
                        }}
                        placeholder="Please enter phone"
                    />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="billing_email"
                    label="Billing email"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter billing email',

                        },
                        {
                            // eslint-disable-next-line
                            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            message: 'Please enter valid email',
                        }
                    ]}
                >
                    <Input
                        onChange={({target: {value}}) => setFormData({...formData, billing_email: value})}
                        placeholder="Please enter billing email"
                    />
                </Form.Item>
            </Col>
        </Row>

        <Row gutter={16}>
            <Col span={24}>
                <Form.Item
                    name="address"
                    label="Company Address"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter address',
                        },
                    ]}
                >
                    <Input
                        onChange={({target: {value}}) => setFormData({...formData, address: value})}
                        placeholder="Please enter address"
                    />
                </Form.Item>
            </Col>
        </Row>

        <Divider/>

        <Row gutter={16}>

            <Col span={12}>
                <Form.Item
                    name="key_client_first_name"
                    label="Key contact First name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter client key contact First name',
                        },
                    ]}
                >
                    <Input
                        onChange={({target: {value}}) => setFormData({
                            ...formData,
                            key_client_first_name: value
                        })}
                        placeholder="Please enter client key contact First name"
                    />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="key_client_last_name"
                    label="Key contact Last name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter client key contact Last name',
                        },
                    ]}
                >
                    <Input
                        onChange={({target: {value}}) => setFormData({
                            ...formData,
                            key_client_last_name: value
                        })}
                        placeholder="Please enter client key contact Last name"
                    />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="key_client_contact_email"
                    label="Key contact Email"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter email',
                        },
                        {
                            // eslint-disable-next-line
                            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            message: 'Please enter valid email',
                        }
                    ]}
                >
                    <Input
                        onChange={({target: {value}}) => setFormData({...formData, key_client_contact_email: value})}
                        placeholder="Please enter email"
                    />
                </Form.Item>
            </Col>
        </Row>

        <Divider/>
    </>;

export default Employer;