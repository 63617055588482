import React, {useEffect, useContext} from 'react'
import {Row, Col, Typography, Button} from 'antd'
import {NavLink} from "react-router-dom";

// import {useAppDispatch} from "../../../app/hooks";
// import {logOut} from "../../../features/auth/authSlice";

import {AuthContext} from "../../AuthContext";

const LogOut = () => {
    // const dispatch = useAppDispatch();

    const {logout} = useContext(AuthContext);

    useEffect(() => {
        // dispatch(logOut());

        logout();

        // eslint-disable-next-line
    }, []);


    return <Row justify={'center'} style={{textAlign: "center"}}>
        <Col span={6}>
            <Typography.Title level={2} style={{marginTop: 150}}>
                You successfully logged out 👌
            </Typography.Title>

            <Typography.Paragraph>
                You can return to system after&nbsp;
                <NavLink to={'/login'}>
                    <Button type={'link'} style={{padding: 0}}>login</Button>
                </NavLink>
                ! See you soon.
            </Typography.Paragraph>
        </Col>
    </Row>;
}

export default LogOut;