import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import ContractsList from "./ContractsList";
import {HomeOutlined} from "@ant-design/icons";
import {Row, Col, Divider, Breadcrumb, Typography} from "antd";


import NewContractForm from "./NewContractForm";

const SalesManagement = () => {
    const [contractsList, setContractsList] = useState([]);

    return <Row justify="center">
        <Col xs={24}>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to={'/'}>
                        <HomeOutlined/>&nbsp;
                        Dashboard
                    </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    Contracts
                </Breadcrumb.Item>
            </Breadcrumb>

            <Typography.Title level={1}>
                Contracts
            </Typography.Title>
            <NewContractForm setContractsList={setContractsList}/>
        </Col>

        <Divider/>

        <Col xs={24}>
            <ContractsList
                contractsList={contractsList}
                setContractsList={setContractsList}
            />
        </Col>
    </Row>
};

export default SalesManagement;