import React, {useEffect, useContext} from 'react'
import {Result, Button} from 'antd'
import {NavLink} from "react-router-dom";

import {AuthContext} from "../../AuthContext";


const YourSessionExpired = () => {


    const {logout} = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            await logout();
        })();
    }, []);

    return <Result
        status="403"
        title="401"
        subTitle="Server give 401 status code. Your session expired."
        extra={<NavLink to={'/login'}><Button type="primary">Back Home</Button> </NavLink>}
    />;

}


export default YourSessionExpired;