import React from 'react';
import {NavLink} from 'react-router-dom';
import {
    HomeOutlined,
    ShoppingOutlined,
    IdcardOutlined,
    DashboardOutlined,
    EnvironmentOutlined,
} from "@ant-design/icons";
import {Breadcrumb, Col, Divider, Row, Space, Tag, Spin, Typography, Card, List} from "antd";
import styled from "styled-components";


// Added for an example only todo in future
const data = [
    '[04/23/23 12:54:23 PM] - Make an Order',
    '[04/23/23 12:54:23 PM] - Logged to system',
    '[01/23/23 12:54:23 PM] - Make an Order',
    '[01/23/23 12:54:23 PM] - Make an Order',
    '[01/23/23 12:54:23 PM] - Login to system',
    '[03/23/23 12:54:23 PM] - Provider created',
];


const CustomCard = styled(Card)`
    cursor: pointer;
    user-select: none;
    text-align: center;
    border-width: 2px;
    border-color: #01a9ac;
    //background: rgba(1, 169, 172, 0.06);
    background: linear-gradient(to right, rgba(1, 169, 172, 0.12), rgba(1, 219, 223, 0.11));

    & .anticon {
        font-size: 3.5em;
        margin: 0 0 20px 0;
        //color: #01a9ac;
    }

    & .ant-typography {
        //color: #01a9ac;
    }

    &:hover {
        background: linear-gradient(to right, #01a9ac, #01dbdf);

        & .ant-typography {
            color: #fff;
        }

        & .anticon {
            color: #fff;
        }
    }
`

const ProviderDetailsIndex = ({isLoading, clientInfo, currentOrderType}) => {

    return <Spin spinning={isLoading}>

        <Row>


            <Col span={24}>
                <Typography.Title>
                    <Space>
                        {clientInfo && clientInfo.name}&nbsp;
                        <Tag
                            style={{
                                display: "block",
                                background: "linear-gradient(to right, #01a9ac, #01dbdf)",
                                color: "#fff",
                                borderColor: "transparent"
                            }}
                        >
                            {currentOrderType}
                        </Tag>
                    </Space>
                </Typography.Title>

                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to={'/'}>
                            <HomeOutlined/>&nbsp;
                            Dashboard
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <NavLink to={`/clients`}>
                            Clients
                        </NavLink>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>{clientInfo?.name}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>

            <Divider/>

            {/*<Col>*/}
            {/*    {clientInfo && JSON.stringify(clientInfo, null, 2)*/}
            {/*    }&nbsp;*/}
            {/*</Col>*/}

            {/*<Col>*/}
            {/*    is_able_to_use_the_supply_orders:*/}
            {/*    {clientInfo && clientInfo.is_able_to_use_the_supply_orders*/}
            {/*        ? 'is_able_to_use_the_supply_orders'*/}
            {/*        : 'is_not_able_to_use_the_supply_orders'*/}
            {/*    }*/}
            {/*</Col>*/}


            <Divider/>

            <Col span={24}>
                <Row gutter={[16, 16]}>

                    <Col xxl={4}>
                        <NavLink to={`inventory`}>
                            <CustomCard bordered={1} hoverable={1}>
                                <DashboardOutlined/>
                                <Typography.Title level={4}>Inventory</Typography.Title>
                            </CustomCard>
                        </NavLink>
                    </Col>

                    <Col xxl={4}>
                        <NavLink to={'orders'}>
                            <CustomCard bordered={1} hoverable={1}>
                                <ShoppingOutlined/>
                                <Typography.Title level={4}>Orders</Typography.Title>
                            </CustomCard>
                        </NavLink>
                    </Col>

                    <Col xxl={4}>
                        <NavLink to={'manage-info'}>
                            <CustomCard bordered={1} hoverable={1}>
                                <IdcardOutlined/>
                                <Typography.Title level={4}>Manage info</Typography.Title>
                            </CustomCard>
                        </NavLink>
                    </Col>

                    {currentOrderType === 'provider' &&
                        <Col xxl={4}>
                            <NavLink to={'locations'}>
                                <CustomCard bordered={1} hoverable={1}>
                                    <EnvironmentOutlined/>
                                    <Typography.Title level={4}>Locations</Typography.Title>
                                </CustomCard>
                            </NavLink>
                        </Col>
                    }

                    {/*<Col xxl={4}>*/}
                    {/*    <NavLink to={'referrals'}>*/}
                    {/*        <CustomCard bordered={1} hoverable={1}>*/}
                    {/*            <UsergroupAddOutlined/>*/}
                    {/*            <Typography.Title level={4}>Referrals</Typography.Title>*/}
                    {/*        </CustomCard>*/}
                    {/*    </NavLink>*/}
                    {/*</Col>*/}

                    {/*/!*todo - provide this feature in future*!/*/}
                    {/*<Col xxl={4}>*/}
                    {/*    <NavLink to={'logs'}>*/}
                    {/*        <CustomCard bordered={1} hoverable={1}>*/}
                    {/*            <RadarChartOutlined/>*/}
                    {/*            <Typography.Title level={4}>Logs</Typography.Title>*/}
                    {/*        </CustomCard>*/}
                    {/*    </NavLink>*/}
                    {/*</Col>*/}

                </Row>
            </Col>

            <Divider/>


            <Col span={24}>

                <Divider orientation="left">
                    <Typography.Title level={4}>Last user actions</Typography.Title>
                </Divider>

                <List
                    size="small"
                    // header={<div>Header</div>}
                    dataSource={data}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                />
                <Divider orientation="left"/>

            </Col>


            {/*<Inventory authConfig={authConfig} clientID={clientID}/>*/}

            {/* ------------- Old list with doctors -------------*/}
            {/*<Col span={24}>*/}
            {/*    <ProviderDoctorsList*/}
            {/*        clientID={clientID}*/}
            {/*        authConfig={authConfig}*/}
            {/*        clientInfo={clientInfo}*/}
            {/*        doctors={doctors}*/}
            {/*        setDoctors={setDoctors}*/}
            {/*    />*/}
            {/*</Col>*/}
            {/* ------------- Old list with doctors ------------- */}

        </Row>
    </Spin>;
}


export default ProviderDetailsIndex;