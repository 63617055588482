import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";

import InfiniteScroll from 'react-infinite-scroll-component';
import SalesGroupDeleteConfirmModal from "./SalesGroupDeleteConfirmModal";
import SalesGroupEditModal from "./SalesGroupEditModal";
import SalesGroupManageSalesPersons from "./SalesGroupManageSalesPersons";

import {TeamOutlined} from '@ant-design/icons';
import {Button, Col, Divider, List, Skeleton, Space, Spin, Modal, message} from 'antd';

import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import SalesGroupMembershipsManagement from "./SalesGroupMembershipsManagement";


const SalesGroupList = ({salesGroupsList, setSalesGroupsList}) => {
    const [loading, setLoading] = useState(false);
    const [salesGroupManagementIsModalOpen, setSalesGroupManagementIsModalOpen] = useState(false);
    const [selectedSalesGroupManagement, setSelectedSalesGroupManagement] = useState(null);
    const [data, setData] = useState({});

    // Used in SalesGroupMembershipsManagement component. Need here to work with Modal submitting data.
    const [selectedSalesPersons, setSelectedSalesPersons] = useState([]);

    // Used in SalesGroupMembershipsManagement component.
    const [componentEditMode, setComponentEditMode] = useState(false);


    const api = useAuthAPI();

    const loadMoreData = async () => {

        if (loading) return;

        setLoading(true);

        await api.get(`/sales-groups/`)
            .then(response => {
                setSalesGroupsList(response.data.results);

                setData({
                    ...data,
                    count: response.data.count,
                    next: response.data.next
                })

                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                // if (err.response.status === 401) {
                //     // console.log("getClientsList: 401");
                //     navigate('/session-expired');
                // } else {
                //     console.log("getClientsList Error", err);
                // }
                setLoading(false);
            });
    };

    const openSalesGroupManagementModalHandler = salesGroupID => {
        setSalesGroupManagementIsModalOpen(true);
        setSelectedSalesGroupManagement(salesGroupID)
    };

    const updateSalesGroupMemberships = async () => {
        // TODO - finish this. Do not use api calls here, just use the 1 Action API Call for this job.
        // TODO - validate `split_percent` field. It must be a number between 0 and 100. On front and back end.
        try {

            // Prepare data for API call
            const data = {};
            const membershipData = selectedSalesPersons.map(salesPerson => {
                return {
                    salesperson: salesPerson.id,
                    split_percent: salesPerson.split_percent,
                    salesgroup: selectedSalesGroupManagement
                }
            })

            data.salesgroup = selectedSalesGroupManagement;
            data.memberships = membershipData;


            const request = await api.post('/update-salesgroup-memberships/', data);
            console.log("request", request)
            message.success("Sales group updated successfully.");
            setComponentEditMode(false);

        } catch (error) {
            console.error(error);
            const errorMessage = error.response.data.error;
            message.error(errorMessage);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                await loadMoreData();
            } catch (err) {
                console.error(err);
            }
        })();
        // eslint-disable-next-line
    }, []);

    return <Spin tip="Loading..." spinning={loading}>
        <InfiniteScroll
            dataLength={data.count || 0}
            next={loadMoreData}
            hasMore={data.next}
            loader={<Skeleton paragraph={{rows: 1}} active/>}
            endMessage={<Divider plain></Divider>}
            scrollableTarget="scrollableDiv"
        >
            <List
                dataSource={salesGroupsList}
                renderItem={saleGroup =>
                    <List.Item style={{width: '100%'}} key={saleGroup.id} extra={<Space>

                        {/*<SalesGroupManageSalesPersons {...saleGroup}/>*/}
                        {/*/!*<SalesGroupManageSalesPersons {...saleGroup} authConfig={authConfig}/>*!/*/}


                        <Button size={'small'} onClick={() => openSalesGroupManagementModalHandler(saleGroup.id)}>
                            Open management
                        </Button>


                        <SalesGroupEditModal
                            {...saleGroup}
                            salesGroupsList={salesGroupsList}
                            setSalesGroupsList={setSalesGroupsList}

                        />

                        <SalesGroupDeleteConfirmModal
                            {...saleGroup}
                            setSalesGroupsList={setSalesGroupsList}

                        />

                    </Space>}>

                        <List.Item.Meta
                            avatar={<TeamOutlined style={{fontSize: 35, marginTop: 7}}/>}
                            title={<NavLink to={`/sales-groups/${saleGroup.id}`}>
                                Sales group ID: {saleGroup.id} - {saleGroup.shortname}
                            </NavLink>}
                            description={<Col xs={24}>Split percent: {saleGroup.split_percent} %</Col>}
                        />

                    </List.Item>}
            />
        </InfiniteScroll>

        <Modal
            width={"70%"}
            open={salesGroupManagementIsModalOpen}
            onCancel={() => setSalesGroupManagementIsModalOpen(false)}
            onOk={componentEditMode ? updateSalesGroupMemberships : () => setSalesGroupManagementIsModalOpen(false)}
            closable={true}
            destroyOnClose={true}
        >
            <SalesGroupMembershipsManagement
                componentEditMode={componentEditMode}
                setComponentEditMode={setComponentEditMode}
                saleGroupID={selectedSalesGroupManagement}
                selectedSalesPersons={selectedSalesPersons}
                setSelectedSalesPersons={setSelectedSalesPersons}
            />
        </Modal>
    </Spin>
};

export default SalesGroupList;