import React from 'react';
import {Button} from "antd";

const PrintButton = ({printerDevice, setPrinterDevice}) => {
    const barcodeValue = 'TEST_LABEL_0001';

    const connectToPrinter = async () => {
        try {
            const device = await navigator.usb.requestDevice({filters: [{vendorId: 0x0A5F}]}); // Replace with your printer's vendorId
            await device.open();
            // Select configuration if it hasn't been automatically. First configuration is usually 1.
            if (device.configuration === null) {
                await device.selectConfiguration(1);
            }
            // Claim first interface (index starts from 0). You may need to adjust this for your device.
            await device.claimInterface(0);
            setPrinterDevice(device);
        } catch (error) {
            console.error('Could not connect to printer:', error);
        }
    };

    const printBarcode = async () => {
        if (!printerDevice) {
            console.error('Printer not connected');
            return;
        }
        const encoder = new TextEncoder();
        const data = encoder.encode(`^XA^FO50,50^BCN,70,Y,N,N^FD${barcodeValue}^FS^FO50,150^ADN,36,20^FD${barcodeValue}^FS^XZ`);
        await printerDevice.transferOut(1, data);
    };

    // return (
    //     <div>
    //         <Button onClick={connectToPrinter}>
    //             Connect to Printer
    //         </Button>
    //
    //         <Button onClick={printBarcode}>
    //             Print Barcode
    //         </Button>
    //     </div>
    // );

    return <Button onClick={connectToPrinter}>
        Connect to Printer
    </Button>;

};

export default PrintButton;
