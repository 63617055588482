import styled, {keyframes} from "styled-components";
import React, {useEffect, useState} from "react";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import {Button, Col, Divider, List, message, Row, Space, Spin, Tag, Typography} from "antd";
import {ThunderboltOutlined, UserOutlined} from "@ant-design/icons";
import {NavLink} from "react-router-dom";
import dayjs from "dayjs";


// -----  STYLES  ----- //
const blink = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const BlinkingTag = styled(Tag)`
    animation: ${blink} 1s linear infinite;
`;
// -----  STYLES  ----- //


const SHIPPING_CARRIERS = [
    {
        label: "USPS",
        value: 1
    },
    {
        label: "FedEx",
        value: 2
    }
]

const getKitLabel = kit => {
    // Extracting values to variables for readability and efficiency
    const productName = kit?.orderItem?.product?.name;
    const testType = kit?.test;

    let label = null;

    if (productName) {
        // If product name is available, use it
        label = productName;
    } else {
        // Handling the test type logic
        if (testType === "AM") {
            label = "DOD MALE PANEL";
        } else if (testType === "AF") {
            label = "DOD FEMALE PANEL";
        }
    }

    return (
        <Typography.Text>
            <Tag>{label}</Tag>
        </Typography.Text>
    );
}

const SingleKitItem = ({kit}) => {
    return <List.Item key={kit.id}>
        <List.Item.Meta
            title={
                <Space>
                    <Typography.Text>
                        {kit.barcode} &nbsp;&nbsp;
                    </Typography.Text>

                    <Divider type={'vertical'}/>

                    {/*<Typography.Text>*/}
                    {/*    <Tag>*/}
                    {/*        {kit?.orderItem?.product?.name*/}
                    {/*            ? kit?.orderItem?.product?.name*/}
                    {/*            : kit?.test.includes("AM", "AF")*/}
                    {/*                ? kit?.test === "AM"*/}
                    {/*                    ? "DOD MALE PANEL"*/}
                    {/*                    : kit?.test === "AF"*/}
                    {/*                        ? "DOD FEMALE PANEL"*/}
                    {/*                        : ""*/}
                    {/*                : "Fake"}*/}
                    {/*    </Tag>*/}
                    {/*</Typography.Text>*/}

                    {getKitLabel(kit)}

                    <Divider type={'vertical'}/>

                    <Typography.Text>
                        {kit?.status && <Tag color={'#01a9ac'}>{kit?.status}</Tag>}
                    </Typography.Text>

                    <Divider type={'vertical'}/>

                    <Typography.Text>
                        {kit?.patient
                            ? <Button color={'#01a9ac'} size={'small'} type={'dashed'} icon={<UserOutlined/>}>
                                <NavLink to={`/patients/${kit?.patient}`}>
                                    &nbsp;&nbsp;&nbsp;open patient info
                                </NavLink>
                            </Button>
                            : null}
                    </Typography.Text>

                    {kit?.web_provider === 1166 && <>
                        <Divider type={'vertical'}/>
                        <BlinkingTag color={'#01a9ac'}>
                            AGE REJUVENATION
                        </BlinkingTag>
                    </>}

                    {kit?.web_provider === 1177 && <>
                        <Divider type={'vertical'}/>
                        <BlinkingTag color={'#01a9ac'}>
                            AGE REJUVENATION
                        </BlinkingTag>
                    </>}
                </Space>
            }
            description={<Space></Space>}
        />
    </List.Item>
}

const OrderDetailsWithAllKitsForShippedStatus = ({
                                                     selectedOrder,
                                                     setOrdersList,
                                                     setSelectedOrder,
                                                     loading
                                                 }) => {
    /* Displaying all Kits List for current Order ID and show actions buttons. */

    const api = useAuthAPI();

    /* ------------- List with all component modes -------------  */
    const modes = {
        KITS_MANAGEMENT: 'KITS_MANAGEMENT',
        ALL_KITS_ASSEMBLED: 'ALL_KITS_ASSEMBLED',
        ORDER_SUCCESSFULLY_SHIPPED: 'ORDER_SUCCESSFULLY_SHIPPED',
    }

    const [componentMode, setComponentMode] = useState(modes.KITS_MANAGEMENT);

    const {
        id: orderId,
        status: orderStatus,
        assembled_kit_count: assembledKitsCount,
    } = selectedOrder;

    // console.log(selectedOrder);

    // Tracking numbers for current order. Default values are null. Waiting for all kits was assembled and packed.
    const [trackingNumber, setTrackingNumber] = useState(null);
    const [trackingReturn, setTrackingReturn] = useState(null);
    const [carrierId, setCarrierId] = useState(1);

    // Initial state for assembled kits. Needed for the Switch component and Complete the Supply Order button.
    const [localAssembledKitsCount, setLocalAssembledKitsCount] = useState(assembledKitsCount);

    // All kits for current order
    const [kitsForOrder, setKitsForOrder] = useState([]);

    // Server response with all data for current order
    const [orderData, setOrderData] = useState(null);

    const assembledPercentage = orderData?.count ? (assembledKitsCount / orderData?.count) * 100 : 0;

    const markOrderAsShipped = async () => {

        const requestData = {
            trackingNumber,
            trackingReturn,
            carrierId
        };

        try {
            const response = await api.post(`/orders/supplies-orders-all/${orderId}/mark-as-shipped/`, requestData);
            console.log(response.data);
            message.success('Order marked as shipped successfully!');
            setComponentMode(modes.ORDER_SUCCESSFULLY_SHIPPED);

            setOrdersList(prevState => prevState.filter(order => order.id !== orderId))
        } catch (error) {
            console.error('Error marking order as shipped:', error);

            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Server response:', error.response.data);
                message.error('Failed to mark order as shipped. ' + error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received:', error.request);
                message.error('Failed to mark order as shipped. No response from server.');
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up request:', error.message);
                message.error('Failed to mark order as shipped. Error: ' + error.message);
            }
        }
    }

    const loadKitsForOrder = async () => {
        try {
            const response = await api.get(`/kits/all/?order=${orderId}`);
            console.log(response.data); // TODO - remove this console.log
            setKitsForOrder(response.data.results);
            setOrderData(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        (async () => {
            await loadKitsForOrder();
        })();
    }, []);

    // TODO - here in request output data we have a paginated data. Need to implement pagination here.

    const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

    return <Spin spinning={loading}>
        <Row>

            <Col lg={24}>
                <Space>
                    <Tag color="#01a9ac">Order ID: {orderId}</Tag>

                    {selectedOrder?.isExpeditedShipping && <BlinkingTag icon={<ThunderboltOutlined/>} color={'red'}>
                        Expedited shipping
                    </BlinkingTag>}

                    <Tag color="#01a9ac">Status: {orderStatus}</Tag>
                    <Tag>Total kits in order: {orderData?.count}</Tag>

                    {selectedOrder?.isTest && <Tag bordered={0} color="red">test</Tag>}
                    {selectedOrder?.isNewClient && <Tag bordered={0} color="#01a9ac">efunctional.com</Tag>}
                    {selectedOrder?.isProvider && <Tag bordered={0} color="black">provider.efunctional.com</Tag>}
                    {selectedOrder?.isCorporate && <Tag bordered={0} color="black">corporate.efunctional.com</Tag>}
                    {selectedOrder?.isWebProvider && <Tag bordered={0} color="black">webprovider.efunctional.com</Tag>}

                </Space>


                <Row style={{marginTop: '1rem'}}>

                    <Col lg={24}>
                        <Typography.Text>
                            <strong>Customer name:</strong>&nbsp;
                            {capitalizeFirstLetter(selectedOrder?.firstName)}&nbsp;
                            {capitalizeFirstLetter(selectedOrder?.lastName)}
                        </Typography.Text>
                    </Col>

                    <Col lg={24}>
                        <Typography.Text>
                            <strong>Customer phone:</strong>&nbsp;
                            {selectedOrder?.phone ? capitalizeFirstLetter(selectedOrder?.phone) : 'N/A'}
                        </Typography.Text>
                    </Col>

                    <Col lg={24}>
                        <Typography.Text>
                            <strong>Customer email:</strong>&nbsp;
                            {capitalizeFirstLetter(selectedOrder?.email)}
                        </Typography.Text>
                    </Col>


                    <Col lg={24}>
                        <Typography.Text>
                            <strong>Shipping address:</strong>&nbsp;
                            {selectedOrder?.address_1}&nbsp;{selectedOrder?.address_2},&nbsp;
                            {selectedOrder?.city}, {selectedOrder?.state}, {selectedOrder?.postcode}
                        </Typography.Text>
                    </Col>


                    {selectedOrder?.shipping_carrier
                        ? <Col lg={24}>
                            <Typography.Text>
                                <strong>Shipping carrier:</strong>&nbsp;

                                {/*{parseInt(selectedOrder?.shipping_carrier)}&nbsp;*/}
                                {SHIPPING_CARRIERS.find(c =>
                                    c.value === parseInt(selectedOrder?.shipping_carrier))?.label
                                }

                            </Typography.Text>
                        </Col>
                        : null
                    }


                    {selectedOrder?.tracking_number
                        ? <Col lg={24}>
                            <Typography.Text>
                                <strong>Tracking number:</strong>&nbsp;
                                {capitalizeFirstLetter(selectedOrder?.tracking_number)}&nbsp;
                            </Typography.Text>
                        </Col>

                        : selectedOrder?.tracking_notes

                            ? <Col lg={24}>
                                <Typography.Text>
                                    <strong>Tracking number:</strong>&nbsp;
                                    {capitalizeFirstLetter(selectedOrder?.tracking_notes)}&nbsp;
                                </Typography.Text>
                            </Col>

                            : null
                    }

                    {selectedOrder?.tracking_return
                        ? <Col lg={24}>
                            <Typography.Text>
                                <strong>Tracking return:</strong>&nbsp;
                                {capitalizeFirstLetter(selectedOrder?.tracking_return)}&nbsp;
                            </Typography.Text>
                        </Col>

                        : null
                    }
                </Row>

                <Divider/>

                {selectedOrder?.created && <Tag>
                    Order date:&nbsp;&nbsp;
                    {dayjs(selectedOrder?.created).format('MM/DD/YYYY')}
                </Tag>}

                <Divider type={'vertical'}/>

                {/*{JSON.stringify(kitsForOrder[0]?.assembled_and_packed_timestamp)}*/}

                {selectedOrder?.created && <Tag>
                    Assembled date:&nbsp;&nbsp;
                    {dayjs(kitsForOrder[0]?.assembled_and_packed_timestamp).format('MM/DD/YYYY')}
                </Tag>}

                <Divider type={'vertical'}/>

                <Divider/>
            </Col>

            <Col lg={24} style={{overflow: "auto"}}>
                <List
                    style={{overflow: "auto", maxHeight: "49vh"}}
                    dataSource={kitsForOrder}
                    renderItem={kit =>
                        <SingleKitItem
                            setSelectedOrder={setSelectedOrder}
                            setKitsForOrder={setKitsForOrder}
                            setLocalAssembledKitsCount={setLocalAssembledKitsCount}
                            kit={kit}
                            isAssembled={kit?.assembled_and_packed}
                        />
                    }
                />
            </Col>
        </Row>
    </Spin>;
}

export default OrderDetailsWithAllKitsForShippedStatus;