import React from 'react';
import {NavLink} from "react-router-dom";
import {Breadcrumb} from "antd";
import {HomeOutlined} from "@ant-design/icons";

const BreadcrumbsList = ({saleGroupObject}) =>
    <Breadcrumb>
        <Breadcrumb.Item>
            <NavLink to={'/'}>
                <HomeOutlined/>&nbsp;
                Dashboard
            </NavLink>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
            <NavLink to={`/sales-groups`}>
                Sales Groups
            </NavLink>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
            {saleGroupObject?.shortname}
        </Breadcrumb.Item>
    </Breadcrumb>;

export default BreadcrumbsList;