import React from 'react';
import {Button} from "antd";

const PrintButton = ({printerDevice, kitsList}) => {


    const printBarcode = async listWithItemsForPrint => {
        if (!printerDevice) {
            console.error('Printer not connected');
            return;
        }

        const encoder = new TextEncoder();

        for (let kit of listWithItemsForPrint) {
            const barcodeValue = kit.barcode;
            const data = encoder.encode(`^XA^FO50,50^BCN,70,Y,N,N^FD${barcodeValue}^FS^FO50,150^ADN,36,20^FD${barcodeValue}^FS^XZ`);
            await printerDevice.transferOut(1, data);
        }
    };


    return <Button onClick={() => printBarcode(kitsList)}>
        Print all kits labels
    </Button>;
};

export default PrintButton;
