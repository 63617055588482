import lyfeAPI from '../lyfeAPI'

const searchKitByBarcode = async (barcode) => {
    try {
        if (!barcode) throw new Error('Barcode is required');

        const orderData = await lyfeAPI.get(`/kits/search/${barcode}/`);

        return orderData.data;
    } catch (err) {
        throw new Error('Error in a fetching kit data');
    }
}

export default searchKitByBarcode;