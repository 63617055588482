import React, {useState, useEffect} from 'react';
import {FileOutlined} from '@ant-design/icons';
import {
    Button,
    Col,
    DatePicker,
    Drawer,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Space,
    Tag,
    Spin,
    Select,
    Checkbox, Divider
} from 'antd';
// import restAPI from "../../../../../../features/restAPI";
import {useNavigate} from "react-router-dom";

import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";


const NewContractForm = ({id, name}) => {

    const api = useAuthAPI();

    const initialState = {
        start: null,
        notes: null,
        client: id,
        employee_funding_periods: null,
        employee_funding_amount: null,
        discount: null,
        products: [],
    }

    const navigate = useNavigate();
    const {TextArea} = Input;
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [hasActiveContracts, setHasActiveContracts] = useState(false);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [productList, setProductList] = useState(null);

    const checkActiveContractsForClient = async () => {
        await api.get(`/contracts/?client=${id}`)
            .then(response => {
                setHasActiveContracts(response?.data.count);
                // console.log(id, response?.data.count)
                setIsLoading(false);
            })
    }

    const loadAllProducts = async () => {

        try {
            setIsLoading(true);
            const response = await api.get('/products/');
            setProductList(response.data.results);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {

        (async () => {
            try {
                setIsLoading(true);
                await checkActiveContractsForClient();
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoading(false);
            }
        })();


        if (productList === null) {
            (async () => {
                try {
                    setIsLoading(true);
                    await loadAllProducts();
                } catch (err) {
                    console.log(err);
                } finally {
                    setIsLoading(false);
                }
            })();
        }

        // eslint-disable-next-line
    }, []);

    const submitForm = async () => {

        try {
            setIsLoading(true);
            await api.post(`/contracts/`, formData);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const key = 'addNewContract';

    const triggerSubmitHandler = () => form.submit();

    const sendFormWithMessage = () => {
        setOpen(false);
        message?.loading({
            content: 'Loading...',
            key,
        });
        setTimeout(() => {
            submitForm()
                .then(() => {
                    setFormData(initialState);
                    setHasActiveContracts(1);
                    form.resetFields();
                    message?.success({
                        content: 'Contract created!',
                        key,
                        duration: 2,
                    });
                })
                .catch((err) => {
                    console.log(err);

                    if (err.response.status === 401) {
                        navigate('/session-expired');
                    } else {
                        console.log("getClientsList Error", err);
                    }

                    message?.error({
                        content: 'We have an errors!',
                        key,
                        duration: 2,
                    });

                    setOpen(true);
                })
        }, 1000);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onChangeLocalRangePicker = dataPickerArray => {

        console.log(dataPickerArray);

        setFormData({
            ...formData,
            start: dataPickerArray.format("YYYY-MM-DD"),
        });

        // setFormData({
        //     ...formData,
        //     start: dataPickerArray[0].format("YYYY-MM-DD"),
        //     finish: dataPickerArray[1].format("YYYY-MM-DD"),
        // })

    }

    const handleChange = checkedValues => {
        setFormData(prevState => {
            return {
                ...prevState,
                products: checkedValues,
            }
        })
    };

    return !isLoading ?
        <>

            {hasActiveContracts
                ? <Space><Tag color={'green'}>Active contract: {hasActiveContracts}</Tag></Space>
                : <Space>
                    <Button size={'small'} type="primary" onClick={showDrawer} icon={<FileOutlined/>}>
                        Sign contract
                    </Button>
                </Space>}


            <Drawer
                title={`Sign contract with ${name}`}
                width={window.innerWidth > 1200 ? 780 : '100%'}
                onClose={onClose}
                open={open}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={triggerSubmitHandler} type="primary">
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={sendFormWithMessage}
                    autoComplete="off"
                >

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="start"
                                label="Start date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input start date',
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{width: '100%'}}
                                    allowEmpty
                                    onChange={onChangeLocalRangePicker}
                                    format={"MM-DD-YYYY"}
                                    showToday={true}
                                />
                            </Form.Item>
                        </Col>


                        <Col span={12}>
                            <Form.Item
                                name={'employee_funding_periods'}
                                label="Contract duration"
                                rules={[{required: true, message: 'Please choose a funding periods'}]}
                            >
                                <Select placeholder="Please choose funding periods"
                                        defaultOpen={0}
                                        style={{width: '100%'}}
                                        options={[
                                            {label: "3 month", value: 3},
                                            {label: "6 month", value: 6},
                                            {label: "9 month", value: 9},
                                            {label: "12 month", value: 12},
                                        ]}
                                        onChange={value => setFormData({...formData, employee_funding_periods: value})}
                                />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>

                        <Col span={12}>
                            <Form.Item
                                name="employee_funding_amount"
                                label="Amount per employee, $"
                                rules={[
                                    {
                                        type: 'number',
                                        required: true,
                                        message: 'Please specify amount per employee',
                                        min: 0,
                                        max: 10000,
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{width: '100%',}}
                                    min={0} max={10000}
                                    placeholder="Please specify amount per employee"
                                    onChange={value => setFormData({...formData, employee_funding_amount: value})}
                                />
                            </Form.Item>
                        </Col>


                        <Col span={12}>
                            <Form.Item
                                name="discount"
                                label="Test kits discount, %"
                                rules={[
                                    {
                                        type: 'number',
                                        required: true,
                                        message: 'Please specify discount percent',
                                        min: 0,
                                        max: 100,
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{width: '100%',}}
                                    min={0} max={100}
                                    placeholder="Please enter discount"
                                    onChange={value => setFormData({...formData, discount: value})}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>

                        <Divider/>

                        <Col span={24}>
                            <Form.Item
                                name="products"
                                label="Products"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value && value.length > 0
                                                ? Promise.resolve()
                                                : Promise.reject(new Error('Please choose one or more products')),
                                    },
                                ]}
                            >
                                <Checkbox.Group onChange={handleChange} style={{userSelect: "none"}}>
                                    {productList && productList.map((product, index) => (
                                        <Checkbox value={product.id}>
                                            {product.name}
                                            <br/>
                                        </Checkbox>

                                    ))}
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>

                        <Divider/>


                        <Col span={24}>
                            <Form.Item name="notes" label="Some notes about this contract">
                                <TextArea
                                    value={formData.notes}
                                    onChange={({target: {value}}) => setFormData({...formData, notes: value})}
                                    placeholder="Please add notes"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </>
        : <Spin tip="Loading..." spinning={isLoading}/>;
};

export default NewContractForm;