import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Modal, Upload, message} from 'antd';
import {UploadOutlined} from "@ant-design/icons";
import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";

const EmployerTestSettingsModal = ({clientInfo, setEmployees}) => {

    const api = useAuthAPI();

    const formData = new FormData();
    let navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [fileCSV, setFileCSV] = useState(null);
    const [myFileList, setMyFileList] = useState(null);
    const [uploading, setUploading] = useState(false);


    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleUpload = async () => {
        setUploading(true);

        // TODO create loading with delay
        await api.post('/upload-employees/', formData)
            .then(response => {
                handleCancel();
                console.log(response?.data);
                setEmployees(prevState => [...response?.data?.results, ...prevState]);
                message.success(`We load ${response?.data?.how_much_loaded} unique employees from csv-file`);
            })
            .catch(error => {
                console.log("EmployerTestSettingsModal Error", error);
                handleCancel();
                message.error('Employees file upload failed.');
            })
            .finally(() => {
                setFileCSV(null);
                setMyFileList(null);
                setUploading(false);
            });
    };

    const props = {
        onRemove: (file, fileList) => {
            setFileCSV(null);
            setMyFileList(null);
        },
        beforeUpload: (file, fileList) => {
            if (file.type === "text/csv") {
                setFileCSV(file);
                setMyFileList(fileList);
            } else {
                message.error('We accept only .csv files');
            }
            return false;
        },
    };

    useEffect(() => {
        if (fileCSV) {
            // This fields will be sending to API
            formData.append('employees_file', fileCSV);
            formData.append('employer_id', clientInfo?.id);
        }
        // eslint-disable-next-line
    }, [fileCSV, clientInfo?.id])

    return <>
        <Button size={'small'} onClick={showModal} disabled>
            <UploadOutlined/>&nbsp;Upload employees
        </Button>

        <Modal
            footer={null}
            width={window.innerWidth > 1200 ? "40%" : '100%'}
            title={`Upload employees for ${clientInfo?.name}`}
            open={open}
            onCancel={handleCancel}
        >
            <p>
                We can loading only employees with unique names
            </p>
            <Upload {...props} maxCount={1} fileList={myFileList} accept={'.csv'}>
                <Button icon={<UploadOutlined/>}>Select File</Button>
            </Upload>

            <Button
                disabled
                name={'employees_file'}
                type="primary"
                onClick={handleUpload}
                // disabled={!fileCSV}
                loading={uploading}
                style={{
                    marginTop: 16,
                }}
            >
                {uploading ? 'Uploading' : 'Start Upload'}
            </Button>


        </Modal>
    </>;
}

export default EmployerTestSettingsModal;