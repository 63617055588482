import React, {useState, useEffect} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {
    Button, Col, DatePicker, Drawer, Form, Input, InputNumber, message, Row, Space, Typography, Spin, Checkbox
} from 'antd';

import {useAuthAPI} from "../../../../../../features/hooks/useAuthAPI";
import {compareArraysAsSet} from "@testing-library/jest-dom/dist/utils";

const initialState = {
    lastName: null,
    firstName: null,
    email: null,
    phone: null,
    notes: '',
    percentFirstYear: null,
    percentAfterYear: null,
    salesGroupID: null,
    products: [],
}

const NewContractForm = ({setContractsList}) => {

    const api = useAuthAPI();

    const {RangePicker} = DatePicker;
    const {TextArea} = Input;
    const [form] = Form.useForm();

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [currentRangePickerValue, setCurrentRangePickerValue] = useState(null);
    const [productList, setProductList] = useState(null);

    const submitForm = async () => {
        const {lastName, firstName, email, phone, percentFirstYear, percentAfterYear, notes, salesGroupID} = formData;


        try {
            setIsLoading(true);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }


        await api.post(`/sales-persons/`, {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone": phone,
            "percent_first_year": percentFirstYear,
            "percent_after_year": percentAfterYear,
            "active": true,
            "about": notes,
            "sales_group": salesGroupID,
        })
            .then(response => {
                setContractsList(prevState => [{...response.data}, ...prevState].map(s => s));
            });
    };

    const key = 'addNewContract';

    const triggerSubmitHandler = () => form.submit();

    const loadAllProducts = async () => {

        try {
            setIsLoading(true);
            const response = await api.get('/products/');
            setProductList(response.data.results);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    }

    const sendFormWithMessage = () => {
        setOpen(false);
        message?.loading({
            content: 'Loading...',
            key,
        });
        setTimeout(() => {
            submitForm()
                .then(() => {
                    setFormData(initialState);
                    form.resetFields();
                    message?.success({
                        content: 'Sales person created!',
                        key,
                        duration: 2,
                    });
                })
                .catch((err) => {

                    console.log(err);

                    message?.error({
                        content: 'We have an errors!',
                        key,
                        duration: 2,
                    });

                    setOpen(true);
                })
        }, 1000);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onChangeLocalRangePicker = value => {
        console.log(value);
        setCurrentRangePickerValue(value);
    }

    useEffect(() => {
        if (productList === null) {
            (async () => {
                await loadAllProducts();
            })()
        }
    }, [productList]);

    return <Spin spinning={isLoading}>
        <Space>
            <Button size={'small'} type="primary" onClick={showDrawer} icon={<PlusOutlined/>}>
                Sign a new contract
            </Button>
        </Space>

        <Drawer
            title="Create a new sales person"
            width={window.innerWidth > 1200 ? 780 : '100%'}
            onClose={onClose}
            open={open}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={triggerSubmitHandler} type="primary">
                        Submit
                    </Button>
                </Space>
            }
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={sendFormWithMessage}
                autoComplete="off"
            >


                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="lastName"
                            label="Last Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter last name',
                                },
                            ]}
                        >
                            <Input
                                onChange={({target: {value}}) => setFormData({...formData, lastName: value})}
                                placeholder="Please enter last name"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="firstName"
                            label="First Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter first name',
                                },
                            ]}
                        >
                            <Input
                                style={{
                                    width: '100%',
                                }}
                                onChange={({target: {value}}) => setFormData({...formData, firstName: value})}
                                placeholder="Please enter first name"
                            />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your email',
                                },
                            ]}
                        >
                            <Input
                                onChange={({target: {value}}) => setFormData({...formData, email: value})}
                                placeholder="Please enter your email"
                            />
                        </Form.Item>
                    </Col>


                    <Col span={12}>
                        <Form.Item
                            name="phone"
                            label="Phone number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter phone number',
                                },
                            ]}
                        >
                            <Input
                                onChange={({target: {value}}) => setFormData({...formData, phone: value})}
                                placeholder="Please enter phone number"
                            />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="percentFirstYear"
                            label="First year, %"
                            rules={[
                                {
                                    type: 'number',
                                    required: true,
                                    message: 'Please specify percent',
                                    min: 1,
                                    max: 100,
                                },
                            ]}
                        >
                            <InputNumber
                                style={{width: '100%',}}
                                min={1} max={100}
                                onChange={value => setFormData({
                                    ...formData,
                                    percentFirstYear: value
                                })}
                                placeholder="Please specify percent"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="percentAfterYear"
                            label="After year, %"
                            rules={[
                                {
                                    type: 'number',
                                    required: true,
                                    message: 'Please specify percent',
                                    min: 1,
                                    max: 100,
                                },
                            ]}
                        >
                            <InputNumber
                                style={{width: '100%',}}
                                min={1} max={100}
                                onChange={value => setFormData({
                                    ...formData,
                                    percentAfterYear: value
                                })}
                                placeholder="Please specify percent"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="notes" label="Some notes about this sales">
                            <TextArea
                                value={formData.notes}
                                onChange={({target: {value}}) => setFormData({...formData, notes: value})}
                                placeholder="Please add notes"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Typography.Paragraph style={{color: "red"}}>
                            Choose products for this Employer Client
                        </Typography.Paragraph>

                        {productList && productList.map((product, index) =>
                            <Checkbox
                                style={{userSelect: 'none'}}
                                key={index}
                                checked={formData.products.includes(product.id)}
                                onChange={({target: {checked}}) => {
                                    if (checked) {
                                        setFormData({...formData, products: [...formData.products, product.id]})
                                    } else {
                                        setFormData({
                                            ...formData,
                                            products: formData.products.filter(item => item !== product.id)
                                        })
                                    }
                                }}
                            >
                                {product.name}
                            </Checkbox>
                        )}
                    </Col>
                </Row>


            </Form>
        </Drawer>
    </Spin>;
};

export default NewContractForm;